import React, { useState } from "react";
import { Col, Row, Form } from "react-bootstrap";

import { Panel } from "../../components/core/Panel";
import { Shot } from "../../../shared/routers/ShotRouter";
import {
  GameBoxScore,
  GameTeamReboundModel,
  GamePlayerReboundModel,
  CrashAttempt,
  GameExpectedStats,
  GameExpectedTurnover,
  GameAttackAvoid,
} from "../../../shared/routers/GameRouter";
import {
  TeamStats,
  TeamActionBreakdown,
} from "../../../shared/routers/TeamRouter";
import { GameBoxScoresTable } from "../../components/games/GameBoxScoresTable";
import { CrashingViz } from "../../components/crashing/CrashingViz";
import { GamePlayerReboundingLuck } from "../../components/games/GamePlayerReboundingLuck";
import { GameTeamReboundingLuck } from "../../components/games/GameTeamReboundingLuck";
import { GameTeamShootingLuck } from "../../components/games/GameTeamShootingLuck";
import { GamePlayerShootingLuck } from "../../components/games/GamePlayerShootingLuck";
import { GameTeamShootingLuckBreakdown } from "../../components/games/GameTeamShootingLuckBreakdown";
import { GameTeamStats } from "../../components/games/GameTeamStats";
import { GameTeamActionBreakdown } from "../../components/games/GameTeamActionBreakdown";
import { GameAttackAvoidTables } from "../../components/games/GameAttackAvoidTables";
import { TeamColorBox } from "../../components/team/TeamColorBox";
import { GameXPtsBreakdownTable } from "../../components/games/GameXPtsBreakdownTable";
import { GameExpectedTurnoversTable } from "../../components/games/GameExpectedTurnoversTable";
import { GameChanceStartTypes } from "../../components/games/GameChanceStartTypes";
import { Restrict } from "../../components/core/Restrict";
import { CrashingTable } from "../../components/crashing/CrashingTable";
import { groupBy } from "../../../shared/util/Collections";
import {
  ChanceEfficiencyPerStartType,
  EffPerStartTypeForCompGame,
} from "../../../shared/routers/ChanceRouter";

export function GameSubPagePostgame(props: {
  home?: {
    teamid: number;
    teamCity: string;
    team: string;
    teamabbreviation: string;
    teamName: string;
  };
  away?: {
    teamid: number;
    teamCity: string;
    team: string;
    teamabbreviation: string;
    teamName: string;
  };
  fromDate: string;
  toDate: string;
  shots?: Shot[];
  leagueStats?: TeamStats[];
  awayTeamStats?: TeamStats[];
  homeTeamStats?: TeamStats[];
  gameExpectedStats?: GameExpectedStats[];
  boxscores?: GameBoxScore[];
  gameActions?: TeamActionBreakdown[];
  seasonActions?: TeamActionBreakdown[];
  teamRebounds?: GameTeamReboundModel[];
  crashAttempts?: CrashAttempt[];
  playerRebounds?: GamePlayerReboundModel[];
  expectedTovs?: GameExpectedTurnover[];
  attackAvoid?: GameAttackAvoid[];
  chanceStartTypeData?: ChanceEfficiencyPerStartType[];
  chanceStartTypeCompData?: EffPerStartTypeForCompGame[];
}) {
  const {
    home,
    away,
    fromDate,
    toDate,
    shots,
    leagueStats,
    awayTeamStats,
    homeTeamStats,
    gameExpectedStats,
    boxscores,
    gameActions,
    seasonActions,
    teamRebounds,
    crashAttempts,
    playerRebounds,
    expectedTovs,
    attackAvoid,
    chanceStartTypeData,
    chanceStartTypeCompData,
  } = props;

  const homeStats = homeTeamStats?.[0];
  const awayStats = awayTeamStats?.[0];

  if (!home || !away) return null;

  return (
    <div>
      <Row>
        <Col md={6}>
          {shots && (
            <TeamShootingLuckPanel
              home={home}
              away={away}
              fromDate={fromDate}
              toDate={toDate}
              shots={shots}
            />
          )}
        </Col>
        <Col md={6}>
          <Panel header={"xPts Breakdown"}>
            <GameXPtsBreakdownTable data={gameExpectedStats} />
          </Panel>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          {shots && (
            <PlayerShootingLuckPanel home={home} away={away} shots={shots} />
          )}
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <BoxScoresPanel data={boxscores} />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <TeamStatComparisonPanel
            home={home}
            away={away}
            leagueStats={leagueStats}
            awayTeamStats={awayTeamStats}
            homeTeamStats={homeTeamStats}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <OffensiveDefensiveBreakdownPanel
            home={home}
            away={away}
            gameActions={gameActions}
            seasonActions={seasonActions}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <TeamReboundsPanel
            teamRebounds={teamRebounds}
            home={home}
            away={away}
          />
        </Col>
        <Col md={6}>
          <GameCrashingPanel
            crashAttempts={crashAttempts}
            home={home}
            away={away}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <PlayerReboundsPanel
            playerRebounds={playerRebounds}
            home={home}
            away={away}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          {shots && homeStats && awayStats && (
            <TeamShootingLuckBreakdownPanel
              shots={shots}
              home={home}
              away={away}
              homeStats={homeStats}
              awayStats={awayStats}
            />
          )}
        </Col>
        <Col md={6}>
          <Restrict roles={["bia"]}>
            <Panel header={"xTOV Breakdown - [BIA ONLY]"}>
              <GameExpectedTurnoversTable expectedTovs={expectedTovs} />
            </Panel>
          </Restrict>
        </Col>
      </Row>
      <Row>
        <Col>
          <Restrict roles={["bia"]}>
            <Panel header={"WIP: Attack Avoid - [BIA ONLY]"}>
              <GameAttackAvoidTables data={attackAvoid} />
            </Panel>
          </Restrict>
        </Col>
      </Row>
      <Row>
        <Col>
          <Panel
            header="Possession Start Type Efficiency Breakdown"
            panelID="startTypeEfficiency"
          >
            <GameChanceStartTypes
              data={chanceStartTypeData}
              compData={chanceStartTypeCompData}
              home={home}
              away={away}
            />
          </Panel>
        </Col>
      </Row>
    </div>
  );
}

function TeamShootingLuckPanel(props: {
  home: {
    teamid: number;
    teamCity: string;
    team: string;
    teamabbreviation: string;
  };
  away: {
    teamid: number;
    teamCity: string;
    team: string;
    teamabbreviation: string;
  };
  fromDate: string;
  toDate: string;
  shots: Shot[];
}) {
  const { home, away, shots, fromDate, toDate } = props;
  return (
    <Panel panelID="teamShotQuality" header={"Team Shot Quality"}>
      <div>
        <TeamColorBox teamId={home.teamid} oppTeamId={away.teamid} />
        {home.teamCity}{" "}
        <TeamColorBox teamId={away.teamid} oppTeamId={home.teamid} />
        {away.teamCity}
        <GameTeamShootingLuck
          data={shots}
          home={home}
          away={away}
          fromDate={fromDate}
          toDate={toDate}
        />
      </div>
    </Panel>
  );
}

function PlayerShootingLuckPanel(props: {
  home: {
    teamid: number;
    teamCity: string;
    team: string;
  };
  away: {
    teamid: number;
    teamCity: string;
    team: string;
  };
  shots: Shot[];
}) {
  const { home, away, shots } = props;

  return (
    <Panel panelID="playerShotQuality" header={"Player Shot Quality"}>
      <GamePlayerShootingLuck data={shots} home={home} away={away} />
    </Panel>
  );
}

function BoxScoresPanel(props: { data?: GameBoxScore[] }) {
  const { data } = props;

  if (!data) return null;

  const dataByTeam = groupBy(data || [], (d) => d.homeAway);

  return (
    <Panel panelID="boxScores" header={"Box Scores"}>
      <>
        {/* Sort home before away. */}
        {Object.entries(dataByTeam)
          .sort((a, b) => (a[0] > b[0] ? -1 : 1))
          .map(([key, teamData]) => (
            <GameBoxScoresTable data={teamData} key={key} />
          ))}
      </>
    </Panel>
  );
}

function TeamStatComparisonPanel(props: {
  home: {
    teamid: number;
    teamCity: string;
    team: string;
    teamabbreviation: string;
  };
  away: {
    teamid: number;
    teamCity: string;
    team: string;
    teamabbreviation: string;
  };
  leagueStats?: TeamStats[];
  homeTeamStats?: TeamStats[];
  awayTeamStats?: TeamStats[];
}) {
  const { home, away, leagueStats, homeTeamStats, awayTeamStats } = props;

  const homeStats = homeTeamStats && homeTeamStats[0];
  const awayStats = awayTeamStats && awayTeamStats[0];

  return (
    <Panel panelID="teamStatComparison" header={"Team Stat Comparison"}>
      {homeStats && awayStats && leagueStats && (
        <GameTeamStats
          home={home}
          away={away}
          homeStats={homeStats}
          awayStats={awayStats}
          leagueStats={leagueStats}
        />
      )}
    </Panel>
  );
}

function OffensiveDefensiveBreakdownPanel(props: {
  home: {
    teamid: number;
    teamCity: string;
    team: string;
    teamabbreviation: string;
    teamName: string;
  };
  away: {
    teamid: number;
    teamCity: string;
    team: string;
    teamabbreviation: string;
    teamName: string;
  };
  gameActions?: TeamActionBreakdown[];
  seasonActions?: TeamActionBreakdown[];
}) {
  const { home, away, gameActions, seasonActions } = props;

  if (!gameActions || !seasonActions) return null;

  return (
    <Panel panelID="breakdown" header={"Offensive and Defensive Breakdown"}>
      <GameTeamActionBreakdown
        home={home}
        away={away}
        gameActions={gameActions}
        seasonActions={seasonActions}
      />
    </Panel>
  );
}

function TeamReboundsPanel(props: {
  teamRebounds?: GameTeamReboundModel[];
  home: {
    team: string;
    teamid: number;
    teamName: string;
    teamCity: string;
    teamabbreviation: string;
  };
  away: {
    team: string;
    teamid: number;
    teamName: string;
    teamCity: string;
    teamabbreviation: string;
  };
}) {
  const { teamRebounds, home, away } = props;

  return (
    <Panel panelID="teamRebounds" header={"Team Rebounding"}>
      {teamRebounds && (
        <div>
          <TeamColorBox teamId={home.teamid} oppTeamId={away.teamid} />
          {home.teamCity}{" "}
          <TeamColorBox teamId={away.teamid} oppTeamId={home.teamid} />
          {away.teamCity}
          <GameTeamReboundingLuck data={teamRebounds} home={home} away={away} />
        </div>
      )}
    </Panel>
  );
}

function PlayerReboundsPanel(props: {
  playerRebounds?: GamePlayerReboundModel[];
  home: {
    teamid: number;
    teamCity: string;
    team: string;

    teamabbreviation: string;
  };
  away: {
    teamid: number;
    teamCity: string;
    team: string;

    teamabbreviation: string;
  };
}) {
  const { playerRebounds, home, away } = props;

  return (
    <Panel panelID="playerRebounds" header={"Player Rebounding"}>
      {playerRebounds && (
        <GamePlayerReboundingLuck
          data={playerRebounds}
          home={home}
          away={away}
        />
      )}
    </Panel>
  );
}

function TeamShootingLuckBreakdownPanel(props: {
  shots: Shot[];
  home: {
    teamid: number;
    teamCity: string;
    team: string;

    teamabbreviation: string;
  };
  away: {
    teamid: number;
    teamCity: string;
    team: string;

    teamabbreviation: string;
  };
  homeStats: TeamStats;
  awayStats: TeamStats;
}) {
  const { shots, home, away, homeStats, awayStats } = props;
  return (
    <Panel
      panelID="teamShotQualityBreakdown"
      header={"Team Shot Quality Breakdown"}
    >
      <GameTeamShootingLuckBreakdown
        data={shots}
        home={home}
        away={away}
        awayFTPct={awayStats.ftPct}
        homeFTPct={homeStats.ftPct}
      />
    </Panel>
  );
}

function GameCrashingPanel(props: {
  crashAttempts?: CrashAttempt[];
  home: {
    teamid: number;
    teamCity: string;
    team: string;

    teamabbreviation: string;
  };
  away: {
    teamid: number;
    teamCity: string;
    team: string;

    teamabbreviation: string;
  };
}) {
  const { crashAttempts, home, away } = props;

  if (crashAttempts === undefined || crashAttempts.length === 0) return null;

  return (
    <Panel header="Team Crashing">
      <TeamCrashing
        data={crashAttempts}
        teams={[
          { teamName: home.team, teamId: home.teamid },
          { teamName: away.team, teamId: away.teamid },
        ]}
      />
    </Panel>
  );
}

function TeamCrashing(props: {
  data: CrashAttempt[];
  teams: { teamId: number; teamName: string }[];
}) {
  const { data, teams } = props;
  const firstTeam = teams[0];
  const [teamId, setTeamId] = useState<string>(
    firstTeam ? firstTeam.teamId.toString() : ""
  );
  const [player, setPlayer] = useState<string>("");
  const [period, setPeriod] = useState(0);
  const [location, setLocation] = useState("All Locations");
  const [noLayups, setNoLayups] = useState(true);

  const playerOptions: Array<{ value: string; label: string }> = [];
  data
    .filter((d) => d.teamId.toString() === teamId)
    .sort((a, b) => (a.player > b.player ? 1 : -1))
    .forEach((d) => {
      if (playerOptions.find((p) => p.value === d.playerId.toString())) return;
      playerOptions.push({ value: d.playerId.toString(), label: d.player });
    });

  const filteredData = data.filter((d) => {
    if (noLayups && d.shotType === "layup") return false;
    if (location !== "All Locations" && d.location !== location) return false;
    if (teamId !== "" && d.teamId.toString() !== teamId) return false;
    if (player !== "" && d.playerId.toString() !== player) return false;
    if (period === 0) return true;
    if (period === 5) return d.period > 4;
    return d.period === period;
  });

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          flexWrap: "wrap",
          gap: 8,
        }}
      >
        <select
          value={teamId}
          onChange={(evt) => {
            const newTeamId = evt.target.value;
            setTeamId(newTeamId);
            // Reset the player as well so we don't have a team/player mismatch.
            setPlayer("");
          }}
        >
          {teams.map((t) => (
            <option value={t.teamId.toString()} key={t.teamId.toString()}>
              {t.teamName}
            </option>
          ))}
        </select>
        <select value={player} onChange={(evt) => setPlayer(evt.target.value)}>
          <option value="">All Players</option>
          {playerOptions.map((po) => (
            <option value={po.value} key={po.value}>
              {po.label}
            </option>
          ))}
        </select>
        <select
          value={period}
          onChange={(evt) => setPeriod(parseInt(evt.target.value))}
        >
          {[
            { value: 0, label: "Full Game" },
            { value: 1, label: "1" },
            { value: 2, label: "2" },
            { value: 3, label: "3" },
            { value: 4, label: "4" },
            { value: 5, label: "OT" },
          ].map((po) => (
            <option value={po.value} key={po.value}>
              {po.label}
            </option>
          ))}
        </select>
        <select
          value={location}
          onChange={(evt) => setLocation(evt.target.value)}
        >
          {["All Locations", "Corner", "Inside the Arc", "Above the Break"].map(
            (l) => (
              <option value={l} key={l}>
                {l}
              </option>
            )
          )}
        </select>
        <Form.Check
          type="checkbox"
          checked={noLayups}
          label="Hide Layups"
          onChange={() => setNoLayups(!noLayups)}
        />
      </div>
      <CrashingViz data={filteredData} disableHover={player === ""} />
      <CrashingTable data={filteredData} />
    </div>
  );
}
