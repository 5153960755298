import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQueryParams, StringParam, withDefault } from "use-query-params";
import moment from "moment";

import AppContext from "../../shared/AppContext";
import { getSeasonFromDate } from "../util/Util";
import { Page } from "../components/core/Page";
import { Tabs } from "../components/core/PageTabs";
import { DebugInfo } from "../components/core/DebugInfo";
import { GamePageHeader } from "../components/games/GamePageHeader";
import { GameBoxScoreGame } from "../../shared/routers/GameRouter";
import { GameSubPagePostgame } from "../subpages/game/GameSubPagePostgame";
import { GameSubPageShooting } from "../subpages/game/GameSubPageShooting";
import { GameSubPagePnr } from "../subpages/game/GameSubPagePnr";
import { GameSubPageLineups } from "../subpages/game/GameSubPageLineups";
import { GameSubPageGameFlow } from "../subpages/game/GameSubPageGameFlow";
import { GameSubPageSchedule } from "../subpages/game/GameSubPageSchedule";
import { GameSubPageVideo } from "../subpages/game/GameSubPageVideo";
import { CELTICS_TEAM_ID } from "../constants/AppConstants";
import { trpc } from "../util/tRPC";

export function GamePage() {
  const { id } = useParams();

  // Page state query params.
  const [queryParams, setQueryParams] = useQueryParams({
    tab: withDefault(StringParam, "postgame"),
    scrollTo: StringParam,
  });
  const { tab, scrollTo } = queryParams;

  useEffect(() => {
    const el = document.querySelector(`#${scrollTo}`);
    if (!el) return;
    const yOffset = -76; // The navbar + team selector.
    const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: "smooth" });
  }, [scrollTo]);

  const { data: gameBoxScoreGames } = trpc.game.getGameBoxScoreGames.useQuery({
    gameId: id,
  });
  const home =
    gameBoxScoreGames &&
    gameBoxScoreGames.find((gbsg) => gbsg.homeAway === "home");
  const away =
    gameBoxScoreGames &&
    gameBoxScoreGames.find((gbsg) => gbsg.homeAway === "away");

  const onSubLinkClick = (subLink: string) => {
    const tab = Object.keys(pageTabs.tabs).find((pt) => {
      const tarTab = pageTabs.tabs[pt];
      if (!tarTab) return;
      return tarTab.sublinks.find((sl) => sl.refId === subLink);
    });
    if (!tab) return;
    setQueryParams({ tab, scrollTo: subLink });
  };

  if (!id) return null;

  const pageTabs = getPageTabs(parseInt(id), home, away);

  if (!home || !away) return null;

  const title = `${away.teamabbreviation} @ ${home.teamabbreviation} - ${moment(
    home.gameDateTimeStr
  ).format("MM/DD/YYYY")}`;

  const ptsByQuarter: Record<number, { homePts: number; awayPts: number }> = {};
  const numQuarters = home.numQuarters;
  for (let i = 0; i < numQuarters; i++) {
    ptsByQuarter[i + 1] = {
      homePts: getQuarterPts(i + 1, home),
      awayPts: getQuarterPts(i + 1, away),
    };
  }

  return (
    <Page
      header={{
        component: (
          <GamePageHeader
            gameId={id}
            homeTeamId={home.teamid}
            awayTeamId={away.teamid}
            homeAbbr={home.teamabbreviation}
            awayAbbr={away.teamabbreviation}
            xPPPHome={home.xPPP}
            xPPPAway={away.xPPP}
            xPtsHome={
              home.xPPP === null || home.numPossessions === null
                ? null
                : home.xPPP * home.numPossessions
            }
            xPtsAway={
              away.xPPP === null || away.numPossessions === null
                ? null
                : away.xPPP * away.numPossessions
            }
            gameDateTimeStr={home.gameDateTimeStr}
            arenaname={home.arenaname}
            location={home.location}
            ptsByQuarter={ptsByQuarter}
            lastUpdatedStr={""}
          />
        ),
      }}
      title={title}
      tabs={pageTabs}
      activeTab={tab}
      onTabClick={(newTab) => setQueryParams({ tab: newTab })}
      onSubLinkClick={(subLink) => onSubLinkClick(subLink)}
    />
  );
}

function getPageTabs(
  gameId: number,
  home?: GameBoxScoreGame,
  away?: GameBoxScoreGame
): Tabs {
  const homeTeamId = home && home.teamid.toString();
  const homeTeam = home && home.teamabbreviation;
  const awayTeamId = away && away.teamid.toString();
  const awayTeam = away && away.teamabbreviation;

  // Fall back to current season if we fail to figure out the season.
  const season =
    (home && getSeasonFromDate(home.gameDateTimeStr)) ||
    AppContext.currentSeason.toString();

  const leagueFilters = {
    seasonStart: season,
    seasonEnd: season,
    preseason: AppContext.inPreseason ? 1 : 0,
    regularSeason: 1,
    postseason: 1,
  };

  // Post Game
  const { data: homeTeamStats } = trpc.team.getTeamStats.useQuery({
    gameIds: gameId.toString(),
    teamId: homeTeamId,
  });
  const { data: gameExpectedStats } = trpc.game.getGameExpectedStats.useQuery({
    gameIds: [gameId],
  });
  const { data: awayTeamStats } = trpc.team.getTeamStats.useQuery({
    gameIds: gameId.toString(),
    teamId: awayTeamId,
  });
  const { data: boxscores } = trpc.game.getGameBoxScores.useQuery({
    gameIds: [gameId],
  });
  const { data: leagueStats } = trpc.team.getTeamStats.useQuery(leagueFilters);

  const { data: gameActions } = trpc.team.getTeamActionsBreakdown.useQuery({
    gameIds: gameId.toString(),
  });
  const { data: seasonActions } =
    trpc.team.getTeamActionsBreakdown.useQuery(leagueFilters);

  const { data: teamRebounds } = trpc.game.getGameTeamReboundModel.useQuery({
    gameIds: [gameId],
  });
  const { data: crashAttempts } = trpc.game.getCrashAttempts.useQuery({
    gameIds: [gameId],
  });
  const { data: playerRebounds } = trpc.game.getGamePlayerReboundModel.useQuery(
    {
      gameIds: [gameId],
    }
  );
  const { data: expectedTovs } = trpc.game.getGameExpectedTurnovers.useQuery({
    gameIds: [gameId],
  });
  const { data: attackAvoid } = trpc.game.getGameAttackAvoid.useQuery({
    gameIds: [gameId],
  });
  const { data: chanceStartTypeData } =
    trpc.chance.getChanceEfficiencyPerStartType.useQuery({
      gameIds: [gameId],
    });

  const { data: chanceStartTypeCompData } =
    trpc.chance.geteffPerStartTypeForCompGameByPeriod.useQuery({
      teamId: CELTICS_TEAM_ID,
    });

  // Shooting
  const { data: shots } = trpc.shot.getShots.useQuery({
    filters: {
      gameIds: gameId ? [gameId.toString()] : [],
    },
  });

  // PNRs
  const { data: pnrs } = trpc.game.getGamePnrs.useQuery({
    gameIds: gameId ? [gameId] : [],
  });
  const { data: pnrBreakdowns } = trpc.game.getGamePnrBreakdown.useQuery({
    gameIds: gameId ? [gameId] : [],
  });

  // Lineups
  const { data: lineupBreakdowns } = trpc.game.getLineupBreakdowns.useQuery({
    gameIds: [gameId],
  });

  // Game Flow
  const { data: lineups } = trpc.game.getGameLineups.useQuery({
    gameIds: [gameId],
  });
  const { data: players } = trpc.game.getGamePlayers.useQuery({
    gameIds: [gameId],
  });
  const { data: possessions } = trpc.game.getGamePossessions.useQuery({
    gameIds: [gameId],
  });
  const { data: awaySubs } = trpc.team.getTeamSubstitutionPatterns.useQuery({
    teamId: awayTeamId,
    games: gameId.toString(),
  });

  const { data: homeSubs } = trpc.team.getTeamSubstitutionPatterns.useQuery({
    teamId: homeTeamId,
    games: gameId.toString(),
  });

  const { data: gameWinProb } = trpc.game.getGameWinProbability.useQuery({
    gameIds: [gameId],
  });

  // Schedule
  const { data: awaySchedule } = trpc.team.getTeamSchedule.useQuery({
    teamId: awayTeamId,
  });
  const { data: homeSchedule } = trpc.team.getTeamSchedule.useQuery({
    teamId: homeTeamId,
  });

  // Video
  const { data: gameSegments } = trpc.synergy.getSynergyGameSegments.useQuery({
    gameIds: [gameId],
  });
  const { data: gameClips } = trpc.synergy.getSynergyGameClips.useQuery({
    gameIds: [gameId],
  });
  const { data: gameDetails } = trpc.game.getGameDetails.useQuery({
    gameIds: [gameId],
  });

  const pageTabs = {
    tabs: {
      postgame: {
        label: "Overview",
        sublinks: [
          { label: "Team Shot Quality", refId: "teamShotQuality" },
          { label: "Player Shot Quality", refId: "playerShotQuality" },
          { label: "Box Scores", refId: "boxScores" },
          { label: "Offensive and Defensive Breakdown", refId: "breakdown" },
          { label: "Team Stat Comparison", refId: "teamStatComparison" },
          { label: "Team Rebounding", refId: "teamRebounds" },
          { label: "Player Rebounding", refId: "playerRebounds" },
          {
            label: "Team Shot Quality Breakdown",
            refId: "teamShotQualityBreakdown",
          },
          {
            label: "Possession Start Type Efficiency Breakdown",
            refId: "startTypeEfficiency",
          },
        ],
        content: (
          <div>
            <GameSubPagePostgame
              home={home}
              away={away}
              fromDate={home ? home.gameDateTimeStr : ""}
              toDate={home ? home.gameDateTimeStr : ""}
              shots={shots}
              leagueStats={leagueStats}
              homeTeamStats={homeTeamStats}
              awayTeamStats={awayTeamStats}
              gameExpectedStats={gameExpectedStats}
              boxscores={boxscores}
              gameActions={gameActions}
              seasonActions={seasonActions}
              teamRebounds={teamRebounds}
              crashAttempts={crashAttempts}
              playerRebounds={playerRebounds}
              expectedTovs={expectedTovs}
              attackAvoid={attackAvoid}
              chanceStartTypeData={chanceStartTypeData}
              chanceStartTypeCompData={chanceStartTypeCompData}
            />
            <DebugInfo gameId={gameId} />
          </div>
        ),
      },
      shooting: {
        label: "Shooting",
        content: (
          <div>
            <GameSubPageShooting home={home} away={away} shots={shots} />
            <DebugInfo gameId={gameId} />
          </div>
        ),
        sublinks: [
          { label: `Shot Quality · ${awayTeam}`, refId: "shotQualityAway" },
          { label: `Shot Quality · ${homeTeam}`, refId: "shotQualityHome" },
          { label: `Shot Charts · ${awayTeam}`, refId: "shotChartsAway" },
          { label: `Shot Charts · ${homeTeam}`, refId: "shotChartsHome" },
        ],
      },
      pnr: {
        label: "PNR",
        content: (
          <div>
            <GameSubPagePnr
              pnrs={pnrs}
              pnrBreakdowns={pnrBreakdowns}
              home={home}
              away={away}
            />
            <DebugInfo gameId={gameId} />
          </div>
        ),
        sublinks: [
          { label: `PNR Breakdown · ${homeTeam}`, refId: "pnrBreakdownhome" },
          { label: `PNR Offense · ${homeTeam}`, refId: "pnrOffensehome" },
          { label: `PNR Defense · ${homeTeam}`, refId: "pnrDefensehome" },
          { label: `PNR Breakdown · ${awayTeam}`, refId: "pnrBreakdownaway" },
          { label: `PNR Offense · ${awayTeam}`, refId: "pnrOffenseaway" },
          { label: `PNR Defense · ${awayTeam}`, refId: "pnrDefenseaway" },
        ],
      },

      lineups: {
        label: "Lineups",
        content: (
          <div>
            <GameSubPageLineups
              lineupBreakdowns={lineupBreakdowns}
              home={home}
              away={away}
            />{" "}
            <DebugInfo gameId={gameId} />
          </div>
        ),
        sublinks: [
          { label: "Lineups Summary", refId: "lineupsSummary" },
          { label: "Lineups By Time", refId: "lineupsByTime" },
        ],
      },

      gameFlow: {
        label: "Game Flow",
        content: (
          <div>
            <GameSubPageGameFlow
              home={home}
              away={away}
              lineups={lineups}
              players={players}
              possessions={possessions}
              awaySubs={awaySubs}
              homeSubs={homeSubs}
              gameWinProb={gameWinProb}
            />{" "}
            <DebugInfo gameId={gameId} />
          </div>
        ),
        sublinks: [
          { label: "Game Flow", refId: "gameFlow" },
          { label: "Sub Pattern", refId: "subPattern" },
          // TODO(chrisbu): Uncomment when/if this is launched.
          // { label: "Win Probability", refId: "winProbability" },
        ],
      },

      schedules: {
        label: "Schedules",
        content: (
          <div>
            <GameSubPageSchedule
              gameId={gameId}
              home={home}
              away={away}
              awaySchedule={awaySchedule}
              homeSchedule={homeSchedule}
            />{" "}
            <DebugInfo gameId={gameId} />
          </div>
        ),
        sublinks: [
          { label: "Away Schedule", refId: "awaySchedule" },
          { label: "Home Schedule", refId: "homeSchedule" },
        ],
      },

      video: {
        label: "Video",
        content: (
          <div>
            <GameSubPageVideo
              home={home}
              away={away}
              gameSegments={gameSegments}
              gameClips={gameClips}
              gameDetails={gameDetails}
            />{" "}
            <DebugInfo gameId={gameId} />
          </div>
        ),
        sublinks: [{ label: "Video", refId: "video" }],
      },
    },
  };

  return pageTabs;
}

function getQuarterPts(quarter: number, gbsg: GameBoxScoreGame) {
  if (quarter === 1) return gbsg.q1pts;
  else if (quarter === 2) return gbsg.q2pts;
  else if (quarter === 3) return gbsg.q3pts;
  else if (quarter === 4) return gbsg.q4pts;
  else if (quarter === 5) return gbsg.q5pts;
  else if (quarter === 6) return gbsg.q6pts;
  else if (quarter === 7) return gbsg.q7pts;
  else if (quarter === 8) return gbsg.q8pts;
  return 0;
}
