interface NbaDate {
  season: { start: number; end: number };
  preseason: { start: number; end: number };
  postseason: { start: number; end: number };
  allstar: { start: number; end: number };
}

export const NbaDates: Record<string, NbaDate> = {
  "2005": {
    preseason: { start: Date.parse("10/10/04"), end: Date.parse("10/29/04") },
    season: { start: Date.parse("11/2/04"), end: Date.parse("4/20/05") },
    postseason: { start: Date.parse("4/23/05"), end: Date.parse("6/23/05") },
    allstar: { start: Date.parse("2/18/05"), end: Date.parse("2/21/05") },
  },
  "2006": {
    preseason: { start: Date.parse("10/10/05"), end: Date.parse("10/28/05") },
    season: { start: Date.parse("11/1/05"), end: Date.parse("4/19/06") },
    postseason: { start: Date.parse("4/22/06"), end: Date.parse("6/20/06") },
    allstar: { start: Date.parse("2/17/06"), end: Date.parse("2/20/06") },
  },
  "2007": {
    preseason: { start: Date.parse("10/9/06"), end: Date.parse("10/27/06") },
    season: { start: Date.parse("10/31/06"), end: Date.parse("4/18/07") },
    postseason: { start: Date.parse("4/21/07"), end: Date.parse("6/14/07") },
    allstar: { start: Date.parse("2/16/07"), end: Date.parse("2/19/07") },
  },
  "2008": {
    preseason: { start: Date.parse("10/6/07"), end: Date.parse("10/26/07") },
    season: { start: Date.parse("10/30/07"), end: Date.parse("4/16/08") },
    postseason: { start: Date.parse("4/19/08"), end: Date.parse("6/17/08") },
    allstar: { start: Date.parse("2/15/08"), end: Date.parse("2/18/08") },
  },
  "2009": {
    preseason: { start: Date.parse("10/2/08"), end: Date.parse("10/24/08") },
    season: { start: Date.parse("10/28/08"), end: Date.parse("4/15/09") },
    postseason: { start: Date.parse("4/18/09"), end: Date.parse("6/7/09") },
    allstar: { start: Date.parse("2/13/09"), end: Date.parse("2/16/09") },
  },
  "2010": {
    preseason: { start: Date.parse("10/1/09"), end: Date.parse("10/23/09") },
    season: { start: Date.parse("10/27/09"), end: Date.parse("4/14/10") },
    postseason: { start: Date.parse("4/17/10"), end: Date.parse("6/17/10") },
    allstar: { start: Date.parse("2/12/10"), end: Date.parse("2/15/10") },
  },
  "2011": {
    preseason: { start: Date.parse("10/4/10"), end: Date.parse("10/22/10") },
    season: { start: Date.parse("10/26/10"), end: Date.parse("4/13/11") },
    postseason: { start: Date.parse("4/16/11"), end: Date.parse("6/9/11") },
    allstar: { start: Date.parse("2/18/11"), end: Date.parse("2/21/11") },
  },
  "2012": {
    preseason: { start: Date.parse("12/16/11"), end: Date.parse("12/22/11") },
    season: { start: Date.parse("12/25/11"), end: Date.parse("4/26/12") },
    postseason: { start: Date.parse("4/28/12"), end: Date.parse("6/21/12") },
    allstar: { start: Date.parse("2/24/12"), end: Date.parse("2/27/12") },
  },
  "2013": {
    preseason: { start: Date.parse("10/5/12"), end: Date.parse("10/26/12") },
    season: { start: Date.parse("10/30/12"), end: Date.parse("4/17/13") },
    postseason: { start: Date.parse("4/20/13"), end: Date.parse("6/20/13") },
    allstar: { start: Date.parse("2/15/13"), end: Date.parse("2/18/13") },
  },
  "2014": {
    preseason: { start: Date.parse("10/5/13"), end: Date.parse("10/25/13") },
    season: { start: Date.parse("10/29/13"), end: Date.parse("4/16/14") },
    postseason: { start: Date.parse("4/19/14"), end: Date.parse("6/15/14") },
    allstar: { start: Date.parse("2/14/14"), end: Date.parse("2/17/14") },
  },
  "2015": {
    preseason: { start: Date.parse("10/4/14"), end: Date.parse("10/24/14") },
    season: { start: Date.parse("10/28/14"), end: Date.parse("4/15/15") },
    postseason: {
      start: Date.parse("04/18/2015"),
      end: Date.parse("06/16/15"),
    },
    allstar: { start: Date.parse("2/13/15"), end: Date.parse("2/18/15") },
  },
  "2016": {
    preseason: { start: Date.parse("10/3/15"), end: Date.parse("10/23/15") },
    season: { start: Date.parse("10/27/15"), end: Date.parse("4/13/16") },
    postseason: {
      start: Date.parse("04/16/2016"),
      end: Date.parse("06/20/16"),
    },
    allstar: { start: Date.parse("2/12/16"), end: Date.parse("2/14/16") },
  },
  "2017": {
    preseason: { start: Date.parse("10/3/16"), end: Date.parse("10/21/16") },
    season: { start: Date.parse("10/25/16"), end: Date.parse("4/12/17") },
    postseason: {
      start: Date.parse("04/16/17"),
      end: Date.parse("06/20/17"),
    },
    allstar: { start: Date.parse("2/17/17"), end: Date.parse("2/19/17") },
  },
  "2018": {
    preseason: { start: Date.parse("9/30/17"), end: Date.parse("10/13/17") },
    season: { start: Date.parse("10/17/17"), end: Date.parse("4/11/18") },
    postseason: {
      start: Date.parse("04/12/18"),
      end: Date.parse("06/25/18"),
    },
    allstar: { start: Date.parse("2/16/18"), end: Date.parse("2/18/18") },
  },
  "2019": {
    preseason: { start: Date.parse("9/28/18"), end: Date.parse("10/12/18") },
    season: { start: Date.parse("10/16/18"), end: Date.parse("4/10/19") },
    postseason: {
      start: Date.parse("04/12/19"),
      end: Date.parse("06/30/19"),
    },
    allstar: { start: Date.parse("2/15/19"), end: Date.parse("2/17/19") },
  },
  "2020": {
    preseason: { start: Date.parse("9/28/19"), end: Date.parse("10/20/19") },
    season: { start: Date.parse("10/21/19"), end: Date.parse("8/14/20") },
    postseason: {
      start: Date.parse("08/15/20"),
      end: Date.parse("10/13/20"),
    },
    allstar: { start: Date.parse("2/14/20"), end: Date.parse("2/16/20") },
  },
  "2021": {
    preseason: { start: Date.parse("12/10/20"), end: Date.parse("12/21/20") },
    season: { start: Date.parse("12/22/20"), end: Date.parse("05/16/21") },
    postseason: {
      start: Date.parse("05/18/21"),
      end: Date.parse("07/22/21"),
    },
    allstar: { start: Date.parse("3/05/21"), end: Date.parse("3/07/21") },
  },
  "2022": {
    preseason: { start: Date.parse("10/02/21"), end: Date.parse("10/16/21") },
    season: { start: Date.parse("10/18/21"), end: Date.parse("4/11/22") },
    postseason: {
      start: Date.parse("04/12/22"),
      end: Date.parse("06/22/22"),
    },
    allstar: { start: Date.parse("2/18/22"), end: Date.parse("2/21/22") },
  },
  "2023": {
    preseason: { start: Date.parse("9/30/22"), end: Date.parse("10/14/22") },
    season: { start: Date.parse("10/18/22"), end: Date.parse("4/09/23") },
    postseason: {
      start: Date.parse("04/11/23"),
      end: Date.parse("06/18/23"),
    },
    allstar: { start: Date.parse("2/17/23"), end: Date.parse("2/19/23") },
  },
  "2024": {
    preseason: { start: Date.parse("10/05/23"), end: Date.parse("10/20/23") },
    season: { start: Date.parse("10/24/23"), end: Date.parse("4/14/24") },
    postseason: {
      start: Date.parse("4/15/24"),
      end: Date.parse("6/16/24"),
    },
    allstar: { start: Date.parse("2/16/24"), end: Date.parse("2/18/24") },
  },
  "2025": {
    preseason: { start: Date.parse("10/05/24"), end: Date.parse("10/18/24") },
    season: { start: Date.parse("10/22/24"), end: Date.parse("4/13/25") },
    postseason: {
      start: Date.parse("4/19/25"),
      // TODO(chrisbu): Get date confirmed with Mike.
      end: Date.parse("6/15/25"),
    },
    allstar: { start: Date.parse("2/14/25"), end: Date.parse("2/16/25") },
  },
};
