import React, { useMemo, useState } from "react";
import { extent } from "d3";

import { Table, SortingState, createColumnHelper } from "../core/Table";
import { GamePnrBreakdown } from "../../../shared/routers/GameRouter";
import { groupBy } from "../../../shared/util/Collections";
import { sumFromField, weightedAverage } from "../../util/Util";
import { decFormat2, intFormat } from "../../util/Format";
import { Highlights } from "../../constants/AppConstants";

const pppColorDomain = [0.84, 1.2];

const columnHelper = createColumnHelper<PnrBreakdownRow>();

export function GamePnrBreakdownTable(props: { data: GamePnrBreakdown[] }) {
  const { data } = props;
  const [sorting, setSorting] = useState<SortingState>();

  const dataByType = groupBy(data, (d) => d.type);

  const preparedData = ["dho", "handback", "middle", "stepUp", "wing"].map(
    (type) => {
      const dataForType = dataByType[type] || [];
      const vsBlitz = dataForType.filter((d) => d.defType === "blitz");
      const vsContain = dataForType.filter((d) => d.defType === "contain");
      const vsIce = dataForType.filter((d) => d.defType === "ice");
      const vsReject = dataForType.filter((d) => d.defType === "reject");
      const vsShow = dataForType.filter((d) => d.defType === "show");
      const vsSoft = dataForType.filter((d) => d.defType === "soft");
      const vsVeer = dataForType.filter((d) => d.defType === "veer");
      const vsSwitch = dataForType.filter((d) => d.defType === "switch");
      const vsUnder = dataForType.filter((d) => d.defType === "under");
      const vsUpToTouch = dataForType.filter(
        (d) => d.defType === "up to touch"
      );
      const vsWeak = dataForType.filter((d) => d.defType === "weak");
      const vsNonThreat = dataForType.filter((d) => d.defType === "NT");

      return {
        type,
        overall: sumFromField("numPicks", dataForType) || 0,
        overallPpp: weightedAverage("numPicks", "ppp", dataForType),
        blitz: sumFromField("numPicks", vsBlitz) || null,
        blitzPpp: weightedAverage("numPicks", "ppp", vsBlitz) || null,
        contain: sumFromField("numPicks", vsContain) || null,
        containPpp: weightedAverage("numPicks", "ppp", vsContain) || null,
        ice: sumFromField("numPicks", vsIce) || null,
        icePpp: weightedAverage("numPicks", "ppp", vsIce) || null,
        reject: sumFromField("numPicks", vsReject) || null,
        rejectPpp: weightedAverage("numPicks", "ppp", vsReject) || null,
        show: sumFromField("numPicks", vsShow) || null,
        showPpp: weightedAverage("numPicks", "ppp", vsShow) || null,
        soft: sumFromField("numPicks", vsSoft) || null,
        softPpp: weightedAverage("numPicks", "ppp", vsSoft) || null,
        veer: sumFromField("numPicks", vsVeer) || null,
        veerPpp: weightedAverage("numPicks", "ppp", vsVeer) || null,
        switch: sumFromField("numPicks", vsSwitch) || null,
        switchPpp: weightedAverage("numPicks", "ppp", vsSwitch) || null,
        under: sumFromField("numPicks", vsUnder) || null,
        underPpp: weightedAverage("numPicks", "ppp", vsUnder) || null,
        upToTouch: sumFromField("numPicks", vsUpToTouch) || null,
        upToTouchPpp: weightedAverage("numPicks", "ppp", vsUpToTouch) || null,
        weak: sumFromField("numPicks", vsWeak) || null,
        weakPpp: weightedAverage("numPicks", "ppp", vsWeak) || null,
        nonThreat: sumFromField("numPicks", vsNonThreat) || null,
        nonThreatPpp: weightedAverage("numPicks", "ppp", vsNonThreat) || null,
      };
    }
  );

  const vsBlitz = data.filter((d) => d.defType === "blitz");
  const vsContain = data.filter((d) => d.defType === "contain");
  const vsIce = data.filter((d) => d.defType === "ice");
  const vsReject = data.filter((d) => d.defType === "reject");
  const vsShow = data.filter((d) => d.defType === "show");
  const vsSoft = data.filter((d) => d.defType === "soft");
  const vsVeer = data.filter((d) => d.defType === "veer");
  const vsSwitch = data.filter((d) => d.defType === "switch");
  const vsUnder = data.filter((d) => d.defType === "under");
  const vsUpToTouch = data.filter((d) => d.defType === "up to touch");
  const vsWeak = data.filter((d) => d.defType === "weak");
  const vsNonThreat = data.filter((d) => d.defType === "NT");
  const overallRow = {
    type: "Overall",
    overall: sumFromField("numPicks", data) || 0,
    overallPpp: weightedAverage("numPicks", "ppp", data),
    blitz: sumFromField("numPicks", vsBlitz) || null,
    blitzPpp: weightedAverage("numPicks", "ppp", vsBlitz) || null,
    contain: sumFromField("numPicks", vsContain) || null,
    containPpp: weightedAverage("numPicks", "ppp", vsContain) || null,
    ice: sumFromField("numPicks", vsIce) || null,
    icePpp: weightedAverage("numPicks", "ppp", vsIce) || null,
    reject: sumFromField("numPicks", vsReject) || null,
    rejectPpp: weightedAverage("numPicks", "ppp", vsReject) || null,
    show: sumFromField("numPicks", vsShow) || null,
    showPpp: weightedAverage("numPicks", "ppp", vsShow) || null,
    soft: sumFromField("numPicks", vsSoft) || null,
    softPpp: weightedAverage("numPicks", "ppp", vsSoft) || null,
    veer: sumFromField("numPicks", vsVeer) || null,
    veerPpp: weightedAverage("numPicks", "ppp", vsVeer) || null,
    switch: sumFromField("numPicks", vsSwitch) || null,
    switchPpp: weightedAverage("numPicks", "ppp", vsSwitch) || null,
    under: sumFromField("numPicks", vsUnder) || null,
    underPpp: weightedAverage("numPicks", "ppp", vsUnder) || null,
    upToTouch: sumFromField("numPicks", vsUpToTouch) || null,
    upToTouchPpp: weightedAverage("numPicks", "ppp", vsUpToTouch) || null,
    weak: sumFromField("numPicks", vsWeak) || null,
    weakPpp: weightedAverage("numPicks", "ppp", vsWeak) || null,
    nonThreat: sumFromField("numPicks", vsNonThreat) || null,
    nonThreatPpp: weightedAverage("numPicks", "ppp", vsNonThreat) || null,
  };

  const countColorDomains: Record<string, number[]> = {
    overall: extent(preparedData.map((d) => d.overall)) as number[],
    blitz: extent(preparedData.map((d) => d.blitz || 0)) as number[],
    contain: extent(preparedData.map((d) => d.contain || 0)) as number[],
    ice: extent(preparedData.map((d) => d.ice || 0)) as number[],
    reject: extent(preparedData.map((d) => d.reject || 0)) as number[],
    show: extent(preparedData.map((d) => d.show || 0)) as number[],
    soft: extent(preparedData.map((d) => d.soft || 0)) as number[],
    veer: extent(preparedData.map((d) => d.veer || 0)) as number[],
    switch: extent(preparedData.map((d) => d.switch || 0)) as number[],
    under: extent(preparedData.map((d) => d.under || 0)) as number[],
    upToTouch: extent(preparedData.map((d) => d.upToTouch || 0)) as number[],
    weak: extent(preparedData.map((d) => d.weak || 0)) as number[],
    nonThreat: extent(preparedData.map((d) => d.nonThreat || 0)) as number[],
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor("type", {
        header: () => "Type",
        cell: (info) => PnrTypeLabelMap[info.getValue()],
        footer: () => <b>{overallRow.type}</b>,
        meta: { group: 0, textAlign: "left" },
      }),
      columnHelper.group({
        id: "overallGroup",
        meta: { group: 1 },
        header: "Overall",
        columns: [
          columnHelper.accessor("overall", {
            header: () => "#",
            cell: (info) => intFormat(info.getValue()),
            footer: () => overallRow.overall,
            meta: {
              group: 1,
              highlights: Highlights.Max,
              colorDomain: countColorDomains["overall"],
              neutralColorScheme: true,
            },
          }),
          columnHelper.accessor("overallPpp", {
            header: () => "PPP",
            cell: (info) => decFormat2(info.getValue()),
            footer: decFormat2(overallRow.overallPpp),
            meta: {
              group: 1,
              highlights: Highlights.Max,
              colorDomain: pppColorDomain,
            },
          }),
        ],
      }),
      columnHelper.group({
        id: "blitzGroup",
        meta: { group: 1 },
        header: "vs. Blitz",
        columns: [
          columnHelper.accessor("blitz", {
            header: () => "#",
            cell: (info) => intFormat(info.getValue()),
            footer: () => overallRow.blitz,
            meta: {
              group: 2,
              highlights: Highlights.Max,
              colorDomain: countColorDomains["blitz"],
              neutralColorScheme: true,
            },
          }),
          columnHelper.accessor("blitzPpp", {
            header: () => "PPP",
            cell: (info) => decFormat2(info.getValue()),
            footer: () => decFormat2(overallRow.blitzPpp),
            meta: {
              group: 2,
              highlights: Highlights.Max,
              colorDomain: pppColorDomain,
            },
          }),
        ],
      }),
      columnHelper.group({
        id: "containGroup",
        meta: { group: 3 },
        header: "vs. Contain",
        columns: [
          columnHelper.accessor("contain", {
            header: () => "#",
            cell: (info) => intFormat(info.getValue()),
            footer: () => overallRow.contain,
            meta: {
              group: 3,
              highlights: Highlights.Max,
              colorDomain: countColorDomains["contain"],
              neutralColorScheme: true,
            },
          }),
          columnHelper.accessor("containPpp", {
            header: () => "PPP",
            cell: (info) => decFormat2(info.getValue()),
            footer: () => decFormat2(overallRow.containPpp),
            meta: {
              group: 3,
              highlights: Highlights.Max,
              colorDomain: pppColorDomain,
            },
          }),
        ],
      }),
      columnHelper.group({
        id: "iceGroup",
        meta: { group: 4 },
        header: "vs. Ice",
        columns: [
          columnHelper.accessor("ice", {
            header: () => "#",
            cell: (info) => intFormat(info.getValue()),
            footer: () => overallRow.ice,
            meta: {
              group: 4,
              highlights: Highlights.Max,
              colorDomain: countColorDomains["ice"],
              neutralColorScheme: true,
            },
          }),
          columnHelper.accessor("icePpp", {
            header: () => "PPP",
            cell: (info) => decFormat2(info.getValue()),
            footer: () => decFormat2(overallRow.icePpp),
            meta: {
              group: 4,
              highlights: Highlights.Max,
              colorDomain: pppColorDomain,
            },
          }),
        ],
      }),
      columnHelper.group({
        id: "rejectGroup",
        meta: { group: 5 },
        header: "vs. Reject",
        columns: [
          columnHelper.accessor("reject", {
            header: () => "#",
            cell: (info) => intFormat(info.getValue()),
            footer: () => overallRow.reject,
            meta: {
              group: 5,
              highlights: Highlights.Max,
              colorDomain: countColorDomains["reject"],
              neutralColorScheme: true,
            },
          }),
          columnHelper.accessor("rejectPpp", {
            header: () => "PPP",
            cell: (info) => decFormat2(info.getValue()),
            footer: () => decFormat2(overallRow.rejectPpp),
            meta: {
              group: 5,
              highlights: Highlights.Max,
              colorDomain: pppColorDomain,
            },
          }),
        ],
      }),
      columnHelper.group({
        id: "showGroup",
        meta: { group: 6 },
        header: "vs. Show",
        columns: [
          columnHelper.accessor("show", {
            header: () => "#",
            cell: (info) => intFormat(info.getValue()),
            footer: () => overallRow.show,
            meta: {
              group: 6,
              highlights: Highlights.Max,
              colorDomain: countColorDomains["show"],
              neutralColorScheme: true,
            },
          }),
          columnHelper.accessor("showPpp", {
            header: () => "PPP",
            cell: (info) => decFormat2(info.getValue()),
            footer: () => decFormat2(overallRow.showPpp),
            meta: {
              group: 6,
              highlights: Highlights.Max,
              colorDomain: pppColorDomain,
            },
          }),
        ],
      }),
      columnHelper.group({
        id: "softGroup",
        meta: { group: 7 },
        header: "vs. Soft",
        columns: [
          columnHelper.accessor("soft", {
            header: () => "#",
            cell: (info) => intFormat(info.getValue()),
            footer: () => overallRow.soft,
            meta: {
              group: 7,
              highlights: Highlights.Max,
              colorDomain: countColorDomains["soft"],
              neutralColorScheme: true,
            },
          }),
          columnHelper.accessor("softPpp", {
            header: () => "PPP",
            cell: (info) => decFormat2(info.getValue()),
            footer: () => decFormat2(overallRow.softPpp),
            meta: {
              group: 7,
              highlights: Highlights.Max,
              colorDomain: pppColorDomain,
            },
          }),
        ],
      }),
      columnHelper.group({
        id: "veerGroup",
        meta: { group: 8 },
        header: "vs. Veer",
        columns: [
          columnHelper.accessor("veer", {
            header: () => "#",
            cell: (info) => intFormat(info.getValue()),
            footer: () => overallRow.veer,
            meta: {
              group: 8,
              highlights: Highlights.Max,
              colorDomain: countColorDomains["veer"],
              neutralColorScheme: true,
            },
          }),
          columnHelper.accessor("veerPpp", {
            header: () => "PPP",
            cell: (info) => decFormat2(info.getValue()),
            footer: () => decFormat2(overallRow.veerPpp),
            meta: {
              group: 8,
              highlights: Highlights.Max,
              colorDomain: pppColorDomain,
            },
          }),
        ],
      }),
      columnHelper.group({
        id: "switchGroup",
        meta: { group: 9 },
        header: "vs. Switch",
        columns: [
          columnHelper.accessor("switch", {
            header: () => "#",
            cell: (info) => intFormat(info.getValue()),
            footer: () => overallRow.switch,
            meta: {
              group: 9,
              highlights: Highlights.Max,
              colorDomain: countColorDomains["switch"],
              neutralColorScheme: true,
            },
          }),
          columnHelper.accessor("switchPpp", {
            header: () => "PPP",
            cell: (info) => decFormat2(info.getValue()),
            footer: () => decFormat2(overallRow.switchPpp),
            meta: {
              group: 9,
              highlights: Highlights.Max,
              colorDomain: pppColorDomain,
            },
          }),
        ],
      }),
      columnHelper.group({
        id: "underGroup",
        meta: { group: 10 },
        header: "vs. Under",
        columns: [
          columnHelper.accessor("under", {
            header: () => "#",
            cell: (info) => intFormat(info.getValue()),
            footer: () => overallRow.under,
            meta: {
              group: 10,
              highlights: Highlights.Max,
              colorDomain: countColorDomains["under"],
              neutralColorScheme: true,
            },
          }),
          columnHelper.accessor("underPpp", {
            header: () => "PPP",
            cell: (info) => decFormat2(info.getValue()),
            footer: () => decFormat2(overallRow.underPpp),
            meta: {
              group: 10,
              highlights: Highlights.Max,
              colorDomain: pppColorDomain,
            },
          }),
        ],
      }),
      columnHelper.group({
        id: "upToTouchGroup",
        meta: { group: 11 },
        header: "vs. Up To Touch",
        columns: [
          columnHelper.accessor("upToTouch", {
            header: () => "#",
            cell: (info) => intFormat(info.getValue()),
            footer: () => overallRow.upToTouch,
            meta: {
              group: 11,
              highlights: Highlights.Max,
              colorDomain: countColorDomains["upToTouch"],
              neutralColorScheme: true,
            },
          }),
          columnHelper.accessor("upToTouchPpp", {
            header: () => "PPP",
            cell: (info) => decFormat2(info.getValue()),
            footer: () => decFormat2(overallRow.upToTouchPpp),
            meta: {
              group: 11,
              highlights: Highlights.Max,
              colorDomain: pppColorDomain,
            },
          }),
        ],
      }),
      columnHelper.group({
        id: "weakGroup",
        meta: { group: 12 },
        header: "vs. Weak",
        columns: [
          columnHelper.accessor("weak", {
            header: () => "#",
            cell: (info) => intFormat(info.getValue()),
            footer: () => overallRow.weak,
            meta: {
              group: 12,
              highlights: Highlights.Max,
              colorDomain: countColorDomains["weak"],
              neutralColorScheme: true,
            },
          }),
          columnHelper.accessor("weakPpp", {
            header: () => "PPP",
            cell: (info) => decFormat2(info.getValue()),
            footer: () => decFormat2(overallRow.weakPpp),
            meta: {
              group: 12,
              highlights: Highlights.Max,
              colorDomain: pppColorDomain,
            },
          }),
        ],
      }),
      columnHelper.group({
        id: "nonThreatGroup",
        meta: { group: 13 },
        header: "vs. Non-threat",
        columns: [
          columnHelper.accessor("nonThreat", {
            header: () => "#",
            cell: (info) => intFormat(info.getValue()),
            footer: () => overallRow.nonThreat,
            meta: {
              group: 13,
              highlights: Highlights.Max,
              colorDomain: countColorDomains["nonThreat"],
              neutralColorScheme: true,
            },
          }),
          columnHelper.accessor("nonThreatPpp", {
            header: () => "PPP",
            cell: (info) => decFormat2(info.getValue()),
            footer: () => decFormat2(overallRow.nonThreatPpp),
            meta: {
              group: 13,
              highlights: Highlights.Max,
              colorDomain: pppColorDomain,
            },
          }),
        ],
      }),
    ],
    [overallRow, countColorDomains]
  );
  return (
    <Table
      data={preparedData}
      columns={columns}
      sorting={sorting}
      setSorting={setSorting}
      showColorOnHover={true}
      autoWidth={true}
    />
  );
}

interface PnrBreakdownRow {
  type: string;
  overall: number;
  overallPpp: number | null;
  blitz: number | null;
  blitzPpp: number | null;
  contain: number | null;
  containPpp: number | null;
  ice: number | null;
  icePpp: number | null;
  reject: number | null;
  rejectPpp: number | null;
  show: number | null;
  showPpp: number | null;
  soft: number | null;
  softPpp: number | null;
  veer: number | null;
  veerPpp: number | null;
  switch: number | null;
  switchPpp: number | null;
  under: number | null;
  underPpp: number | null;
  upToTouch: number | null;
  upToTouchPpp: number | null;
  weak: number | null;
  weakPpp: number | null;
  nonThreat: number | null;
  nonThreatPpp: number | null;
}

const PnrTypeLabelMap: Record<string, string> = {
  dho: "DHO",
  handback: "Handback",
  middle: "Middle",
  stepUp: "Step Up",
  wing: "Wing",
};
