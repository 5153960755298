import React, { useMemo, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import moment from "moment";

import { TeamMultiLeagueSchedule } from "../components/team/TeamMultiLeagueSchedule";
import { Page } from "../components/core/Page";
import { Panel } from "../components/core/Panel";
import { VideoPlayer } from "../components/video/VideoPlayer";
import { Spinner } from "../components/core/Spinner";
import {
  GameMultiLeagueBoxscoreTeam,
  GameMultiLeagueBoxscorePlayer,
} from "../../shared/routers/GameRouter";
import { Game } from "../../shared/routers/GameRouter";
import { LeagueSeasonTeamSchedule } from "../../shared/routers/LeagueRouter";
import { dateFormatLong, minutesFormat, fractionFormat } from "../util/Format";
import {
  Table,
  SortingState,
  createColumnHelper,
} from "../components/core/Table";
import { Highlights } from "../constants/AppConstants";
import { sum } from "../util/Util";
import { getHoop1Urls } from "../util/VideoUtil";
import { gameClipToSynergyEditorClip } from "../components/video/utilities";
import { trpc } from "../util/tRPC";
import { PlayerTableCell, TeamTableCell } from "../components/core/TableCell";

export function GameMultiLeaguePage() {
  const { league, id } = useParams();

  const [hoop1Urls, setHoop1Urls] =
    useState<{ label: string; url: string }[]>();
  const [source, setSource] = useState("synergy");

  const { data: playerBoxes } =
    trpc.game.getGameMultiLeagueBoxscorePlayer.useQuery({
      gameId: id ? parseInt(id) : undefined,
      league,
    });

  const { data: synergyPos } = trpc.synergy.getSynergyGameClips.useQuery({
    gameIds: id ? [parseInt(id)] : [],
  });

  const { data: synergySegments } =
    trpc.synergy.getSynergyGameSegments.useQuery({
      gameIds: id ? [parseInt(id)] : [],
    });

  const { data: detailsRows } = trpc.game.getGameDetails.useQuery(
    { gameIds: id ? [parseInt(id)] : [] },
    {
      onSuccess: (data) => {
        const game = data[0];
        if (game) {
          setHoop1Urls(
            getHoop1Urls(game).urls.map((u, i) => {
              return { url: u, label: `Period ${i + 1}` };
            })
          );
        } else {
          setHoop1Urls(undefined);
        }
      },
    }
  );

  const details = detailsRows && detailsRows[0];

  const { data: teamBoxes } = trpc.game.getGameMultiLeagueBoxScoreTeam.useQuery(
    { gameId: id, league }
  );

  const { data: homeSchedule } =
    trpc.league.getLeagueSeasonTeamSchedule.useQuery({
      leagueKey: league,
      season: details ? details.season.toString() : undefined,
      teamId:
        details && details.homeTeamID
          ? details.homeTeamID.toString()
          : undefined,
    });

  const { data: awaySchedule } =
    trpc.league.getLeagueSeasonTeamSchedule.useQuery({
      leagueKey: league,
      season: details ? details.season.toString() : undefined,
      teamId:
        details && details.awayTeamID
          ? details.awayTeamID.toString()
          : undefined,
    });

  const clipTitle = details
    ? `${details.awayTeam} @ ${details.homeTeam}`
    : "Unknown";

  const isLoading = !synergyPos || !synergySegments || !hoop1Urls;

  const clips = useMemo(() => {
    if (!synergyPos || !synergySegments || !hoop1Urls) return [];
    if (source === "synergy") {
      return synergySegments.map((url) => {
        return { label: clipTitle, url: url.videoUrl };
      });
    } else if (source === "synergyByPoss") {
      return synergyPos.map(gameClipToSynergyEditorClip);
    } else {
      return hoop1Urls;
    }
  }, [synergyPos, synergySegments, hoop1Urls, source, clipTitle]);

  const sources = [
    { value: "synergy", label: "Synergy" },
    { value: "synergyByPoss", label: "Synergy (by possession)" },
    { value: "hoop1", label: "Hoop 1" },
  ];

  if (
    !id ||
    !league ||
    !details ||
    !teamBoxes ||
    !playerBoxes ||
    !homeSchedule ||
    !awaySchedule
  )
    return null;

  const schedules: Record<string, LeagueSeasonTeamSchedule[]> = {
    home: homeSchedule,
    away: awaySchedule,
  };

  const header = (
    <div>
      <div style={{ display: "flex", gap: 40 }}>
        {["away", "home"].map((homeAway) => {
          const team = teamBoxes.find((tb) => tb.homeAway === homeAway);
          if (!team) return null;
          const url = `/team/ml/${team.teamId}?league=${team.leagueKey}&season=${team.Season}`;

          return (
            <div key={team.teamId}>
              <h3 style={{ marginBottom: 0 }}>
                <Link to={url}>{team.TEAMLONG}</Link>
              </h3>
              <div style={{ fontSize: 40 }}>{team.pts}</div>
              <div
                style={{
                  color: "rgba(255,255,255,.5)",
                  textTransform: "uppercase",
                  fontSize: 12,
                }}
              >
                {homeAway}
              </div>
            </div>
          );
        })}
      </div>
      <div style={{ color: "rgba(255,255,255,.5)" }}>
        {dateFormatLong(new Date(details.gameDate))}
      </div>
    </div>
  );

  return (
    <Page header={{ component: header }} title="Game Multi League Page">
      <div>
        <Row>
          <Col>
            <Panel header="Video">
              {isLoading ? (
                <Spinner />
              ) : (
                <div
                  style={{
                    marginBottom: 8,
                  }}
                >
                  <div style={{ marginBottom: 8 }}>
                    <Form.Select
                      style={{ width: "auto", display: "inline" }}
                      onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => {
                        setSource(evt.target.value);
                      }}
                    >
                      {sources.map((s, i) => (
                        <option key={i} value={s.value}>
                          {s.label}
                        </option>
                      ))}
                    </Form.Select>
                  </div>

                  <VideoPlayer
                    title={`${details.awayTeam} @ ${details.homeTeam}`}
                    prefetchLength={source === "synergyByPoss" ? 5 : 1}
                    clips={clips}
                    upDownClipSkip={source === "synergyByPoss"}
                    showSynergyEditor={source === "synergyByPoss"}
                  />
                </div>
              )}
            </Panel>
          </Col>
        </Row>
        <Row>
          {["away", "home"].map((homeAway) => {
            const teamBox = teamBoxes.find(
              (d) => d.homeAway === homeAway
            ) as GameMultiLeagueBoxscoreTeam;

            if (!teamBox) return null;

            const team = teamBox.TEAMLONG;
            return (
              <Col key={homeAway}>
                <MultiLeagueBoxscorePanel
                  team={team}
                  data={playerBoxes.filter((p) => p.homeAway === homeAway)}
                />
              </Col>
            );
          })}
        </Row>
        <Row>
          {["away", "home"].map((homeAway) => {
            const teamBox = teamBoxes.find(
              (d) => d.homeAway === homeAway
            ) as GameMultiLeagueBoxscoreTeam;

            if (!teamBox) return null;
            const team = teamBox.TEAMLONG;

            return (
              <Col key={homeAway}>
                <Panel header={team}>
                  <TeamMultiLeagueSchedule
                    gameId={id}
                    data={schedules[homeAway] || []}
                  />
                </Panel>
              </Col>
            );
          })}
        </Row>
        <Row>
          <Col>
            <Panel
              header={`Other Games on ${dateFormatLong(
                new Date(details.gameDate)
              )}`}
            >
              <GamesOnDate
                league={league}
                gameDate={details.gameDate}
                gameId={id}
              />
            </Panel>
          </Col>
        </Row>
      </div>
    </Page>
  );
}

const columnHelper = createColumnHelper<GameMultiLeagueBoxscorePlayer>();

function MultiLeagueBoxscorePanel(props: {
  team: string;
  data: GameMultiLeagueBoxscorePlayer[];
}) {
  const { team, data } = props;
  const [sorting, setSorting] = useState<SortingState>();

  const columns = useMemo(
    () => [
      columnHelper.accessor("player", {
        header: () => "Player",
        cell: (info) => (
          <PlayerTableCell
            name={info.getValue()}
            id={info.row.original.playerId || undefined}
          />
        ),
        meta: { group: 0 },
      }),
      columnHelper.accessor("jerseyNumber", {
        header: () => "#",
        cell: (info) => info.getValue(),
        meta: { group: 1 },
      }),
      columnHelper.accessor("min", {
        header: () => "Min",
        cell: (info) => {
          if (info.row.original.playerMinutesExist) {
            return (
              <Link
                to={`/player-minutes/${info.row.original.gameId}/${info.row.original.playerId}`}
              >
                {minutesFormat(info.getValue())}
              </Link>
            );
          } else {
            return minutesFormat(info.getValue());
          }
        },
        footer: () => minutesFormat(sum("min", data)),
        meta: { highlights: Highlights.Max, group: 2 },
      }),
      columnHelper.accessor("PTS", {
        header: () => "PTS",
        cell: (info) => info.getValue(),
        footer: () => sum("PTS", data),
        meta: { highlights: Highlights.Max, group: 3 },
      }),
      columnHelper.accessor("rtot", {
        header: () => "REB",
        cell: (info) => info.getValue(),
        footer: () => sum("rtot", data),
        meta: { highlights: Highlights.Max, group: 3 },
      }),
      columnHelper.accessor("ast", {
        header: () => "AST",
        cell: (info) => info.getValue(),
        footer: () => sum("ast", data),
        meta: { highlights: Highlights.Max, group: 3 },
      }),
      columnHelper.accessor((row) => (row.fg2a ? row.fg2m / row.fg2a : ""), {
        id: "fg2",
        header: () => "2PM/A",
        cell: (info) =>
          fractionFormat({
            denominator: info.row.original["fg2a"],
            numerator: info.row.original["fg2m"],
          }),
        footer: () =>
          fractionFormat({
            denominator: sum("fg2a", data),
            numerator: sum("fg2m", data),
          }),
        meta: { highlights: Highlights.Max, group: 4 },
      }),
      columnHelper.accessor((row) => (row.fg3a ? row.fg3m / row.fg3a : ""), {
        id: "fg3",
        header: () => "3PM/A",
        cell: (info) =>
          fractionFormat({
            denominator: info.row.original["fg3a"],
            numerator: info.row.original["fg3m"],
          }),
        footer: () =>
          fractionFormat({
            denominator: sum("fg3a", data),
            numerator: sum("fg3m", data),
          }),
        meta: { highlights: Highlights.Max, group: 4 },
      }),
      columnHelper.accessor((row) => (row.fta ? row.ftm / row.fta : ""), {
        id: "ft",
        header: () => "FTM/A",
        cell: (info) =>
          fractionFormat({
            denominator: info.row.original["fta"],
            numerator: info.row.original["ftm"],
          }),
        footer: () =>
          fractionFormat({
            denominator: sum("fta", data),
            numerator: sum("ftm", data),
          }),
        meta: { highlights: Highlights.Max, group: 4 },
      }),
      columnHelper.accessor("roff", {
        header: () => "OR",
        cell: (info) => info.getValue(),
        footer: () => sum("roff", data),
        meta: { highlights: Highlights.Max, group: 5 },
      }),
      columnHelper.accessor("rdef", {
        header: () => "DR",
        cell: (info) => info.getValue(),
        footer: () => sum("rdef", data),
        meta: { highlights: Highlights.Max, group: 5 },
      }),
      columnHelper.accessor("blk", {
        header: () => "BLK",
        cell: (info) => info.getValue(),
        footer: () => sum("blk", data),
        meta: { highlights: Highlights.Max, group: 6 },
      }),
      columnHelper.accessor("stl", {
        header: () => "STL",
        cell: (info) => info.getValue(),
        footer: () => sum("stl", data),
        meta: { highlights: Highlights.Max, group: 6 },
      }),
      columnHelper.accessor("turn", {
        header: () => "TO",
        cell: (info) => info.getValue(),
        footer: () => sum("turn", data),
        meta: { highlights: Highlights.Min, group: 7 },
      }),
      columnHelper.accessor("pf", {
        header: () => "PF",
        cell: (info) => info.getValue(),
        footer: () => sum("pf", data),
        meta: { highlights: Highlights.Min, group: 7 },
      }),
    ],
    [data]
  );

  return (
    <Panel header={`${team} Box Scores`}>
      <Table
        data={data}
        columns={columns}
        sorting={sorting}
        setSorting={setSorting}
        autoWidth={true}
        showColorOnHover={true}
      />
    </Panel>
  );
}

const gamesOnDateColumnHelper = createColumnHelper<Game>();

function GamesOnDate(props: {
  league: string;
  gameDate: string;
  gameId: string;
}) {
  const { league, gameDate, gameId } = props;

  const { data: games } = trpc.game.getGamesOnDate.useQuery({
    league,
    date: moment(gameDate).format("YYYY-MM-DD"),
  });

  const columns = useMemo(
    () => [
      gamesOnDateColumnHelper.accessor("leagueName", {
        header: () => "League",
        cell: (info) => info.getValue(),
        meta: { textAlign: "left" },
      }),
      gamesOnDateColumnHelper.accessor("gameDate", {
        header: () => "Date",
        cell: (info) => moment(info.getValue()).format("MM/DD/YYYY"),
      }),
      gamesOnDateColumnHelper.accessor("awayTeam", {
        header: () => "Away",
        cell: (info) => (
          <TeamTableCell
            name={info.getValue()}
            ids={info.row.original.awayIDS || undefined}
            id={info.row.original.awayTeamID}
            season={info.row.original.season.toString()}
            league={info.row.original.leagueKey || undefined}
          />
        ),
      }),
      gamesOnDateColumnHelper.display({
        id: "result",
        header: () => "Result",
        cell: (info) => {
          const d = info.row.original;
          const away = d.awayPts > d.homePts ? <b>{d.awayPts}</b> : d.awayPts;
          const home = d.homePts > d.awayPts ? <b>{d.homePts}</b> : d.homePts;
          return (
            <Link
              title={"Go to game page"}
              to={`/game/ml/${d.leagueKey}/${d.alag}`}
            >
              {away} - {home}
            </Link>
          );
        },
      }),
      gamesOnDateColumnHelper.accessor("homeTeam", {
        header: () => "Home",
        cell: (info) => (
          <TeamTableCell
            name={info.getValue()}
            ids={info.row.original.homeIDS || undefined}
            id={info.row.original.homeTeamID}
            season={info.row.original.season.toString()}
            league={info.row.original.leagueKey || undefined}
          />
        ),
      }),
    ],
    []
  );

  if (!games) return null;

  const highlightIdx = games.findIndex((g) => g.alag.toString() === gameId);

  return (
    <Table
      data={games}
      columns={columns}
      autoWidth={true}
      showColorOnHover={true}
      rowColorMap={{ [highlightIdx]: { backgroundColor: "#ffffe0" } }}
      disableStickyColumn={true}
    />
  );
}
