import React from "react";
import { Link } from "react-router-dom";

import { TeamLogo } from "../../components/team/TeamLogo";
import {
  dateFormatLong,
  decFormat,
  decFormat2,
  timeFormat,
} from "../../util/Format";
import { useWindowSize } from "../../util/Hooks";

export function GamePageHeader(props: {
  gameId: string | undefined;
  homeTeamId: number;
  awayTeamId: number;
  homeAbbr: string;
  awayAbbr: string;
  ptsByQuarter: Record<
    number,
    {
      homePts: number;
      awayPts: number;
    }
  >;
  xPPPHome: number | null;
  xPPPAway: number | null;
  xPtsHome: number | null;
  xPtsAway: number | null;
  gameDateTimeStr: string | null;
  arenaname: string | null;
  location: string | null;
  lastUpdatedStr: string | undefined;
  homeTeamContent?: React.ReactNode;
  awayTeamContent?: React.ReactNode;
  seriesContent?: React.ReactNode;
}) {
  const {
    gameId,
    homeTeamId,
    awayTeamId,
    homeAbbr,
    awayAbbr,
    ptsByQuarter,
    xPPPHome,
    xPPPAway,
    xPtsAway,
    xPtsHome,
    gameDateTimeStr,
    arenaname,
    location,
    lastUpdatedStr,
    homeTeamContent,
    awayTeamContent,
    seriesContent,
  } = props;

  const { width } = useWindowSize();
  const isMobile = width < 768;

  const homePts = Object.values(ptsByQuarter).reduce(
    (a, b) => a + b.homePts,
    0
  );
  const awayPts = Object.values(ptsByQuarter).reduce(
    (a, b) => a + b.awayPts,
    0
  );

  // Playoff game's start with a 4.
  const isPlayoffs = gameId && gameId[0] === "4";
  // If its a playoff game the series id is the game id but the last digit is 0.
  const seriesId = gameId ? gameId.slice(0, -1) + "0" : undefined;

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          gap: isMobile ? 0 : 24,
        }}
      >
        <div
          style={{
            display: "inline-block",
            verticalAlign: "top",
          }}
        >
          <PageHeaderTeam
            isHome={false}
            teamId={awayTeamId}
            pts={awayPts}
            xPts={xPtsAway}
            xPPP={xPPPAway}
            teamContent={awayTeamContent || undefined}
          />
          <PageHeaderTeam
            isHome={true}
            teamId={homeTeamId}
            pts={homePts}
            xPts={xPtsHome}
            xPPP={xPPPHome}
            teamContent={homeTeamContent || undefined}
          />
        </div>
        <div style={{ display: "inline-block", verticalAlign: "top" }}>
          <GameQuarterPts
            homeAbbr={homeAbbr}
            awayAbbr={awayAbbr}
            homePts={homePts}
            awayPts={awayPts}
            ptsByQuarter={ptsByQuarter}
          />
        </div>
        <div style={{ display: "inline-block" }}>{seriesContent}</div>
      </div>
      {gameDateTimeStr && arenaname && location && (
        <div
          style={{
            display: "flex",
            gap: 8,
            fontSize: 13,
            margin: "0 -30px 8px",
            padding: "3px 15px",
            color: "rgba(255,255,255,.5)",
          }}
        >
          {`${dateFormatLong(new Date(gameDateTimeStr))} at ${timeFormat(
            new Date(gameDateTimeStr)
          )} in ${arenaname}, ${location}`}
          {isPlayoffs && (
            <>
              <span>|</span>
              <Link to={`/series/${seriesId}`}>See Full Series</Link>
            </>
          )}
        </div>
      )}
      {lastUpdatedStr && (
        <div
          style={{
            fontSize: 13,
            margin: "0 -30px 8px",
            padding: "3px 15px",
            color: "rgba(255,255,255,.5)",
          }}
        >
          {lastUpdatedStr}
        </div>
      )}
    </>
  );
}

function PageHeaderTeam(props: {
  isHome: boolean;
  teamId: number;
  pts: number;
  xPts: number | null;
  xPPP: number | null;
  teamContent?: React.ReactNode;
}) {
  const { isHome, pts, xPPP, xPts, teamId, teamContent } = props;
  const expected = (
    <div
      style={{
        fontSize: 20,
        gridColumnStart: 2,
        placeSelf: "center",
        color: "rgba(255,255,255,.8)",
        background: "rgba(255,255,255,.11)",
        padding: 8,
        marginTop: 10,
        borderRadius: 5,
      }}
    >
      <div>
        xPTS:{" "}
        <span style={{ color: "#fff", fontWeight: 600 }}>
          {decFormat(xPts) || "---"}
        </span>
      </div>
      <div>
        xPPP:{" "}
        <span style={{ color: "#fff", fontWeight: 600 }}>
          {decFormat2(xPPP) || "---"}{" "}
        </span>
      </div>
    </div>
  );

  return (
    <div
      style={{
        display: "inline-block",
        whiteSpace: "nowrap",
        padding: "15px 0",
        marginRight: 10,
      }}
    >
      {isHome ? null : (
        <Link to={`/team/${teamId}`}>
          <TeamLogo idsId={teamId} />
        </Link>
      )}
      <div style={{ display: "inline-block", marginLeft: 15 }}>
        <div
          style={{
            fontSize: 40,
            display: "grid",
            gridTemplateColumns: "auto auto auto",
          }}
        >
          {isHome ? (
            expected
          ) : (
            <div style={{ marginRight: "20px", placeSelf: "center" }}>
              {pts}
            </div>
          )}
          {isHome ? (
            <div style={{ marginLeft: "20px", placeSelf: "center" }}>{pts}</div>
          ) : (
            expected
          )}
        </div>
        <div
          style={{
            textAlign: isHome ? "right" : "left",
            color: "rgba(255,255,255,.5)",
            textTransform: "uppercase",
            fontSize: 12,
          }}
        >
          {teamContent || (isHome ? "Home" : "Away")}
        </div>
      </div>
      {isHome ? (
        <Link to={`/team/${teamId}`}>
          <TeamLogo idsId={teamId} />
        </Link>
      ) : null}
    </div>
  );
}

function GameQuarterPts(props: {
  homeAbbr: string;
  awayAbbr: string;
  homePts: number;
  awayPts: number;
  ptsByQuarter: Record<
    number,
    {
      homePts: number;
      awayPts: number;
    }
  >;
}) {
  const { homeAbbr, awayAbbr, homePts, awayPts, ptsByQuarter } = props;

  const headers = [],
    awayQuarters = [],
    homeQuarters = [];
  const numQuarters = Object.keys(ptsByQuarter).length;

  for (let i = 0; i < numQuarters; i++) {
    let quarterName;
    if (i > 3) {
      if (numQuarters === 5) {
        quarterName = "OT";
      } else {
        quarterName = `OT${i - 3}`;
      }
    } else {
      quarterName = i + 1;
    }

    const ptsAtCurQ = ptsByQuarter[i + 1];
    const awayPtsInQ = ptsAtCurQ ? ptsAtCurQ.awayPts : 0;
    const homePtsInQ = ptsAtCurQ ? ptsAtCurQ.homePts : 0;

    headers.push(
      <th key={i} style={{ padding: 7, verticalAlign: "top" }}>
        {quarterName}
      </th>
    );
    awayQuarters.push(
      <td key={i} style={{ padding: 7, verticalAlign: "top" }}>
        {awayPtsInQ === 0 && homePtsInQ === 0 ? "--" : awayPtsInQ}
      </td>
    );
    homeQuarters.push(
      <td key={i} style={{ padding: 7, verticalAlign: "top" }}>
        {awayPtsInQ === 0 && homePtsInQ === 0 ? "--" : homePtsInQ}
      </td>
    );
  }

  return (
    <div
      style={{
        display: "inline-block",
        color: "rgba(255,255,255,.8) !important",
        textAlign: "right",
        paddingTop: 10,
      }}
    >
      <table>
        <thead>
          <tr>
            <th />
            {headers}
            <th style={{ padding: 7, verticalAlign: "top" }}>T</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ padding: 7, verticalAlign: "top", fontWeight: 600 }}>
              {awayAbbr}
            </td>
            {awayQuarters}
            <td style={{ padding: 7, verticalAlign: "top", fontWeight: 600 }}>
              {awayPts === 0 && homePts === 0 ? "--" : awayPts}
            </td>
          </tr>
          <tr>
            <td style={{ padding: 7, verticalAlign: "top", fontWeight: 600 }}>
              {homeAbbr}
            </td>
            {homeQuarters}
            <td style={{ padding: 7, verticalAlign: "top", fontWeight: 600 }}>
              {homePts === 0 && awayPts === 0 ? "--" : homePts}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
