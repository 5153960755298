import React, { useState } from "react";
import { Form } from "react-bootstrap";

import {
  SecondSpectrumChance,
  SecondSpectrumChancePlayer,
  SecondSpectrumRebound,
  SecondSpectrumShot,
} from "../../../shared/routers/LiveGameRouter";
import { CrashAttempt } from "../../../shared/routers/GameRouter";
import { CrashingViz } from "../crashing/CrashingViz";
import { CrashingTable } from "../crashing/CrashingTable";

export function LiveGameCrashingViz(props: {
  teams: { teamId: number; teamName: string }[];
  players: {
    playerId: number;
    playerName: string;
    eagleId: string;
    teamId: number;
    homeAway: string;
    ftPct: number;
  }[];
  chances: Map<string, SecondSpectrumChance>;
  rebounds: Map<string, SecondSpectrumRebound>;
  chancePlayers: SecondSpectrumChancePlayer[];
  shots: Map<string, SecondSpectrumShot>;
}) {
  const { teams, players, rebounds, chancePlayers, chances, shots } = props;
  const firstTeam = teams[0];
  const [teamId, setTeamId] = useState<string>(
    firstTeam ? firstTeam.teamId.toString() : ""
  );
  const [playerId, setPlayerId] = useState<string>("");
  const [period, setPeriod] = useState(0);
  const [location, setLocation] = useState("All Locations");
  const [noLayups, setNoLayups] = useState(true);

  const playerMap = players.reduce((prev, cur) => {
    prev[cur.eagleId] = {
      name: cur.playerName,
      playerId: cur.eagleId,
      teamId: cur.teamId,
    };
    return prev;
  }, {} as Record<string, { name: string; playerId: string; teamId: number }>);

  const crashAttempts = createCrashAttempts(
    playerMap,
    chancePlayers,
    rebounds,
    shots,
    chances
  );

  const playerOptions = players.filter((p) => p.teamId.toString() === teamId);

  const filteredcrashAttempts = crashAttempts.filter((c) => {
    if (playerId !== "" && c.playerId.toString() !== playerId) return false;
    if (noLayups && c.shotType === "layup") return false;
    if (teamId !== "" && c.teamId.toString() !== teamId) return false;
    if (location !== "All Locations" && c.location !== location) return false;
    if (period === 0) return true;
    if (period === 5) return c.period > 4;
    return c.period === period;
  });

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          flexWrap: "wrap",
          gap: 8,
        }}
      >
        <select
          value={teamId}
          onChange={(evt) => {
            const newTeamId = evt.target.value;
            setTeamId(newTeamId);
            // Reset the player as well so we don't have a team/player mismatch.
            setPlayerId("");
          }}
        >
          {teams.map((t) => (
            <option value={t.teamId.toString()} key={t.teamId.toString()}>
              {t.teamName}
            </option>
          ))}
        </select>
        <select
          value={playerId}
          onChange={(e) => setPlayerId(e.currentTarget.value)}
        >
          <option value="">All Players</option>
          {playerOptions.map((p) => (
            <option key={p.eagleId} value={p.eagleId}>
              {p.playerName}
            </option>
          ))}
        </select>
        <select
          value={period}
          onChange={(evt) => setPeriod(parseInt(evt.target.value))}
        >
          {[
            { value: 0, label: "Full Game" },
            { value: 1, label: "1" },
            { value: 2, label: "2" },
            { value: 3, label: "3" },
            { value: 4, label: "4" },
            { value: 5, label: "OT" },
          ].map((po) => (
            <option value={po.value} key={po.value}>
              {po.label}
            </option>
          ))}
        </select>
        <select
          value={location}
          onChange={(evt) => setLocation(evt.target.value)}
        >
          {["All Locations", "Corner", "Inside the Arc", "Above the Break"].map(
            (l) => (
              <option value={l} key={l}>
                {l}
              </option>
            )
          )}
        </select>
        <Form.Check
          type="checkbox"
          checked={noLayups}
          label="Hide Layups"
          onChange={() => setNoLayups(!noLayups)}
        />
      </div>
      <CrashingViz
        data={filteredcrashAttempts}
        disableHover={playerId === ""}
      />
      <CrashingTable data={filteredcrashAttempts} />
    </div>
  );
}

function createCrashAttempts(
  playerMap: Record<string, { name: string; playerId: string; teamId: number }>,
  chancePlayers: SecondSpectrumChancePlayer[],
  rebounds: Map<string, SecondSpectrumRebound>,
  shots: Map<string, SecondSpectrumShot>,
  chances: Map<string, SecondSpectrumChance>
): CrashAttempt[] {
  return chancePlayers
    .filter((cp) => {
      const shot = shots.get(cp.chanceId);
      const chance = chances.get(cp.chanceId);

      if (!shot || !chance) return false;

      return (
        cp.crashed !== null &&
        shot.blocked === false &&
        !(
          chance.startType === "FGORB" &&
          chance.startGameClock - chance.endGameClock < 4
        )
      );
    })
    .map((cp) => {
      const shot = shots.get(cp.chanceId);
      const rebound = rebounds.get(cp.chanceId);
      const chance = chances.get(cp.chanceId);

      if (shot === undefined || chance === undefined) {
        return null;
      }

      const endX =
        (cp.reboundLoc && cp.reboundLoc[0]) || (cp.rimLoc && cp.rimLoc[0]) || 0;
      const startX = (cp.shotLoc && cp.shotLoc[0]) || 0;

      const shotLoc0 = (cp.shotLoc && cp.shotLoc[0]) || 0;
      const shotLoc1 = (cp.shotLoc && cp.shotLoc[1]) || 0;

      let action = "Stuck";
      if (cp.crashed) {
        action = "Crashed";
      } else if (
        (cp.crashed === false &&
          startX !== null &&
          endX !== null &&
          endX - startX >= 3) ||
        cp.shotLoc === null ||
        shotLoc0 >= 0
      ) {
        action = "Got Back";
      } else if (cp.crashed === false && shot.shooterId === cp.playerId) {
        action = "Non-crashing Shooter";
      }

      let location = null;
      if (cp.shotLoc && Math.abs(shotLoc1) > 21 && shotLoc0 < -37) {
        location = "Corner";
      } else if (
        cp.shotLoc &&
        Math.sqrt(
          Math.abs(shotLoc1) * Math.abs(shotLoc1) +
            Math.abs(shotLoc0 + 41.75) * Math.abs(shotLoc0 + 41.75)
        ) < 23.75
      ) {
        location = "Inside the Arc";
      } else if (cp.shotLoc) {
        location = "Above the Break";
      }

      const player = playerMap[cp.playerId];

      return {
        id: cp.chanceId + "-" + cp.playerId,
        playerId: player ? player.playerId : 0,
        player: player ? player.name : "Unknown",
        action: action,
        location: location,
        shotLocX: cp.shotLoc ? cp.shotLoc[0] : 0,
        shotLocY: cp.shotLoc ? cp.shotLoc[1] : 0,
        rimLocX: cp.rimLoc ? cp.rimLoc[0] : 0,
        rimLocY: cp.rimLoc ? cp.rimLoc[1] : 0,
        reboundLocX: cp.reboundLoc ? cp.reboundLoc[0] : null,
        reboundLocY: cp.reboundLoc ? cp.reboundLoc[1] : null,
        reboundBallLocX:
          rebound && rebound.location ? rebound.location[0] : null,
        reboundBallLocY:
          rebound && rebound.location ? rebound.location[1] : null,
        url: null,
        teamId: player ? player.teamId : 0,
        period: chance.period,
        gameClock: chance.endGameClock,
        isShooter: shot.shooterId === cp.playerId,
        rbPctRim: cp.rbPctRim,
        rbPctShot: cp.rbPctShot,
        isRebounder: rebound && rebound.rebounderId === cp.playerId,
        shotType: shot.shotType,
      };
    })
    .filter((x) => x !== null) as CrashAttempt[];
}
