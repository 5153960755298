import {
  ShotModelCoeffecient,
  SecondSpectrumChance,
  SecondSpectrumChancePlayer,
  SecondSpectrumShot,
} from "../../shared/routers/LiveGameRouter";

/** Run the shot model on demand and get the xPts for a given shot. */
export function xPtsFromShot(
  shot: SecondSpectrumShot,
  chance: SecondSpectrumChance,
  defChancePlayers: SecondSpectrumChancePlayer[],
  defenderPlayerMakeEffects: { def_sr: number | null; intercept: number },
  defenderPlayerFoulEffects: { def_sr: number | null; intercept: number },
  shooterMakeIntercept: number,
  shooterFoulIntercept: number,
  shotModelCoefficients: ShotModelCoeffecient[],
  shooterFtPct: number,
  shotTypeShotCount: number,
  enableFoulblending: boolean
) {
  let lateClock = 0;
  if (shot.shotClock !== null && shot.shotClock <= 6) {
    lateClock = 6 - shot.shotClock;
  }

  const d1Proximity =
    1 - 1 / (1 + (Math.exp((-(shot.closestDefDist - 3) * 2) / 3) * 4) / 9);
  const locationX = (shot.location[1] || 0) * -1;
  const locationY = (shot.location[0] || 0) + 47 - 5.25;

  const behindBackboard = pBehindBackboard(locationX, locationY);

  const proximitySum = defChancePlayers.reduce(
    (a, b) =>
      a +
      (1 -
        1 /
          (1 +
            (Math.exp((-((b.distanceToShooter || 0) - 3) * 2) / 3) * 4) / 9)),
    0
  );

  // Truncate velocity magnitude to 25.
  const shooterSpeed = Math.min(shot.shooterSpeed, 25);

  const towardBasketDegree =
    1 - 1 / (1 + Math.exp(-((Math.abs(shot.shooterVelAngle) - 45) / 9)));

  const atRimTransform = 1 - 1 / (1 + Math.exp(-(shot.distance - 4)));
  const d1DistTransform = 1 - 1 / (1 + Math.exp(-(shot.closestDefDist - 4)));
  const openLayupDegree = atRimTransform / (d1DistTransform + 1);
  const extraDefenders = shot.contesterIds.length;
  const velocityTransform = 1 / (1 + Math.exp(-(shot.shooterSpeed - 3)) * 9);

  const transHeave = shot.complexShotType === "heave" && chance.transition;

  const shooterVelTowardRim = shooterVelTowardRimMeans[shot.complexShotType];
  const nDefendersToRim = getNDefendersToRim(shot, defChancePlayers);
  const closestTimeToShooter = closestTimeToShooterMeans[shot.complexShotType];

  const foulDrawn = shot.fouled ? 1 : 0;

  const contestLevel = shot.contestLevel;

  const complexShotType = simplifyComplexShotType(shot.complexShotType);

  const isShot = isJumper(shot);

  // Shot model has 4 models, p(makeShot), p(makeFinish), p(foulShot),
  // p(foulFinish). Depending on whether the shot is a "shot" or a "finish"
  // we use the two corresponding models and and combine the probabilities
  // of a make and of a foul to get the expected value of the shot.
  const probMake = isShot
    ? probMakeShot(
        lateClock,
        d1Proximity,
        foulDrawn,
        complexShotType,
        behindBackboard,
        contestLevel,
        proximitySum,
        shooterSpeed,
        towardBasketDegree,
        shot.distance,
        shotTypeShotCount,
        defenderPlayerMakeEffects.intercept,
        shooterMakeIntercept,
        shotModelCoefficients.filter(
          (c) => c.outcome === "make" && c.type === "shot"
        )
      )
    : probMakeFinish(
        lateClock,
        nDefendersToRim,
        shooterSpeed,
        towardBasketDegree,
        complexShotType,
        openLayupDegree,
        defenderPlayerMakeEffects.def_sr || 0,
        d1Proximity,
        behindBackboard,
        extraDefenders,
        proximitySum,
        foulDrawn,
        shot.distance,
        chance.transition ? 1 : 0,
        shotTypeShotCount,
        defenderPlayerMakeEffects.intercept,
        shooterMakeIntercept,
        shotModelCoefficients.filter(
          (c) => c.outcome === "make" && c.type === "finish"
        )
      );

  const probFoul = isShot
    ? probFoulShot(
        lateClock,
        proximitySum,
        extraDefenders,
        contestLevel,
        shot.three ? 1 : 0,
        velocityTransform,
        defenderPlayerFoulEffects.def_sr || 0,
        transHeave ? 1 : 0,
        chance.transition ? 1 : 0,
        shot.outcome ? 1 : 0,
        complexShotType,
        shooterVelTowardRim || 0,
        d1Proximity,
        nDefendersToRim,
        shotTypeShotCount,
        defenderPlayerFoulEffects.intercept,
        shooterFoulIntercept,
        shotModelCoefficients.filter(
          (c) => c.outcome === "foul" && c.type === "shot"
        )
      )
    : probFoulFinish(
        shot.outcome ? 1 : 0,
        complexShotType,
        lateClock,
        extraDefenders,
        contestLevel,
        closestTimeToShooter || 0,
        shot.distance,
        chance.transition ? 1 : 0,
        d1Proximity,
        velocityTransform,
        shooterVelTowardRim || 0,
        nDefendersToRim,
        shotTypeShotCount,
        defenderPlayerFoulEffects.intercept,
        shooterFoulIntercept,
        shotModelCoefficients.filter(
          (c) => c.outcome === "foul" && c.type === "finish"
        )
      );

  const ftPct = shooterFtPct;
  const fgValue = shot.three ? 3 : 2;
  let xPts =
    fgValue * probMake +
    probFoul * ftPct * (probMake + (1 - probMake) * fgValue);
  if (enableFoulblending) {
    // Take the actual xPTS value and then blend it with an xPTS calc knowing
    // that probFoul is 1.
    xPts =
      (xPts +
        (fgValue * probMake +
          (shot.fouled ? 1 : 0) *
            ftPct *
            (probMake + (1 - probMake) * fgValue))) /
      2;
  }
  return xPts;
}

function probFoulFinish(
  outcome: number,
  complexShotType: string,
  lateClock: number,
  extraDefenders: number,
  contestLevel: string,
  closest_time_to_shooter: number,
  distance: number,
  isTransition: number,
  d1Proximity: number,
  velocityTransform: number,
  shooterVelTowardRim: number,
  nDefendersToRim: number,
  shotTypeShotCount: number,
  defenderPlayerEffect: number,
  shooterPlayerEffect: number,
  shotModelCoefficients: ShotModelCoeffecient[]
) {
  const getCoeff = (variable: string) => {
    const coeff = shotModelCoefficients.find((c) => c.variable === variable);
    return coeff ? coeff.coefficients : 0;
  };
  const unscaledVal =
    outcome * getCoeff("outcome") +
    getCoeff("pnorm(season - 2016)") +
    outcome * getCoeff("outcome:pnorm(season - 2016)") +
    Math.log(shotTypeShotCount) * getCoeff("log(cumul_cst_count)") +
    Math.log(shotTypeShotCount) *
      getCoeff(`complexShotType${complexShotType}:log(cumul_cst_count)`) +
    lateClock * getCoeff("lateClock") +
    getCoeff(`factor(extraDefenders)${extraDefenders}`) +
    getCoeff(`contestLevel${contestLevel}`) +
    closest_time_to_shooter * getCoeff("closest_time_to_shooter") +
    (1 / (distance + 1)) * getCoeff(`I(1/(Distance + 1))`) +
    getCoeff(`complexShotType${complexShotType}`) +
    (nDefendersToRim === 0 ? 1 : 0) *
      getCoeff("I(n_defenders_to_rim == 0)TRUE") +
    (nDefendersToRim === 0 ? 1 : 0) *
      getCoeff(
        `complexShotType${complexShotType}:I(n_defenders_to_rim == 0)TRUE`
      ) +
    isTransition * getCoeff("isTransitionTRUE") +
    outcome * isTransition * getCoeff("outcome:isTransitionTRUE") +
    Math.sqrt(distance) * getCoeff(`sqrt(Distance)`) +
    Math.sqrt(distance) *
      getCoeff(`complexShotType${complexShotType}:sqrt(Distance)`) +
    d1Proximity * getCoeff("d1Proximity") +
    d1Proximity * getCoeff(`complexShotType${complexShotType}:d1Proximity`) +
    velocityTransform * getCoeff("velocityTransform") +
    shooterVelTowardRim * getCoeff("shooterVelTowardRim") +
    shooterVelTowardRim *
      getCoeff(`complexShotType${complexShotType}:shooterVelTowardRim`) +
    defenderPlayerEffect +
    shooterPlayerEffect -
    getCoeff("(Intercept)") * 5;
  return 1 / (1 + Math.exp(-unscaledVal));
}

function probFoulShot(
  lateClock: number,
  proximitySum: number,
  extraDefenders: number,
  contestLevel: string,
  three: number,
  velocityTransform: number,
  def_sr: number,
  transHeave: number,
  isTransition: number,
  outcome: number,
  complexShotType: string,
  shooterVelTowardRim: number,
  d1Proximity: number,
  nDefendersToRim: number,
  shotTypeShotCount: number,
  defenderPlayerEffect: number,
  shooterPlayerEffect: number,
  shotModelCoefficients: ShotModelCoeffecient[]
) {
  const getCoeff = (variable: string) => {
    const coeff = shotModelCoefficients.find((c) => c.variable === variable);
    return coeff ? coeff.coefficients : 0;
  };

  const unscaledVal =
    getCoeff(`complexShotType${complexShotType}`) +
    outcome * getCoeff("outcome") +
    getCoeff("pnorm(season - 2016)") +
    outcome * getCoeff("outcome:pnorm(season - 2016)") +
    Math.log(shotTypeShotCount) * getCoeff("log(cumul_cst_count)") +
    lateClock * getCoeff("lateClock") +
    proximitySum * getCoeff("proximity_sum") +
    getCoeff(`proximity_sum:complexShotType${complexShotType}`) * proximitySum +
    getCoeff(`factor(extraDefenders)${extraDefenders}`) +
    getCoeff(`contestLevel${contestLevel}`) +
    getCoeff(`threeTRUE`) * three +
    velocityTransform * getCoeff("velocityTransform") +
    (nDefendersToRim === 0 ? 1 : 0) *
      getCoeff(`I(n_defenders_to_rim == 0)TRUE`) +
    def_sr * getCoeff("def_sr") +
    transHeave * getCoeff("trans_heaveTRUE") +
    isTransition * getCoeff("isTransitionTRUE") +
    outcome * isTransition * getCoeff("outcome:isTransitionTRUE") +
    shooterVelTowardRim * getCoeff("shooterVelTowardRim") +
    shooterVelTowardRim *
      getCoeff(`complexShotType${complexShotType}:shooterVelTowardRim`) +
    d1Proximity * getCoeff("d1Proximity") +
    getCoeff(`complexShotType${complexShotType}:d1Proximity`) * d1Proximity +
    defenderPlayerEffect +
    shooterPlayerEffect -
    getCoeff("(Intercept)") * 4;

  return 1 / (1 + Math.exp(-unscaledVal));
}

function probMakeFinish(
  lateClock: number,
  nDefendersToRim: number,
  shooterSpeed: number,
  towardBasketDegree: number,
  complexShotType: string,
  openLayupDegree: number,
  def_sr: number,
  d1Proximity: number,
  behindBackboard: number,
  extraDefenders: number,
  proximitySum: number,
  foulDrawn: number,
  distance: number,
  isTransition: number,
  shotTypeShotCount: number,
  defenderPlayerEffect: number,
  shooterPlayerEffect: number,
  shotModelCoefficients: ShotModelCoeffecient[]
) {
  const getCoeff = (variable: string) => {
    const coeff = shotModelCoefficients.find((c) => c.variable === variable);
    return coeff ? coeff.coefficients : 0;
  };

  const unscaledVal =
    getCoeff("pnorm(season - 2016)") +
    foulDrawn * getCoeff("foulDrawn:pnorm(season - 2016)") +
    Math.log(shotTypeShotCount) * getCoeff("log(cumul_cst_count)") +
    lateClock * getCoeff("lateClock") +
    shooterSpeed *
      towardBasketDegree *
      (nDefendersToRim === 0 ? 1 : 0) *
      getCoeff(
        "I((n_defenders_to_rim == 0) * (shooterSpeed * towardBasketDegree))"
      ) +
    (nDefendersToRim === 0 ? 1 : 0) *
      getCoeff(`I(n_defenders_to_rim == 0)TRUE`) +
    getCoeff(
      `complexShotType${complexShotType}:I(n_defenders_to_rim == 0)TRUE`
    ) *
      (nDefendersToRim === 0 ? 1 : 0) +
    Math.sqrt(nDefendersToRim) * getCoeff("sqrt(n_defenders_to_rim)") +
    openLayupDegree * getCoeff("openLayupDegree") +
    def_sr * getCoeff("def_sr") +
    d1Proximity * getCoeff("d1Proximity") +
    def_sr * d1Proximity * getCoeff("def_sr:d1Proximity") +
    behindBackboard * getCoeff("behindBackboard") +
    getCoeff(`factor(extraDefenders)${extraDefenders}`) +
    d1Proximity * getCoeff(`complexShotType${complexShotType}:d1Proximity`) +
    proximitySum * getCoeff("proximity_sum") +
    proximitySum * getCoeff(`complexShotType${complexShotType}:proximity_sum`) +
    shooterSpeed * getCoeff("shooterSpeed") +
    towardBasketDegree * getCoeff("towardBasketDegree") +
    shooterSpeed *
      towardBasketDegree *
      getCoeff("shooterSpeed:towardBasketDegree") +
    foulDrawn * getCoeff("foulDrawn") +
    foulDrawn * getCoeff(`foulDrawn:complexShotType${complexShotType}`) +
    getCoeff(`complexShotType${complexShotType}`) +
    distance * getCoeff("Distance") +
    distance * getCoeff(`complexShotType${complexShotType}:Distance`) +
    isTransition * getCoeff("isTransitionTRUE") +
    defenderPlayerEffect +
    shooterPlayerEffect -
    getCoeff("(Intercept)") * 5;
  return 1 / (1 + Math.exp(-unscaledVal));
}

function probMakeShot(
  lateClock: number,
  d1Proximity: number,
  foulDrawn: number,
  complexShotType: string,
  behindBackboard: number,
  contestLevel: string,
  proximitySum: number,
  shooterSpeed: number,
  towardBasketDegree: number,
  distance: number,
  shotCount: number,
  defenderPlayerEffect: number,
  shooterPlayerEffect: number,
  shotModelCoefficients: ShotModelCoeffecient[]
) {
  const getCoeff = (variable: string) => {
    const coeff = shotModelCoefficients.find((c) => c.variable === variable);
    return coeff ? coeff.coefficients : 0;
  };

  const unscaledVal =
    getCoeff("pnorm(season - 2016)") +
    lateClock * getCoeff("lateClock") +
    d1Proximity * getCoeff("d1Proximity") +
    lateClock * d1Proximity * getCoeff("lateClock:d1Proximity") +
    Math.log(shotCount) * getCoeff("log(cumul_cst_count)") +
    foulDrawn * getCoeff("foulDrawn") +
    getCoeff(`complexShotType${complexShotType}`) +
    getCoeff(`foulDrawn:complexShotType${complexShotType}`) * foulDrawn +
    behindBackboard * getCoeff("behindBackboard") +
    getCoeff(`factor(contestLevel)${contestLevel}`) +
    getCoeff(`d1Proximity:complexShotType${complexShotType}`) * d1Proximity +
    proximitySum * getCoeff("proximity_sum") +
    shooterSpeed * getCoeff("shooterSpeed") +
    towardBasketDegree * getCoeff("towardBasketDegree") +
    shooterSpeed *
      towardBasketDegree *
      getCoeff("shooterSpeed:towardBasketDegree") +
    distance * getCoeff("Distance") +
    getCoeff(`complexShotType${complexShotType}:Distance`) * distance +
    defenderPlayerEffect +
    shooterPlayerEffect -
    getCoeff("(Intercept)") * 4;

  return 1 / (1 + Math.exp(-unscaledVal));
}

const shooterVelTowardRimMeans: Record<string, number> = {
  catchAndShoot: 0.349,
  catchAndShootOnMoveLeft: 0.361,
  catchAndShootOnMoveRight: 0.602,
  catchAndShootRelocating: 0.221,
  cutFloater: 1.83,
  cutLayup: -0.513,
  drivingFloater: 0.462,
  drivingLayup: -2.31,
  heave: 3.26,
  lob: -1.95,
  overScreen: 0.845,
  postLeft: -1.47,
  postRight: -2.46,
  pullupJumper: -0.293,
  shakeAndRaise: -0.014,
  standstillLayup: 0.132,
  stepback: -1.9,
  tip: -0.226,
};

const closestTimeToShooterMeans: Record<string, number> = {
  catchAndShoot: 0.498,
  catchAndShootOnMoveLeft: 0.432,
  catchAndShootOnMoveRight: 0.431,
  catchAndShootRelocating: 0.483,
  cutFloater: 0.449,
  cutLayup: 0.399,
  drivingFloater: 0.461,
  drivingLayup: 0.399,
  heave: 0.644,
  lob: 0.395,
  overScreen: 0.478,
  postLeft: 0.375,
  postRight: 0.377,
  pullupJumper: 0.48,
  shakeAndRaise: 0.478,
  standstillLayup: 0.373,
  stepback: 0.434,
  tip: 0.361,
};

export function simplifyComplexShotType(complexShotType: string) {
  if (complexShotType.endsWith("Left")) {
    return complexShotType.slice(0, -4);
  } else if (complexShotType.endsWith("Right")) {
    return complexShotType.slice(0, -5);
  } else if (complexShotType.endsWith("Relocating")) {
    return complexShotType.slice(0, -10);
  }
  if (complexShotType.includes("Floater")) {
    return "floater";
  }
  return complexShotType;
}

function getNDefendersToRim(
  shot: SecondSpectrumShot,
  defenders: SecondSpectrumChancePlayer[]
) {
  const closer = defenders.filter((d) => {
    if (d.shotLoc === null) return false;
    // -41.75 is the x coord of the rim.
    const rimX = -41.75;
    const rimY = 0;
    const shotX = shot.location[0] || 0;
    const shotY = shot.location[1] || 0;
    const defX = d.shotLoc[0] || 0;
    const defY = d.shotLoc[1] || 0;

    const srMidX = (rimX + shotX) / 2;
    const srMidY = (rimY + shotY) / 2;
    const srDist = Math.sqrt(
      Math.pow(rimX - shotX, 2) + Math.pow(rimY - shotY, 2)
    );
    const mdDist = Math.sqrt(
      Math.pow(srMidX - defX, 2) + Math.pow(srMidY - defY, 2)
    );
    return mdDist < srDist / 2;
  });

  return closer.length;
}

// We have separate models for "jumpers" vs "finishes". This function determines
// if the shot we are looking at should be modeled as a jumper.
export function isJumper(shot: {
  shotType: string;
  complexShotType: string;
  three: boolean;
}) {
  return (
    (shot.shotType === "jumper" || shot.shotType === "heave") &&
    !(shot.complexShotType == "shakeAndRaise" && shot.three === false)
  );
}

export function pBehindBackboard(locationX: number, locationY: number) {
  let angle = Math.atan(locationX / locationY) * (180 / Math.PI) + 90;

  if (locationY < 0) {
    angle = angle + 180;
  }

  if (angle > 270) {
    angle = angle - 360;
  }

  angle = angle + 90;

  const absAngle = Math.abs(angle - 180);
  return 1 - 1 / (1 + Math.exp((absAngle - 105) / 2));
}
