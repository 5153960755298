import moment from "moment";

import { NbaDates } from "./NbaDates";

const currentDates = NbaDates["2025"];

const draftDates = {
  // Occasionally update this list in the future.
  2008: "2008-06-26",
  2009: "2009-06-25",
  2010: "2010-06-24",
  2011: "2011-06-23",
  2012: "2012-06-28",
  2013: "2013-06-27",
  2014: "2014-06-26",
  2015: "2015-06-25",
  2016: "2016-06-23",
  2017: "2017-06-22",
  2018: "2018-06-21",
  2019: "2019-06-27",
  2020: "2020-11-18",
  2021: "2021-07-29",
  2022: "2022-06-23",
  2023: "2023-06-22",
  2024: "2024-06-27",
  2025: "2025-06-26",
  2026: "2026-06-25",
  2027: "2027-06-24",
  2028: "2028-06-22",
  2029: "2029-06-21",
  2030: "2030-06-27",
} as Record<string, string>;

// Find the next draft year. When a draft ends this should flip to be the next
// upcoming draft.
const latestDraftYear = parseInt(
  Object.entries(draftDates)
    .filter((kv) => moment(kv[1]).valueOf() > Date.now())
    .sort(([, val1], [, val2]) => parseInt(val1) - parseInt(val2))[0]![0]
);

/**
 * For variables that are shared across modules, that may change from time to
 * time.
 */
export default {
  // Consider "in preseason" until regular season actually starts.
  inPreseason: currentDates
    ? Date.now() >= currentDates.preseason.start &&
      Date.now() <= currentDates.season.start
    : false,
  // Change when preseason starts.
  currentSeason: "2025",
  latestDraftYear,
  oldestClipsSeason: 2005,
  secondSpectrumSeasons: [
    { value: 2014, label: "2013-14" },
    { value: 2015, label: "2014-15" },
    { value: 2016, label: "2015-16" },
    { value: 2017, label: "2016-17" },
    { value: 2018, label: "2017-18" },
    { value: 2019, label: "2018-19" },
    { value: 2020, label: "2019-20" },
    { value: 2021, label: "2020-21" },
    { value: 2022, label: "2021-22" },
    { value: 2023, label: "2022-23" },
    // Update after preseason.
    { value: 2024, label: "2023-24" },
  ],
  seasons: [
    { value: 2014, label: "2013-14" },
    { value: 2015, label: "2014-15" },
    { value: 2016, label: "2015-16" },
    { value: 2017, label: "2016-17" },
    { value: 2018, label: "2017-18" },
    { value: 2019, label: "2018-19" },
    { value: 2020, label: "2019-20" },
    { value: 2021, label: "2020-21" },
    { value: 2022, label: "2021-22" },
    { value: 2023, label: "2022-23" },
    { value: 2024, label: "2023-24" },
    // Update at start of preseason.
    { value: 2025, label: "2024-25" },
  ],
  draftDates,
};
