import AppContext from "../../shared/AppContext";

export const CELTICS_TEAM_ID = 1610612738;

export const DataTypes = {
  Number: "Number",
  NumberOrdinal: "NumberOrdinal",
  String: "String",
  Boolean: "Boolean",
  Date: "Date",
  None: "None",
};

export const Highlights = {
  Max: "Max",
  Min: "Min",
  Last: "Last", // Highlight last visible row in an expandable row.
};

export type Position = "PG" | "SG" | "SF" | "PF" | "C";

export const Positions: Record<number, Position> = {
  1: "PG",
  2: "SG",
  3: "SF",
  4: "PF",
  5: "C",
};

export const Periods: Record<number, string> = {
  1: "1",
  2: "2",
  3: "3",
  4: "4",
  5: "OT1",
  6: "OT2",
  7: "OT3",
  8: "OT4",
};

export const SortDirections = {
  Ascending: true,
  Descending: false,
};

export const statLabelMap = {
  ppg: { longLabel: "Points Per Game", shortLabel: "PPG" },
  apg: { longLabel: "Assists Per Game", shortLabel: "APG" },
  rpg: { longLabel: "Rebounds Per Game", shortLabel: "RPG" },
  spg: { longLabel: "Steals Per Game", shortLabel: "SPG" },
  bpg: { longLabel: "Blocks Per Game", shortLabel: "BPG" },
  pf48: { longLabel: "Personal Fouls Per 48 Minutes", shortLabel: "PF/48" },
  roffpct: { longLabel: "Offensive Rebound %", shortLabel: "OR%" },
  rdefpct: { longLabel: "Defensive Rebound %", shortLabel: "DR%" },
  crashRate: {
    longLabel: "Crash Rate",
    shortLabel: "Crash%",
  },
  topct: { longLabel: "Turnover %", shortLabel: "TO%" },
  fg2pct: { longLabel: "2-Point %", shortLabel: "2P%" },
  fg3pct: { longLabel: "3-Point %", shortLabel: "3P%" },
  fg3apg: { longLabel: "3-Point Attempt Per Game", shortLabel: "3PA/G" },
  ftpct: { longLabel: "Free Throw %", shortLabel: "FT%" },
  fta48: {
    longLabel: "Free Throw Attempts Per 48 Minutes",
    shortLabel: "FTA/48",
  },
  ppp: { longLabel: "Points Per Possession", shortLabel: "PPP" },
  usg: { longLabel: "Usage", shortLabel: "Usg" },
  norm_max_speed: {
    longLabel: "Top Speed",
    shortLabel: "Top Speed",
  },
  norm_min_ke: {
    longLabel: "Decel",
    shortLabel: "Decel",
  },
  norm_max_power: {
    longLabel: "Burst",
    shortLabel: "Burst",
  },
};

// Numbers from Drew, in his words it "lines up where do guys with a “1" scout
// speed typically land, etc, as opposed to dividing on actual positional
// lines".
export const scoutSpeedForPos: Record<
  "speed" | "burst" | "decel",
  { value: number; label: string }[]
> = {
  speed: [
    { value: 67.8, label: "PG,SG" },
    { value: 63.4, label: "SF" },
    { value: 60.7, label: "PF" },
    { value: 48.7, label: "C" },
  ],
  burst: [
    { value: 64.3, label: "PG,SG" },
    { value: 59.5, label: "SF" },
    { value: 55.1, label: "PF" },
    { value: 42.5, label: "C" },
  ],
  decel: [
    { value: 54.5, label: "PG" },
    { value: 51.6, label: "SG" },
    { value: 47.6, label: "SF" },
    { value: 40.9, label: "PF" },
    { value: 33.9, label: "C" },
  ],
};

export const measurementRanges: Record<
  string,
  Record<"C" | "PF" | "SF" | "SG" | "PG", number[]>
> = {
  // order matters for compare players page
  heightWithShoes: {
    C: [
      78.7213125925262, 80.23375725858308, 81.83969897644965, 83.08474067798029,
      83.90184314125925, 84.80775697848853, 85.74328335335174,
    ],
    PF: [
      77.18801174723691, 78.3037689940354, 79.90289222578548, 80.8039290463349,
      82.06249276086827, 83.93089907897938, 85.36098538061191,
    ],
    PG: [
      69.85575944350514, 71.58502081345367, 73.20979208266563,
      74.83286434436006, 75.79719349651567, 77.8831290346582, 79.47461958010348,
    ],
    SF: [
      75.30962972372085, 76.50087505329232, 78.17910704204446,
      79.30600058472768, 80.20120651902462, 81.47535973252663,
      83.02793533919872,
    ],
    SG: [
      72.32410212923848, 73.74320648202813, 75.98196989710381,
      77.19097860517935, 78.44155175382627, 79.88377321262008, 80.8253641553068,
    ],
  },
  weight: {
    C: [
      207.64709653253246, 216.18036123606862, 227.23110808903394,
      241.4390961915876, 254.47732760742508, 279.48603660778065,
      292.29786513917315,
    ],
    PF: [
      197.98762388897146, 206.70818399817193, 219.09994590976368,
      228.51074515838735, 238.86434742655692, 257.72420984008596,
      263.88980912921653,
    ],
    PG: [
      160.08142886075674, 168.00809692487525, 176.59244565275876,
      186.344920180295, 197.30530795917906, 209.48331769009758,
      221.62871971072389,
    ],
    SF: [
      172.25504270515853, 187.5301091482211, 204.43733056940965,
      214.24975268016703, 223.25396804593566, 236.49684437754772,
      246.11563386184167,
    ],
    SG: [
      169.77421320318942, 176.59244565275876, 191.18302510062068,
      201.84067516089078, 211.09272770808383, 225.25362164529037,
      232.59431113578074,
    ],
  },
  wingspan: {
    C: [
      81.57246111261647, 82.68171922580584, 85.01599709195237, 87.0,
      88.27237078127503, 90.3994170445109, 92.21093001918341,
    ],
    PF: [
      78.52695352650412, 80.8335243921849, 83.0, 84.10709424350412,
      85.9741484550074, 88.00720840826277, 88.92369653237415,
    ],
    PG: [
      70.96489697068844, 73.38545962882188, 75.67357854162606, 78.1,
      79.39798551409407, 81.52476071791851, 82.78289487271196,
    ],
    SF: [
      77.04251321033614, 78.73085254674903, 80.62698018335512,
      82.21377881964322, 83.66550076947487, 86.47207171143968,
      88.02519522738677,
    ],
    SG: [
      74.71490925400168, 75.84470037637739, 78.96238293840531,
      79.93161283230243, 81.69301694877872, 83.52608449977532,
      85.29238569203345,
    ],
  },
  standReach: {
    C: [
      102.91795323379533, 104.87626451938921, 107.10187341631232,
      108.42663227561725, 110.13236023427841, 112.30828291436106,
      114.8619892450121,
    ],
    PF: [
      100.9825469463346, 101.89051294115377, 104.04795812999207,
      105.8083697152293, 107.28388425703992, 109.93325679216726,
      111.64689405305707,
    ],
    PG: [
      90.61308926736201, 91.50401216473797, 95.27752543920592,
      97.17199997845026, 98.90576882566107, 101.43069772769039,
      103.09545690979846,
    ],
    SF: [
      97.50635677499584, 98.62537776386372, 101.5338123963317,
      102.99554421913959, 104.66685165541259, 106.81855663157233,
      108.62389703383754,
    ],
    SG: [
      93.15768654794476, 96.12935068864832, 98.37197516244595,
      100.52334077861319, 102.17489513814839, 104.14233878768586,
      105.84899889912407,
    ],
  },
  verticalJumpNoStep: {
    C: [
      23.742766388737344, 24.386450971125374, 26.290502286297585,
      27.52689875373805, 28.887696645908335, 30.972969187913662,
      32.03095897832363,
    ],
    PF: [
      23.686503644849083, 25.269358337921883, 27.05703891344907,
      28.205362096571374, 29.673427741016077, 31.840166609959308,
      33.183166996237844,
    ],
    PG: [
      25.297173603822653, 26.293823445286613, 28.209074242364952,
      29.373544090236408, 30.534320756495998, 33.07373437553145,
      34.08115825772806,
    ],
    SF: [
      25.024895358256188, 25.92526470479374, 27.281825601530013,
      28.840736188100525, 29.92432918223634, 32.04615449292564,
      34.17584837558601,
    ],
    SG: [
      25.71880840237139, 26.331886719742183, 27.949555496232634,
      29.20967815863439, 30.174605869048047, 32.98220445129134,
      34.08115825772806,
    ],
  },
  verticalJumpOneStep: {
    C: [
      27.153813472834322, 30.101262624952927, 32.45205562307739,
      33.67256036958437, 35.67490914830341, 36.9745850639926,
      39.318272764378484,
    ],
    PF: [
      30.4214565863473, 31.61693528716671, 33.028062575680835,
      34.788648725223105, 36.24601428000532, 37.7799048858145,
      39.37652958223512,
    ],
    PG: [
      30.92688374803421, 32.345628116816, 34.896432449596205, 36.4227058328494,
      38.22671841654307, 39.64632379603744, 41.26562354574607,
    ],
    SF: [
      30.4214565863473, 32.03903983495983, 34.11037307327804, 35.33006741692368,
      37.130984616885556, 39.16202287812094, 40.00046787808859,
    ],
    SG: [
      31.362080824675765, 32.55513945173505, 34.48919854155797,
      35.945173099920886, 37.72428291842671, 39.36366404302973,
      40.14132715399393,
    ],
  },
  run34: {
    C: [
      3.213065663817595, 3.2604899148150226, 3.317140954404333,
      3.3585449585034777, 3.4036011031633664, 3.5068098916026855,
      3.623023510371615,
    ],
    PF: [
      3.197218593526273, 3.2169091528527836, 3.292292167876541,
      3.3297245899705654, 3.36859300751341, 3.4892604683848942,
      3.5231703258650167,
    ],
    PG: [
      3.129252459053146, 3.186044245680312, 3.2345185109545116,
      3.2822659206510685, 3.3134867611855467, 3.3584085031612134,
      3.4349842657954235,
    ],
    SF: [
      3.1992201394081228, 3.2213037384022116, 3.278546358739463,
      3.3084772750216125, 3.3672449783029377, 3.4142059404061866,
      3.511240902605452,
    ],
    SG: [
      3.16137756735468, 3.1992201394081228, 3.2619963395744898,
      3.2933332589258675, 3.3329479480968134, 3.3927127891690008,
      3.4892604683848942,
    ],
  },
  laneAgility: {
    C: [
      10.887415359866239, 11.022413318661677, 11.275681179764888,
      11.575839768788693, 11.858744630731676, 12.359131707094848,
      12.455255364852006,
    ],
    PF: [
      10.887415359866239, 10.99195211955214, 11.18315523136482,
      11.369919003755173, 11.56418303920443, 11.80459407064255,
      12.073295718468598,
    ],
    PG: [
      10.72390754497573, 10.884580340820072, 11.01634137312904,
      11.225480975242474, 11.363824755817646, 11.660606059441436,
      11.872679796601176,
    ],
    SF: [
      10.827509528123093, 10.957687328739423, 11.16007196477642,
      11.342409795126429, 11.519561250230469, 11.790657006387612,
      12.192375165332628,
    ],
    SG: [
      10.72390754497573, 10.847660262472436, 11.082374757071124,
      11.201468590557866, 11.397539797556716, 11.805891303520498,
      12.166776780945897,
    ],
  },
  handLength: {
    C: [
      8.327846592971635, 8.482446288061338, 8.857191785971805, 9.06523476257827,
      9.321899758158835, 9.683095109761663, 9.80434010120582,
    ],
    PF: [
      8.320788487607429, 8.426911858300944, 8.698690325890595, 8.89179704170084,
      9.073178155638491, 9.4065718601667, 10.765173744483143,
    ],
    PG: [
      7.544412868461736, 7.872034765533176, 8.26072754256207, 8.398286562944413,
      8.629445069023275, 8.89208855765326, 9.106722078299216,
    ],
    SF: [
      8.025782870142178, 8.320788487607429, 8.51247142645487, 8.752905218681006,
      8.9426729753734, 9.317653141265748, 10.765173744483143,
    ],
    SG: [
      8.000990536823396, 8.05050869287534, 8.389726273369947, 8.537892398750047,
      8.758370147568344, 8.971413192930823, 9.317653141265748,
    ],
  },
  handWidth: {
    C: [
      8.550939497573907, 8.753136246333364, 9.397508267152833, 9.80145975989092,
      10.245941189774413, 10.72002670431243, 10.797197389912041,
    ],
    PF: [
      8.586142546762021, 8.645741508491234, 9.239238204960138,
      9.510453549192615, 10.01282615903931, 10.413990048005953,
      11.026783048491499,
    ],
    PG: [
      7.670334194965497, 8.389196216473222, 8.89029186208769, 9.247633224378287,
      9.574898283666915, 10.264924457219038, 10.638211123638305,
    ],
    SF: [
      8.586142546762021, 8.619723667628127, 9.008655381696085, 9.37757650123301,
      9.790591860143167, 10.373610653541482, 10.865635114001329,
    ],
    SG: [
      8.296710108918102, 8.605137014574913, 8.960669455216388,
      9.229731679921043, 9.500974069325059, 10.007932315035143,
      10.462665686775997,
    ],
  },
  bodyFat: {
    C: [
      5.1345207596980575, 5.562586420446939, 6.575385174523903,
      7.929199371618006, 9.168047094627662, 14.411010577346019,
      15.133779662825757,
    ],
    PF: [
      4.709291765581634, 5.1788325586821395, 6.459413927769676,
      7.86833947784794, 9.572033366269876, 12.801095660345204,
      14.411010577346019,
    ],
    PG: [
      3.741254406751861, 4.613367073021914, 5.698257589982977,
      6.387032920356328, 7.52370878193444, 9.538567321712831,
      10.291771576258343,
    ],
    SF: [
      4.121959698915421, 4.7562845862062275, 5.814727126884432,
      7.042176361284698, 8.028061877836855, 10.291771576258343,
      12.185469862466615,
    ],
    SG: [
      4.121959698915421, 4.862236647828658, 5.6798818669994695,
      6.799329194996478, 8.102849659551065, 10.291771576258343,
      12.08760921751275,
    ],
  },
};

export const measurementRangeLabels: Record<string, string> = {
  weight: "Weight",
  wingspan: "Wingspan",
  heightWithShoes: "Height (In Shoes)",
  standReach: "St. Reach",
  bodyFat: "Body Fat",
  handLength: "Hand Length",
  handWidth: "Hand Width",
  laneAgility: "Lane Agility",
  run34: "Run 3/4",
  verticalJumpNoStep: "Vert. No Step",
  verticalJumpOneStep: "Vert. Max",
};

export const simpleShotTypeLabelMap: Record<string, string> = {
  layup: "Layup",
  jumper: "Jumper",
  post: "Post",
  floater: "Floater",
  heave: "Heave",
};

export const shotLabels: {
  direction: Record<string, string>;
  drive_direction: Record<string, string>;
} = {
  direction: {
    left: "Left",
    none: "None",
    right: "Right",
  },
  drive_direction: {
    left: "Left",
    right: "Right",
    nonDrive: "Non-Drive",
  },
};

export const complexShotTypeMap: Record<
  string,
  { label: string; type: string; children?: string[]; parent?: string }
> = {
  catchAndShootOnMove: {
    label: "C&S on Move",
    type: "jumper",
    children: ["catchAndShootOnMoveLeft", "catchAndShootOnMoveRight"],
  },
  catchAndShootOnMoveLeft: {
    label: "C&S on Move (Left)",
    type: "jumper",
    parent: "catchAndShootOnMove",
  },
  catchAndShootOnMoveRight: {
    label: "C&S on Move (Right)",
    type: "jumper",
    parent: "catchAndShootOnMove",
  },
  catchAndShootRelocating: { label: "C&S Relocating", type: "jumper" },
  cutLayup: { label: "Cut Layup", type: "angleLayup" },
  drivingLayup: { label: "Driving Layup", type: "drivingLayup" },
  cutFloater: { label: "Cut Floater", type: "drivingLayup" },
  drivingFloater: { label: "Driving Floater", type: "drivingLayup" },
  heave: { label: "Heave", type: "heave" },
  lob: { label: "Lob", type: "lob" },
  overScreen: { label: "Off PNR", type: "jumper" },
  post: { label: "Post", type: "post", children: ["postLeft", "postRight"] },
  postLeft: { label: "Post (LS)", type: "post", parent: "post" },
  postRight: { label: "Post (RS)", type: "post", parent: "post" },
  pullupJumper: { label: "Pull-up Jumper", type: "jumper" },
  shakeAndRaise: { label: "Shake & Raise", type: "jumper" },
  catchAndShoot: { label: "Spot Up", type: "jumper" },
  standstillLayup: { label: "Still Layup", type: "angleLayup" },
  stepback: { label: "Stepback", type: "jumper" },
  tip: { label: "Tip", type: "angleLayup" },
};

export const groupByMap = [
  { value: "season", label: "Season" },
  { value: "gameString", label: "Game" },
  { value: "oteam", label: "Off Team" },
  { value: "dteam", label: "Def Team" },
  { value: "shooter", label: "Shooter" },
  { value: "passer", label: "Passer" },
  { value: "defender", label: "Defender" },
  { value: "positionShooter", label: "Position (At Time)" },
  { value: "jointPosition", label: "Position (Typical)" },
  { value: "positionDefender", label: "Defender Position (At Time)" },
  { value: "jointPositionDef", label: "Defender Position (Typical)" },
  { value: "period", label: "Period" },
  { value: "isRegularSeason", label: "Playoffs" },
  { value: "transition", label: "Transition" },
  { value: "leftSide", label: "Left Side" },
  { value: "oreb2024", label: "After Oreb 20-24" },
  { value: "fouled", label: "Fouled" },
  { value: "isThree", label: "3Pt Shot" },
  { value: "contestLevel", label: "Contest Level" },
  { value: "made", label: "Made" },
  { value: "blocked", label: "Blocked" },
  { value: "putback", label: "Putback" },
  { value: "aboveTheBreak", label: "Above the Break" },
  { value: "corner", label: "Corner 3" },
  { value: "afterTimeout", label: "After Timeout" },
  { value: "generalShotType", label: "General Shot Type" },
  { value: "specificShotType", label: "Specific Shot Type" },
  { value: "direction", label: "C&S Direction" },
  { value: "driveDirection", label: "Drive Direction" },
  { value: "dribblesBefore", label: "Dribbles" },
];

export const pnrLabels: {
  defense: Record<string, string>;
  direction: Record<string, string>;
  roll_pop: Record<string, string>;
  scr_type: Record<string, string>;
  location_type: Record<string, string>;
  bhr_def_type_complex: Record<string, string>;
  scr_def_type_complex: Record<string, string>;
  coverage: Record<string, string>;
  scrDefenderLevel: Record<string, string>;
} = {
  defense: {
    blitz: "Blitz",
    contain: "Contain",
    ice: "Ice",
    reject: "Reject",
    show: "Show",
    soft: "Soft",
    veer: "Veer",
    switch: "Switch",
    under: "Under",
    NT: "Non-threatening",
    "up to touch": "Up to touch",
    weak: "Weak",
  },
  direction: {
    left: "Left",
    none: "None",
    right: "Right",
  },
  roll_pop: {
    other: "Other",
    pop: "Pop",
    roll: "Roll",
  },
  scr_type: {
    pick: "Pick",
    reject: "Reject",
    slip: "Slip",
  },
  location_type: {
    middle: "Middle",
    stepUp: "Step Up",
    wing: "Wing",
  },
  bhr_def_type_complex: {
    blitz: "Blitz",
    contain: "Contain",
    ice: "Ice",
    "ice switch": "Ice Switch",
    none: "NT",
    over: "Over",
    switch: "Switch",
    under: "Under",
    weak: "Weak",
    "weak switch": "Weak Switch",
  },
  scr_def_type_complex: {
    blitz: "Blitz",
    contain: "Contain",
    "ice soft": "Ice Soft",
    "ice switch": "Ice Switch",
    "ice up to touch": "Ice Up To Touch",
    none: "NT",
    show: "Show",
    soft: "Soft",
    switch: "Switch",
    "up to touch": "Up To Touch",
  },
  coverage: {
    blitz: "Blitz",
    ice: "Ice",
    "non-threatening": "Non-threatening",
    reject: "Reject",
    show: "Show",
    soft: "Soft",
    switch: "Switch",
    under: "Under",
    "up to touch": "Up To Touch ",
  },
  scrDefenderLevel: {
    "non-threatening": "Non-threatening",
    none: "None",
    reject: "Reject",
    "show/blitz": "Show/Blitz",
    soft: "Soft",
    switch: "Switch",
    under: "Under",
    "up to touch": "Up To Touch",
  },
};

const draftPlayerTypes = [...Array(5).keys()].map(
  (i) => `Draft ${parseInt(AppContext.currentSeason) + i}`
);

export const PlayerTypes = [
  "Celtics",
  "Free Agent",
  "Retired",
  "Rights Held",
  "Trade",
].concat(draftPlayerTypes);

export const Conferences = [
  "A-10",
  "AAC",
  "ACC",
  "AEC",
  "A-Sun",
  "Big 12",
  "Big East",
  "Big Sky",
  "Big South",
  "Big Ten",
  "Big West",
  "CAA",
  "C-USA",
  "Horizon",
  "Ivy",
  "MAAC",
  "MAC",
  "MEAC",
  "MVC",
  "MWC",
  "NEC",
  "OVC",
  "Pac-12",
  "Patriot",
  "SEC",
  "SoCon",
  "Southland",
  "Summit",
  "Sun Belt",
  "SWAC",
  "WAC",
  "WCC",
];

export const MostRecentLeagues = [
  "adidas NGT",
  "Adidas U16 International All-Star Game",
  "adidas Uprising",
  "Adriatic 2nd",
  "Adriatic Junior",
  "Adriatic",
  "Africa Basketball League",
  "Africa U16",
  "Africa U18",
  "AfroBasket Qualifying",
  "Alpe Adria Cup",
  "Americas U16",
  "AmeriCup Qualifiers",
  "Argentina D-League",
  "Argentina",
  "Armenia - A-League",
  "Asean",
  "Asia Champions Cup EABA Qualifier",
  "Asia Champions",
  "Australia - ACT Premier League 1",
  "Australia - NBL Preseason",
  "Australia - Queensland State League",
  "Australia - SBL",
  "Australia 2nd",
  "Australia U16",
  "Australia U18",
  "Australia U20",
  "Australia Waratah",
  "Australia",
  "Austria",
  "Balkan",
  "Baltic Cup U14",
  "Baltic Cup U15",
  "Baltic Cup U16",
  "Baltic Cup",
  "Baltic Sea U16",
  "Baltic Sea U18",
  "Basketball Africa League Qlf",
  "BBL Cup",
  "BBL Trophy",
  "Belarus",
  "Belgian Basketball Cup",
  "Belgium 2",
  "Belgium",
  "Bosnia",
  "Brazil - LDB",
  "Brazil",
  "Bulgaria",
  "Canada - CEBL",
  "Canada U15",
  "Canada U17",
  "Centrobasket U17",
  "Champions League Americas",
  "Champions League Qualifying",
  "Champions League",
  "Chile - LNB",
  "China",
  "Cholet Mondial",
  "Colombia Liga DirecTV",
  "Copa Del Rey",
  "Copa LEB Plata Unico",
  "Coppa Italia",
  "Coupe de France",
  "Croatia 2nd",
  "Croatia U12",
  "Croatia U14",
  "Croatia U16",
  "Croatia U18",
  "Croatia",
  "Crystal Bohemia U17",
  "Cup of Serbia",
  "Cyprus",
  "Czech - 1Liga",
  "Czech",
  "Deng Camp Future Stars",
  "Denmark",
  "Dominican",
  "Estonia",
  "Estonian-Latvian",
  "Eurasia League",
  "Euro U16 B",
  "Euro U16 C",
  "Euro U16",
  "Euro U18 B",
  "Euro U18 C",
  "Euro U18",
  "Euro U20 B",
  "Euro U20",
  "EuroBasket Qualifying",
  "EuroCup",
  "Euroleague",
  "EYBL Challenge Cup U13",
  "EYBL U14 CE",
  "EYBL U14 D1",
  "EYBL U14 D2",
  "EYBL U15 CE",
  "EYBL U15 D1",
  "EYBL U15 D2",
  "EYBL U16 CE",
  "EYBL U16 D1",
  "EYBL U16 D2",
  "EYBL U17 D1",
  "EYBL U17 D2",
  "EYBL u20 CE",
  "EYBL U20",
  "EYBL",
  "FIBA Afrocan",
  "FIBA Asia Champions Cup GBA Qualifier",
  "FIBA Asia Cup Qualifiers",
  "FIBA Eurobasket Pre-Qualifiers",
  "FIBA Europe Cup",
  "FIBA U14 South America",
  "FIBA World Cup European Pre-Qualifiers",
  "FIBA World Cup",
  "FIBA Worlds U19",
  "Finland - 1st Division A",
  "Finland",
  "France - Pro A Preseason",
  "France 2nd",
  "France 3rd",
  "France Espoirs",
  "France Inter Ligues U15",
  "France Inter Zones U-15",
  "France Inter Zones U13",
  "France",
  "G-League Ignite Scrimmages",
  "G-League",
  "GBA Invitational U19",
  "Geico High School Nationals",
  "Georgia",
  "Germany 2nd",
  "Germany 3rd",
  "Germany U16",
  "Germany U19",
  "Germany",
  "Great Britain",
  "Greece 2nd",
  "Greece",
  "Hungary",
  "Iceland",
  "Interligas",
  "Iran Superleague",
  "Israel - Super League Preseason",
  "Israel Winner Cup",
  "Israel",
  "Italy 2nd",
  "Italy NextGen Cup",
  "Italy Supercoppa",
  "Italy U14",
  "Italy U15",
  "Italy U16",
  "Italy U18",
  "Italy",
  "Japan B.League",
  "Japan B2.League",
  "JIT Lissone",
  "King Mindaugas Cup",
  "Konya U16",
  "Korea",
  "Kosovo - Superliga",
  "Kresimir Cosic Cup",
  "Latvia",
  "Lebanon",
  "Lions Cup U15",
  "Lithuania 2nd",
  "Lithuania U14",
  "Lithuania U15",
  "Lithuania U16",
  "Lithuania U17",
  "Lithuania U18",
  "Lithuania",
  "Luxembourg - Total Lg",
  "Macedonia",
  "Mexico Summer",
  "Mexico Winter",
  "Minicopa",
  "NBA Draft Combine",
  "NBA Playoffs",
  "NBA Preseason",
  "NBA Regular Season",
  "NBPA Top 100 Camp",
  "NCAA",
  "Netherlands",
  "New Zealand",
  "NIBC Invitational",
  "Nike EYBL U15",
  "Nike EYBL U16",
  "NKL Belarus",
  "Nordic U16",
  "Nordic U18",
  "Norway",
  "Oceania U17",
  "Pacific Games",
  "Pan American",
  "Philippines",
  "Poland",
  "Polish Basketball Cup",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Radivoj Korac Cup",
  "Romania Cup",
  "Romania",
  "Russia VTB Youth",
  "Russia VTB",
  "Serbia",
  "Slovakia",
  "Slovenia Ball",
  "Slovenia",
  "Slovenian Basketball Cup",
  "South America U17",
  "Spain 2nd",
  "Spain 3rd",
  "Spain U14 Club",
  "Spain U14 Regional",
  "Spain U16 Club",
  "Spain U16 Regional",
  "Spain U18 Club",
  "Spain",
  "Sudamericana",
  "Supercopa ACB",
  "Supercoppa LNP",
  "Sweden",
  "Switzerland",
  "Szent Istvan U16",
  "Tel Aviv International Youth Tournament",
  "Terrific 12",
  "The Basketball League (US)",
  "The Basketball Tournament",
  "Torneo U18 Ciutat de L'Hospitalet",
  "Turkey - BGL",
  "Turkey - Preseason",
  "Turkey 2nd",
  "Turkey U14",
  "Turkey U16 AA",
  "Turkey U18 AA",
  "Turkey",
  "U15 Balkan Next Star Cup",
  "UA Association U16",
  "UA Association",
  "Uganda FUBA",
  "Ukraine",
  "Uruguay LUB",
  "Vegas Summer",
  "Venezuela Superliga",
  "Venezuela",
  "YDF Project Tournament",
];

export const ActionLabelsMap: Record<string, { label: string; desc: string }> =
  {
    "APM Adjustment (Defense)": {
      label: "APM  Adjustment (Defense)",
      desc: "APM adjustment (defense)",
    },
    "APM Adjustment (Offense)": {
      label: "APM  Adjustment (Offense)",
      desc: "",
    },
    ASTTOV2_Opp_adjDE2: {
      label: "Assist-to-Turnover X Opposing Defense SOS",
      desc: "Assists over assists + turnovers, squared, adjusted for opposing defense strength of schedule",
    },
    ASTTOV_decay: {
      label: "Assist-to-Turnover",
      desc: "Assists over assists + turnovers",
    },
    ASTr_Opp_adjDE: {
      label: "Assist Rate X Opposing Defense SOS",
      desc: "Assist rate, adjusted for opposing defense strength of schedule",
    },
    ASTr_decay: { label: "Assist Rate", desc: "" },
    BLKr_decay: {
      label: "Block Rate",
      desc: "Blocks per team defensive possession",
    },
    DIntR: { label: "Interception Rate", desc: "" },
    DR_mean_rim_distance: { label: "DR Mean Rim Distance", desc: "" },
    DR_mean_rim_distance_log: {
      label: "Defensive Rebounding Rim Distance",
      desc: "",
    },
    DR_reb_shot_prob_mean: {
      label: "Defensive Rebounding Initial Positioning",
      desc: "Expected DR%, based on positioning at the time of the shot",
    },
    DR_rim_under_10_rate_over_def_overallfinishAbility: {
      label: "DR Within 10 Feet Rate X Finish Contest Impact",
      desc: "",
    },
    DRipR: {
      label: "Rip Rate",
      desc: "Non-passing steals per team defensive possession",
    },
    DRovershot: {
      label: "DR% Over Location at Shot",
      desc: "Difference between DR% and expected DR% at the time of the shot",
    },
    DRp_Opp_adjOE: { label: "DR% X Opposing Offense SOS", desc: "" },
    DRp_decay: { label: "DR%", desc: "" },
    DRrimopp: {
      label: "DR Get to Ball Ability",
      desc: "Frequency of getting near the ball on defensive rebounds, above expected rate when the ball hits the rim",
    },
    F2p_decay: { label: "2P%", desc: "2P%" },
    F3Ar_decay: {
      label: "F3A%",
      desc: "Threes taken per team offensive possession",
    },
    F3Ar_decay_above_league: {
      label: "3PA Rate Above League",
      desc: "Threes taken per team offensive possession, above league average",
    },
    F3Ar_up_to_1: {
      label: "Three-Point Rate, Up to 1 Per 100 Possessions",
      desc: "Threes taken per team offensive possession, credit only given up to 1 per 100",
    },
    F3Ar_up_to_3: {
      label: "Three-Point Rate, Up to 3 Per 100 Possessions",
      desc: "Threes taken per team offensive possession, credit only given up to 3 per 100",
    },
    FT_ORp: { label: "OR% on FTs", desc: "" },
    FTp_decay: { label: "FT%", desc: "FT%" },
    OR_mean_rim_distance_log: {
      label: "Log Mean OR Rim Distance",
      desc: "Log of average distance from the rim on offensive rebounds, at the time when the ball hits the rim",
    },
    OR_reb_opp_prob_mean: { label: "OR Get Nearest Ball Rate", desc: "" },
    OR_rim_under_10_rate: { label: "Crash Within 10 Feet Rate", desc: "" },
    OR_rim_under_15_rate: { label: "Crash Within 15 Feet Rate", desc: "" },
    OR_rim_under_5_rate_2: { label: "Crash Within 5 Feet Rate", desc: "" },
    OR_rim_under_5_rate_over_def_overallfinishAbility: {
      label: "Crash Within 5 Feet Rate X Finish Contest Impact",
      desc: "",
    },
    ORgettoball: {
      label: "OR Get to Ball Ability",
      desc: "Frequency of getting close to the ball on offensive rebounds, above the rate expected when the shot is taken",
    },
    ORp_decay: { label: "OR%", desc: "" },
    ORp_decay2: { label: "OR% Squared", desc: "OR%, squared." },
    ORshottorim: {
      label: "OR Positioning Improvement",
      desc: "Expected OR% based on when the ball hits the rim, above the expected OR% based on when the shot is taken",
    },
    Opp_adjDE2: { label: "Opposing Defense SOS", desc: "" },
    Opp_adjDE_decay: {
      label: "Opponent SOS (Defense)",
      desc: "Opponent team defense quality, including non-NBA opponents",
    },
    Opp_adjOE_decay: { label: "Opponent SOS (Offense)", desc: "" },
    PNR_ballhandler: { label: "PNR Ballhandler", desc: "" },
    PNR_ballhandler_defender: { label: "PNR Ballhandler Defender", desc: "" },
    PNR_ballhandler_scoring: { label: "PNR Ballhandler Scoring", desc: "" },
    PNR_ballhandler_xscoring: {
      label: "PNR Ballhandler Scoring",
      desc: "Expected points scored, according to the shot model, per PNR ballhandler action",
    },
    PNR_perimeter_spacer: { label: "PNR Perimeter Spacer", desc: "" },
    PNR_perimeter_spacer_def_contest_impact: {
      label: "PNR Perimeter Spacer Defender Contest Impact",
      desc: "",
    },
    PNR_perimeter_spacer_defender: {
      label: "PNR Perimeter Spacer Defender",
      desc: "",
    },
    PNR_perimeter_spacer_xpassing: {
      label: "PNR Perimeter Spacer Passing",
      desc: "",
    },
    PNR_perimeter_spacer_xscoring: {
      label: "PNR Perimeter Spacer Scoring",
      desc: "",
    },
    PNR_perimeter_spacer_xscoring_total: {
      label: "PNR Perimeter Spacer Expected Total Scoring",
      desc: "Expected points scored as PNR perimeter spacer shooter per overall team offensive possession",
    },
    PNR_perimeter_spacer_xscoring_total_truncated: {
      label: "PNR Perimeter Spacer Expected Total Scoring, Truncated",
      desc: "Expected points scored, according to the shot model, as PNR perimeter spacer, per overall team possession. Capped before the very top of the high end.",
    },
    PNR_popper: { label: "PNR Popper", desc: "" },
    PNR_popper_defender: { label: "PNR Popper Defender", desc: "" },
    PNR_popper_xscoring_total: { label: "PNR Popper Scoring", desc: "" },
    PNR_rim_spacer: { label: "PNR Rim Spacer", desc: "" },
    PNR_rim_spacer_def_contest_impact: {
      label: "PNR Rim Spacer Defender Contest Impact",
      desc: "",
    },
    PNR_rim_spacer_defender: { label: "PNR Rim Spacer Defender", desc: "" },
    PNR_rim_spacer_xpassing: { label: "PNR Rim Spacer Passing", desc: "" },
    PNR_rim_spacer_xscoring_total: {
      label: "PNR Rim Spacer Scoring",
      desc: "Expected points scored, according to the shot model, as PNR rim spacer, per overall team possession",
    },
    PNR_screener_defender_high: {
      label: "PNR Screener Defender High",
      desc: "",
    },
    PNR_screener_defender_switch: {
      label: "PNR Screener Defender Switch",
      desc: "",
    },
    PNR_screener_defender_traditional: {
      label: "PNR Screener Defender Traditional",
      desc: "",
    },
    PNR_roller: { label: "PNR Roller", desc: "" },
    PNR_roller_defender: { label: "PNR Roller Defender", desc: "" },
    PNR_roller_scoring: {
      label: "PNR Roller Scoring",
      desc: "Shots taken per roll, times shot clock-adjusted PPP scored on those actions",
    },
    PTSr2_Opp_adjDE2: {
      label: "Points Rate Squared X Opposing Defense SOS",
      desc: "Points scored per team offensive possession, squared, adjusted for opposing defense quality",
    },
    PTSr_Opp_adjDE: {
      label: "Points Rate X Opposing Defense SOS",
      desc: "Points scored per team offensive possession, adjusted for opposing defense quality",
    },
    PTSr_decay: { label: "PTS Rate", desc: "" },
    ROS_off_mean_distance: {
      label: "Run Off Screen Chase Distance Created",
      desc: "",
    },
    SR2: { label: "Standing Reach (Squared)", desc: "Standing reach, squared" },
    STLr_Opp_adjOE: {
      label: "Steal Rate X Opposing Offense SOS",
      desc: "Steals per team defensive possession, adjusted for opponent offense quality",
    },
    STLr_decay: {
      label: "Steal Rate",
      desc: "Steals per team defensive possession",
    },
    ST_Reach: { label: "Standing Reach", desc: "" },
    TOVr_decay: {
      label: "Turnover Rate",
      desc: "Turnovers per team offensive possession",
    },
    Usage2: { label: "Usage Squared", desc: "Usage, squared" },
    Usage_decay: { label: "Usage", desc: "Usage" },
    WS2: { label: "Wingspan (Squared)", desc: "Wingspan, squared" },
    Wt2: { label: "Weight Squared", desc: "Weight, squared" },
    adjDE2: {
      label: "Team Defense Quality (Squared)",
      desc: "Team defense quality, including recent non-NBA teams, squared",
    },
    adjDE_decay: {
      label: "Team Defense Quality",
      desc: "Team defense quality, including recent non-NBA teams",
    },
    catch_and_go_drive_drive_defender: {
      label: "Catch and Go Drive Defender",
      desc: "",
    },
    catch_and_go_drive_driver: { label: "Catch and Go Driver", desc: "" },
    catch_and_go_drive_perimeter_spacer: {
      label: "Catch and Go Drive Perimeter Spacer",
      desc: "",
    },
    catch_and_go_drive_perimeter_spacer_defender: {
      label: "Catch and Go Drive Perimeter Spacer Defender",
      desc: "",
    },
    catch_and_go_drive_rim_spacer: {
      label: "Catch and Go Drive Rim Spacer",
      desc: "",
    },
    catch_and_go_drive_rim_spacer_defender: {
      label: "Catch and Go Drive Rim Spacer Defender",
      desc: "",
    },
    advantage_drive_drive_defender: {
      label: "Advantage Drive Defender",
      desc: "",
    },
    advantage_drive_driver: { label: "Advantage Driver", desc: "" },
    advantage_drive_driver_creation_failure: {
      label: "Advantage Drive Creation Failure",
      desc: "Shot clock-adjusted PPP when failing to generate a shot as advantage driver, times the frequency of failing to generate a shot",
    },
    advantage_drive_driver_xscoring: {
      label: "Advantage Drive Expected Scoring",
      desc: "Expected points scored, according to the shot model, per advantage drive",
    },
    advantage_drive_driver_xscoring_total: {
      label: "Advantage Drive Scoring",
      desc: "Expected points scored, according to the shot model, on advantage drives per overall team possession",
    },
    advantage_drive_perimeter_spacer: {
      label: "Advantage Drive Perimeter Spacer",
      desc: "",
    },
    advantage_drive_perimeter_spacer_defender: {
      label: "Advantage Drive Perimeter Spacer Defender",
      desc: "",
    },
    advantage_drive_rim_spacer: {
      label: "Advantage Drive Rim Spacer",
      desc: "",
    },
    advantage_drive_rim_spacer_defender: {
      label: "Advantage Drive Rim Spacer Defender",
      desc: "",
    },
    all_shots_rim_loc_vs_exp: {
      label: "Shot Rim Locations",
      desc: "Expected PPS on shots, as measured when the ball hits the rim, above expected PPS on shots at time of release",
    },
    bc_touch_mult_dribble_rate: {
      label: "Backcourt Multi-Dribble Rate",
      desc: "",
    },
    bc_touch_mult_dribble_rate_above_league: {
      label: "Backcourt Touch Multi Dribble Rate Above League",
      desc: "Rate of taking multiple dribbles on backcourt touches, above the leaguewide rate",
    },
    bhr_def_under_rate: { label: "PNR Under Rate", desc: "" },
    closeout_TOV_rate: {
      label: "Closeout Turnover Rate",
      desc: "Turnover rate on offense, in closeout situations",
    },
    contest_rate: { label: "Contest Rate", desc: "" },
    contester_AOI_iso_iso_defender: {
      label: "Iso Defender Contest AOI",
      desc: "",
    },
    contester_AOI_rebounding_defensive_rebounding: {
      label: "Defensive Rebounding Contest AOI",
      desc: "",
    },
    contester_AOI_transition_in_play_defense: {
      label: "Transition In Play (Defense) Contest AOI",
      desc: "Shot clock-adjusted PPP when contesting when in the play in transition",
    },
    contester_rate_advantage_drive_perimeter_spacer_defender: {
      label: "Advantage Drive Perimeter Spacer Defender Contester Rate",
      desc: "Contest rate per advantage drive perimeter spacer defender",
    },
    contester_rate_non_action_ballhandler_defender: {
      label: "Non-Action Ballhandler Defender Contester Rate",
      desc: "Contest rate when defending non-action ballhandlers",
    },
    contester_rate_transition_in_play_defense: {
      label: "Contest Rate (Transition In-Play Defense)",
      desc: "",
    },
    contester_xAOI_PNR_perimeter_spacer_defender: {
      label: "PNR Perimeter Spacer Defender Contest xAOI",
      desc: "",
    },
    contester_xAOI_PNR_popper_defender: {
      label: "PNR Popper Defender Contester xAOI",
      desc: "Shot clock-adjusted expected PPP, according to the shot model, when contesting as PNR popper defender",
    },
    contester_xAOI_PNR_roller_defender: {
      label: "PNR Roller Defender Contest xAOI",
      desc: "",
    },
    contester_xAOI_PNR_scr_defender_wtd: {
      label: "PNR Screener Defender Contest xAOI",
      desc: "",
    },
    contester_xAOI_iso_iso_defender: {
      label: "Iso Defender Contest xAOI",
      desc: "",
    },
    contester_xAOI_non_action_ballhandler_defender: {
      label: "Non-Action Ballhandler Defender Contester xAOI",
      desc: "",
    },
    contester_xAOI_post_post_defender: {
      label: "Post Defender Contest xAOI",
      desc: "",
    },
    crashORp: { label: "OR% on Crash", desc: "" },
    crashORp2: {
      label: "OR% on Crash, Squared",
      desc: "OR% when crashing, squared",
    },
    cs3_rate: {
      label: "Spot-Up 3 Rate",
      desc: "Spot-up 3s attempted per team offensive possession",
    },
    cs3_rate_x_cs3_makeAbility: {
      label: "Spot-Up Make Ability X Spot-Up 3 Rate",
      desc: "",
    },
    cs3_rel_rate: {
      label: "Catch-and-Shoot Relocating 3 Rate",
      desc: "Relocation 3s attempted per team offensive possession",
    },
    cs3_rel_rate_x_cs3_makeAbility: {
      label: "Spot-Up Make Ability X Catch-and-Shoot 3 Relocating Rate",
      desc: "Relocation 3s attempted per team offensive possession, times shot model ability on those shots",
    },
    csm3_rate: {
      label: "Shooting On Move 3PA Rate",
      desc: "Catch-and-shoot 3s on the move attempted per team offensive possession",
    },
    csm_rate: { label: "Shoot on Move Rate", desc: "" },
    cut_ballhandler: { label: "Cut Ballhandler", desc: "" },
    cut_ballhandler_defender: { label: "Cut Ballhandler Defender", desc: "" },
    cut_cutter: { label: "Cutter", desc: "" },
    cut_cutter_defender: { label: "Cutter Defender", desc: "" },
    cut_cutter_xscoring: {
      label: "Cutter Scoring",
      desc: "Expected points scored, according to the shot model, per cut",
    },
    cut_perimeter_spacer: { label: "Cut Perimeter Spacer", desc: "" },
    cut_perimeter_spacer_defender: {
      label: "Cut Perimeter Spacer Defender",
      desc: "",
    },
    cut_rim_spacer: { label: "Cut Rim Spacer", desc: "" },
    cut_rim_spacer_defender: { label: "Cut Rim Spacer Defender", desc: "" },
    cut_screener: { label: "Cut Screener", desc: "" },
    cut_screener_defender: { label: "Cut Screener Defender", desc: "" },
    def_contested_reb_conv: {
      label: "Defensive Contested Rebound Conversion Rate",
      desc: "Team DR% on contested rebounds, with the player on the floor",
    },
    def_foulAbility_finish: {
      label: "Finish Contest Foul Avoidance",
      desc: "Shot model player effect of avoiding fouls on finishes",
    },
    def_makeAbility_finish: {
      label: "Finish Challenge Make Impact",
      desc: "Shot model player effect of forcing misses on finishes",
    },
    def_overallAbility_finish: {
      label: "Finish Challenge Overall Impact",
      desc: "Shot model player effect on finish contests",
    },
    defensive_rebounding: { label: "Defensive Rebounding", desc: "" },
    drb3_makeAbility: { label: "Dribble 3 Make Ability", desc: "" },
    drb3_overallAbility: { label: "Dribble 3 Shooting Ability", desc: "" },
    finish_challenge_0_rate: {
      label: "Positive Finish Challenge Rate",
      desc: "Positive impact challenges on finishes per team defensive possession",
    },
    finish_challenge_10_rate: {
      label: ".10+ Finish Challenge Rate",
      desc: "0.10 PPS (or greater) impact challenges on finishes per team defensive possession",
    },
    finish_challenge_20_rate: { label: ".20+ Finish Challenge Rate", desc: "" },
    finish_challenge_30_rate: { label: ".30+ Finish Challenge Rate", desc: "" },
    finish_challenge_5_rate: { label: ".05+ Finish Challenge Rate", desc: "" },
    finish_contest_rate: {
      label: "Finish Contest Rate",
      desc: "Finish contests per team defensive possession",
    },
    finish_contest_x_finishImpact: {
      label: "Finish Contest Rate X Impact",
      desc: "Finish contests per team defensive possession, times shot model player effect on finish contests",
    },
    finish_hack_0_rate: { label: "Negative Finish Challenge Rate", desc: "" },
    finish_hack_neg10_rate: { label: "Finish Hack Rate", desc: "" },
    foulAbility_cutLayup: { label: "Cut Layup Foul Ability", desc: "" },
    heightWShoes: { label: "Height", desc: "Height with shoes" },
    helper_layup_allowed_rate: {
      label: "Layup Allowed Rate as Helper",
      desc: "Rate of layups allowed when helping on opponent drives",
    },
    interior_crash_ORp: { label: "Interior Crash OR%", desc: "" },
    interior_crash_ORp2: {
      label: "OR% on Interior Crash, Squared",
      desc: "OR% on interior crashes, squared",
    },
    isoBH_bad_NL2_shots: { label: "Iso Non-Layup 2 Rate", desc: "" },
    isoBH_rim_scoring: {
      label: "Iso Rim Scoring",
      desc: "Rate of getting to the rim per iso, times shot model PPS player ability on driving layups",
    },
    isoBH_shot_pps_adj: {
      label: "Opponent-Adjusted Iso Shot PPS",
      desc: "Iso PPS, adjusted for opponent iso defender",
    },
    isoBH_shot_pps_adj_log: { label: "Adjusted Iso Shot PPS", desc: "" },
    isoBH_shot_pps_adj_sqrt: {
      label: "Opponent-Adjusted Iso Shot PPS",
      desc: "Square root of iso PPS, adjusted for opponent iso defender",
    },
    isoBH_three_scoring: {
      label: "Iso Three-Point Scoring",
      desc: "Rate of taking threes per iso, times shot model PPS player ability on iso-type 3s (pull-ups, stepbacks, shake-and-raises)",
    },
    iso_def_shot_pps: {
      label: "Iso Shot PPS Defense",
      desc: "PPS allowed as iso defender",
    },
    iso_def_shot_pps_adj: {
      label: "Adjusted Iso Shot PPS Defense",
      desc: "PPS allowed as iso defender, adjusted for opponent iso ballhandler ",
    },
    iso_force_help_rate: { label: "Iso Force Help Rate", desc: "" },
    iso_iso: { label: "Iso Ballhandler", desc: "" },
    iso_iso_defender: { label: "Iso Defender", desc: "" },
    iso_iso_scoring: { label: "Iso Scoring Effectiveness", desc: "" },
    iso_iso_xscoring_total: { label: "Iso Scoring X Frequency", desc: "" },
    iso_perimeter_spacer: { label: "Iso Perimeter Spacer", desc: "" },
    iso_perimeter_spacer_defender: {
      label: "Iso Perimeter Spacer Defender",
      desc: "",
    },
    iso_rim_spacer: { label: "Iso Rim Spacer", desc: "" },
    iso_rim_spacer_defender: { label: "Iso Rim Spacer Defender", desc: "" },
    jmp5Make_rate: {
      label: "5%+ Jumper Contest Rate",
      desc: "5% shot probability (or greater) impact challenges on jumpers per team defensive possession",
    },
    jump_ball_jump_ball_other: { label: "Jump Ball (Other)", desc: "" },
    jump_ball_jumper: { label: "Jump Ball (Jumper)", desc: "" },
    jump_ball_win_rate: {
      label: "Jump Ball Win %",
      desc: "Win% on jump balls",
    },
    jumper_contest_rate: { label: "Jumper Contest Rate", desc: "" },
    jumper_contest_x_jumperImpact: {
      label: "Jumper Contest Rate X Jumper Contest Impact",
      desc: "Jumper contests per team defensive possession, times shot model PPS defensive player ability on jumper contests",
    },
    lWS: { label: "Wingspan (Logged)", desc: "Log of wingspan" },
    lWt: { label: "Log Weight", desc: "" },
    lgMIN_decay: { label: "League Quality", desc: "" },
    log_ASTTOV_decay: {
      label: "Log Assist-to-Turnover",
      desc: "Log of assists over assists + turnovers",
    },
    log_ASTr_decay: {
      label: "Log Assist Rate",
      desc: "Log of assists per team offensive possession",
    },
    log_BLKr_decay: { label: "Log Block Rate", desc: "" },
    log_DIntR: {
      label: "Log Interception Rate",
      desc: "Log of rate of passes on steals per defensive possession",
    },
    log_DRipR: {
      label: "Log Rip Rate",
      desc: "Log of rate of non-passing steals per team defensive possession",
    },
    log_F3Ar_decay: {
      label: "Log F3A%",
      desc: "Log of three-point attempts per team offensive possession",
    },
    log_F3Ar_decay_above_league_truncated: {
      label: "Log Three-Point Rate Above League, Truncated",
      desc: "Log of rate of three-point attempts per team offensive possession, above league average. Capped before the very top of the high end.",
    },
    log_FT_ORp: {
      label: "Log Free Throw OR%",
      desc: "Log of OR% on free throws",
    },
    log_FTp_decay: { label: "Log FT%", desc: "Log of free throw percentage" },
    log_OR_rim_under_5_rate: {
      label: "Log Crash Within 5 Feet Rate",
      desc: "Log of rate of crashing to within five feet of the rim per team missed field goal",
    },
    log_ORp_decay: { label: "Log OR%", desc: "Log of OR%" },
    log_PTSr_decay: {
      label: "Log PTS Rate",
      desc: "Log of player points per overall team possession",
    },
    log_STLr_decay: {
      label: "Log Steal Rate",
      desc: "Log of steals per team defensive possession",
    },
    log_ST_Reach: {
      label: "Log Standing Reach",
      desc: "Log of standing reach",
    },
    log_TOVr_decay: { label: "Log Turnover Rate", desc: "" },
    log_adjDE: {
      label: "Team Defense Quality (Logged)",
      desc: "Log of team defense quality, including recent non-NBA teams",
    },
    log_adjOE: {
      label: "Team Offense Quality (Logged)",
      desc: "Log of team offense quality, including recent non-NBA teams",
    },
    log_bc_touch_mult_dribble_rate: {
      label: "Log Backcourt Multi-Dribble Rate",
      desc: "Log of rate of taking multiple dribbles on backcourt touches",
    },
    log_finish_challenge_0_rate: {
      label: "Log Positive Finish Challenge Rate",
      desc: "",
    },
    log_finish_challenge_10_rate: {
      label: "Log .10+ Finish Challenge Rate",
      desc: "Log of rate of 0.10 PPS (or greater) impact challenges on finishes per team defensive possession",
    },
    log_finish_challenge_20_rate: {
      label: "Log .20+ Finish Challenge Rate",
      desc: "",
    },
    log_finish_challenge_30_rate: {
      label: "Log .30+ Finish Challenge Rate",
      desc: "Log of rate of 0.30 PPS (or greater) impact challenges on finishes per team defensive possession",
    },
    log_finish_challenge_5_rate: {
      label: "Log .05+ Finish Challenge Rate",
      desc: "Log of rate of 0.05 PPS (or greater) impact challenges on finishes per team defensive possession",
    },
    log_finish_contest_rate: {
      label: "Log Finish Contest Rate",
      desc: "Log of rate of finish contests per team defensive possession",
    },
    log_finish_hack_0_rate: {
      label: "Log Hack on Challenge Rate",
      desc: "Log of rate of negative impact (high foul probability) challenges per team defensive possession",
    },
    log_finish_hack_neg10_rate: { label: "Log Finish Hack Rate", desc: "" },
    log_iso_force_help_rate: {
      label: "Log Iso Force Help Rate",
      desc: "Log of rate of forcing defensive help in iso situations",
    },
    log_jump_ball_recovery_rate: {
      label: "Log Jump Ball Recovery Rate",
      desc: "",
    },
    log_rate_rebounding_crash: { label: "Log Crash Rate", desc: "" },
    log_rate_rebounding_inside_arc: {
      label: "Log OR Inside Arc Rate",
      desc: "Log of rate of non-crash, inside the arc offensive rebounding per overall team possession",
    },
    log_rate_rebounding_interior_crash: {
      label: "Log Interior Crash Rate",
      desc: "Log of rate of interior crashes per overall team possession",
    },
    log_rate_transition_behind_play_defense: {
      label: "Log Transition Behind Play (Defense) Rate",
      desc: "Log of rate of falling behind the play in transition defense, per overall team possession",
    },
    log_rate_transition_behind_play_offense: {
      label: "Log Transition Behind Play Offense Rate",
      desc: "",
    },
    log_ros_jumper_pps: { label: "Log Run Off Screen Jumper PPS", desc: "" },
    log_runs_above_2_5_frameacc25_rate: {
      label: "Log High-Effort Runs Rate",
      desc: "",
    },
    log_trans_involvement_rateD: {
      label: "Log Defensive Transition Involvement Rate",
      desc: "",
    },
    log_trans_involvement_rateO: {
      label: "Log Transition Involvement Rate",
      desc: "Log of rate of being involved in the play in transition offense, per team transition action",
    },
    lpass_cs_totTOV: {
      label: "Log Passes to Spot-Ups Per Turnover",
      desc: "Log of passes to spot-ups over passes to spot-ups + total turnovers",
    },
    mAOI_PNR_perimeter_spacer_defender: {
      label: "PNR Perimeter Spacer Defender AOI",
      desc: "",
    },
    mAOI_PNR_roller: { label: "PNR Roller AOI", desc: "" },
    mAOI_above_league_advantage_drive_driver: {
      label: "Advantage Drive AOI",
      desc: "Shot clock-adjusted PPP above league average as an advantage driver",
    },
    mAOI_above_league_advantage_drive_perimeter_spacer_defender: {
      label: "Advantage Drive Perimeter Spacer Defender mAOI Above League",
      desc: "Shot clock-adjusted PPP above league average as an advantage drive perimeter spacer defender",
    },
    mAOI_above_league_iso_iso: {
      label: "Iso AOI Above League",
      desc: "Shot clock-adjusted PPP above league average as an iso ballhandler",
    },
    mAOI_above_league_iso_iso_defender: {
      label: "Iso Defender AOI Above League",
      desc: "",
    },
    mAOI_above_league_non_action_ballhandler_defender: {
      label: "Non-Action Ballhandler Defender AOI",
      desc: "Shot clock-adjusted PPP above league average as a non-action ballhandler",
    },
    mAOI_above_league_run_off_screen_run_off_screen: {
      label: "Run Off Screen AOI Above League",
      desc: "",
    },
    mAOI_above_league_run_off_screen_run_off_screen_defender: {
      label: "Run Off Screen Defender AOI Above League",
      desc: "Shot clock-adjusted PPP above league average as a run off screen defender",
    },
    mAOI_above_league_transition_in_play_offense: {
      label: "Transition In Play (Offense) AOI Above League",
      desc: "Shot clock-adjusted PPP above league average as a transition offensive player (non-ballhandler)",
    },
    mAOI_advantage_drive_drive_defender: {
      label: "Advantage Drive Defender AOI",
      desc: "",
    },
    mAOI_advantage_drive_perimeter_spacer_defender: {
      label: "Advantage Drive Perimeter Spacer Defender AOI",
      desc: "",
    },
    mAOI_advantage_drive_rim_spacer_defender: {
      label: "Advantage Drive Rim Spacer Defender AOI",
      desc: "Shot clock-adjusted PPP as an advantage drive rim spacer defender",
    },
    mAOI_iso_def_above_SOS: {
      label: "Opponent-Adjusted Iso Defender AOI",
      desc: "",
    },
    mAOI_iso_iso_defender: { label: "Iso Defender AOI", desc: "" },
    mAOI_jump_ball_jump_ball_other: {
      label: "Jump Ball (Other) AOI",
      desc: "",
    },
    mAOI_jump_ball_jumper: {
      label: "Jump Ball AOI",
      desc: "Shot clock-adjusted PPP as a jump ball jumper",
    },
    mAOI_non_action_ballhandler_defender: {
      label: "Non-Action Ballhandler Defender AOI",
      desc: "",
    },
    mAOI_pnr_bhr_def_above_SOS: {
      label: "Opponent-Adjusted PNR Ballhandler Defender AOI",
      desc: "",
    },
    mAOI_pnr_per_spa_def_above_SOS: {
      label: "Opponent-Adjusted PNR Perimeter Spacer Defender AOI",
      desc: "",
    },
    mAOI_pnr_rim_spa_above_SOS: {
      label: "Opponent-Adjusted PNR Rim Spacer Defender AOI",
      desc: "",
    },
    mAOI_rebounding_FT_lane_line_offense: {
      label: "FT Rebounding (Lane Line Offense) AOI",
      desc: "",
    },
    mAOI_rebounding_get_back: { label: "Get Back AOI", desc: "" },
    mAOI_rebounding_inside_arc: { label: "OR Inside Arc AOI", desc: "" },
    mAOI_run_off_screen_run_off_screen: {
      label: "Run Off Screen AOI",
      desc: "",
    },
    mAOI_run_off_screen_run_off_screen_defender: {
      label: "Run Off Screen Defender AOI",
      desc: "",
    },
    mAOI_transition_in_play_defense: {
      label: "Transition Defense (In Play) AOI",
      desc: "",
    },
    makeAbility_catchAndShoot: {
      label: "Spot Up Make Ability",
      desc: "Shot model player effect on making spot-ups",
    },
    makeAbility_cutLayup: { label: "Cut Layup Make Ability", desc: "" },
    makeAbility_drivingLayup: {
      label: "Driving Layup Make Ability",
      desc: "Shot model player effect on making driving layups",
    },
    makeAbility_post: {
      label: "Post Make Ability",
      desc: "Shot model player effect on making post shots",
    },
    makeAbility_standstillLayup: {
      label: "Standstill Layup Make Ability",
      desc: "Shot model player effect on making standstill layups",
    },
    makeAbility_stepback: { label: "Stepback Make Ability", desc: "" },
    mean_max_kinetic15_high_speed: { label: "Max Acceleration", desc: "" },
    mean_min_kinetic15_high_speed: { label: "Max Deceleration", desc: "" },
    mean_non_action_length: { label: "Non-Action Pace", desc: "" },
    mean_pastHC: {
      label: "Ballhandler Mean Shot Clock at Halfcourt",
      desc: "",
    },
    mean_post_def_shot_dist_adj: {
      label: "Adjusted Post Shot Distance Defense",
      desc: "",
    },
    misc_defense: { label: "Misc. Defense", desc: "" },
    misc_offense: { label: "Misc. Offense", desc: "" },
    mxAOI_PNR_perimeter_spacer_defender: {
      label: "PNR Perimeter Spacer Defender xAOI",
      desc: "",
    },
    mxAOI_PNR_popper_defender: { label: "PNR Popper Defender xAOI", desc: "" },
    mxAOI_PNR_roller: { label: "PNR Roller xAOI", desc: "" },
    mxAOI_PNR_roller_defender: {
      label: "PNR Roller Defender xAOI",
      desc: "Shot clock-adjusted expected PPP, according to the shot model, as a PNR roller defender",
    },
    mxAOI_above_league_PNR_ballhandler: {
      label: "PNR Ballhandler xAOI Above League",
      desc: "Shot clock-adjusted expected PPP, according to the shot model, above league average, as a PNR ballhandler",
    },
    mxAOI_above_league_PNR_perimeter_spacer_defender: {
      label: "PNR Perimeter Spacer Defender xAOI Above League",
      desc: "Shot clock-adjusted expected PPP, according to the shot model, above league average, as a PNR perimeter spacer defender",
    },
    mxAOI_above_league_advantage_drive_perimeter_spacer_defender: {
      label: "Advantage Drive Perimeter Spacer Defender xAOI Above League",
      desc: "",
    },
    mxAOI_above_league_cut_cutter_defender: {
      label: "Cutter Defender xAOI Above League",
      desc: "",
    },
    mxAOI_above_league_iso_iso: { label: "Iso xAOI Above League", desc: "" },
    mxAOI_above_league_iso_iso_defender: {
      label: "Iso Defender xAOI Above League",
      desc: "Shot clock-adjusted expected PPP, according to the shot model, above league average, as an iso defender",
    },
    mxAOI_above_league_non_action_spacer: {
      label: "Non-Action Spacer xAOI Above League",
      desc: "Shot clock-adjusted expected PPP, according to the shot model, above league average, as a non-action spacer",
    },
    mxAOI_above_league_post_post_defender: {
      label: "Post Defender xAOI Above League",
      desc: "Shot clock-adjusted expected PPP, according to the shot model, above league average, as a post defender",
    },
    mxAOI_above_league_rebounding_FT_lane_line_defense: {
      label: "FT Rebounding (Lane Line Defense) xAOI Above League",
      desc: "",
    },
    mxAOI_above_league_rebounding_FT_lane_line_offense: {
      label: "FT Lane Line (Offense) xAOI Above League",
      desc: "Shot clock-adjusted expected PPP, according to the shot model, above league average, when on the lane line on teammate missed free throws",
    },
    mxAOI_above_league_rebounding_defensive_rebounding: {
      label: "Defensive Rebounding xAOI Above League",
      desc: "Shot clock-adjusted expected PPP, according to the shot model, above league average, as a defensive rebounder",
    },
    mxAOI_above_league_rebounding_inside_arc: {
      label: "OR Inside Arc xAOI Above League",
      desc: "Shot clock-adjusted expected PPP, according to the shot model, above league average, as a non-crashing, inside arc offensive rebounder",
    },
    mxAOI_above_league_run_off_screen_ballhandler: {
      label: "Run Off Screen Ballhandler xAOI Above League",
      desc: "Shot clock-adjusted expected PPP, according to the shot model, above league average, as a ballhandler on run-off-screen actions",
    },
    mxAOI_above_league_run_off_screen_perimeter_spacer_defender: {
      label: "Run Off Screen Perimeter Spacer Defender xAOI Above League",
      desc: "Shot clock-adjusted expected PPP, according to the shot model, above league average, as a perimeter spacer defender on run-off-screen actions",
    },
    mxAOI_above_league_run_off_screen_run_off_screen: {
      label: "Run Off Screen xAOI Above League",
      desc: "Shot clock-adjusted expected PPP, according to the shot model, above league average, when running off screens",
    },
    mxAOI_above_league_run_off_screen_run_off_screen_defender: {
      label: "Run Off Screen Defender xAOI Above League",
      desc: "",
    },
    mxAOI_above_league_transition_behind_play_defense: {
      label: "Transition Behind Play (Defense) xAOI Above League",
      desc: "Shot clock-adjusted expected PPP, according to the shot model, above league average, when behind the play in transition defense",
    },
    mxAOI_above_league_transition_behind_play_offense: {
      label: "Transition Behind Play (Offense) xAOI Above League",
      desc: "Shot clock-adjusted expected PPP, according to the shot model, above league average, when behind the play in transition offense",
    },
    mxAOI_above_league_transition_in_play_defense: {
      label: "Transition In Play (Defense) xAOI Above League",
      desc: "",
    },
    mxAOI_advantage_drive_drive_defender: {
      label: "Advantage Drive Defender xAOI",
      desc: "Shot clock-adjusted expected PPP, according to the shot model, as an advantage drive defender",
    },
    mxAOI_cut_ballhandler_defender: {
      label: "Cut Ballhandler Defender xAOI",
      desc: "",
    },
    mxAOI_cut_perimeter_spacer_defender: {
      label: "Cut Perimeter Spacer Defender xAOI",
      desc: "",
    },
    mxAOI_cut_screener: {
      label: "Cut Screener xAOI",
      desc: "Shot clock-adjusted expected PPP, according to the shot model, as a cut screener",
    },
    mxAOI_iso_def_above_SOS: {
      label: "Opponent-Adjusted Iso Defender xAOI",
      desc: "",
    },
    mxAOI_iso_iso: {
      label: "Iso xAOI",
      desc: "Shot clock-adjusted expected PPP, according to the shot model, as an iso ballhandler",
    },
    mxAOI_iso_iso_defender: {
      label: "Iso Defender xAOI",
      desc: "Shot clock-adjusted expected PPP, according to the shot model, as an iso defender",
    },
    mxAOI_iso_iso_sqrt: {
      label: "Iso xAOI (Square Root)",
      desc: "Square root of shot clock-adjusted expected PPP, according to the shot model, as an iso ballhandler",
    },
    mxAOI_iso_rim_spacer_defender: {
      label: "Iso Rim Spacer Defender xAOI",
      desc: "",
    },
    mxAOI_jump_ball_jumper: { label: "Jump Ball Jumper xAOI", desc: "" },
    mxAOI_non_action_ballhandler: {
      label: "Non-Action Ballhandler xAOI",
      desc: "Shot clock-adjusted expected PPP, according to the shot model, as a non-action ballhandler",
    },
    mxAOI_non_action_spacer: { label: "Non-Action Spacer xAOI", desc: "" },
    mxAOI_non_action_spacer_defender: {
      label: "Non-Action Spacer Defender xAOI",
      desc: "Shot clock-adjusted expected PPP, according to the shot model, as a non-action spacer defender",
    },
    mxAOI_pnr_bhr_def_above_SOS: {
      label: "Opponent-Adjusted PNR Ballhandler Defender xAOI",
      desc: "",
    },
    mxAOI_pnr_per_spa_above_SOS: {
      label: "Ballhandler-Adjusted PNR Perimeter Spacer xAOI",
      desc: "",
    },
    mxAOI_pnr_per_spa_def_above_SOS: {
      label: "Opponent-Adjusted PNR Perimeter Spacer Defender xAOI",
      desc: "Shot clock-adjusted expected PPP, according to the shot model, as a PNR perimeter spacer defender, adjusted for opponent PNR ballhandler",
    },
    mxAOI_pnr_popper_above_SOS: {
      label: "Ballhandler-Adjusted PNR Popper xAOI",
      desc: "Shot clock-adjusted expected PPP, according to the shot model, as a PNR popper, adjusted for PNR ballhandler",
    },
    mxAOI_pnr_rim_spa_def_above_SOS: {
      label: "Opponent-Adjusted PNR Rim Spacer Defender xAOI",
      desc: "Shot clock-adjusted expected PPP, according to the shot model, as a PNR rim spacer defender, adjusted for opponent PNR ballhandler",
    },
    mxAOI_pnr_roller_above_SOS: {
      label: "Ballhandler-Adjusted PNR Roller xAOI",
      desc: "Shot clock-adjusted expected PPP, according to the shot model, as a PNR roller, adjusted for PNR ballhandler",
    },
    mxAOI_pnr_roller_def_above_SOS: {
      label: "Opponent-Adjusted PNR Roller Defender xAOI",
      desc: "Shot clock-adjusted expected PPP, according to the shot model, as a PNR roller defender, adjusted for opponent PNR ballhandler",
    },
    mxAOI_post_post: { label: "Post Ballhandler xAOI", desc: "" },
    mxAOI_rebounding_FT_lane_line_defense: {
      label: "FT Rebounding (Lane Line Defense) xAOI",
      desc: "",
    },
    mxAOI_rebounding_defensive_rebounding: {
      label: "Defensive Rebounding xAOI",
      desc: "Shot clock-adjusted expected PPP, according to the shot model, as a defensive rebounder",
    },
    mxAOI_rebounding_offensive_rebounding: {
      label: "Offensive Rebounding xAOI",
      desc: "",
    },
    mxAOI_run_off_screen_run_off_screen: {
      label: "Run Off Screen xAOI",
      desc: "",
    },
    mxAOI_transition_in_play_defense: {
      label: "Transition In Play (Defense) xAOI",
      desc: "",
    },
    n_passes_30_rate: { label: "Long Pass Rate", desc: "" },
    non_actionR_ballhandler_passing: {
      label: "Non-Action Ballhandler Passing Impact",
      desc: "Rate of passing for shots as non-action ballhandler, times shot-clock adjusted PPP when passing for shots as non-action ballhandler",
    },
    non_action_ballhandler: { label: "Non-Action Ballhandler", desc: "" },
    non_action_ballhandler_creation_failure: {
      label: "Non-Action Ballhandler Creation Failure",
      desc: "Shot clock-adjusted PPP when failing to generate a shot as non-action ballhandler, times the frequency of failing to generate a shot",
    },
    non_action_ballhandler_defender: {
      label: "Non-Action Ballhandler Defender",
      desc: "",
    },
    non_action_ballhandler_scoring: {
      label: "Non-Action Ballhandler Scoring",
      desc: "",
    },
    non_action_ballhandler_xcreation_failure: {
      label: "Non-Action Ballhandler Non-Shooting xAOI",
      desc: "",
    },
    non_action_spacer: { label: "Non-Action Spacer", desc: "" },
    non_action_spacer_defender: {
      label: "Non-Action Spacer Defender",
      desc: "",
    },
    non_jumpers_rim_loc_vs_exp: {
      label: "Post & Floater Shot Rim Locations",
      desc: "",
    },
    nsf_non_int_rate: {
      label: "Non-Intentional Non-Shooting Foul Drawn Rate",
      desc: "Non-shooting fouls, excluding intentional fouls, drawn per offensive team possession",
    },
    nsf_non_int_rate_x_FTp: {
      label: "Non-Intentional Non-Shooting Foul Drawn Rate X FT%",
      desc: "Non-intentional non-shooting fouls drawn per team offensive possession, times FT%",
    },
    nsf_rate: {
      label: "Non-Shooting Foul Rate",
      desc: "Non-shooting fouls drawn per team offensive possession",
    },
    nsf_rate_x_FTp: {
      label: "Non-Shooting Foul Drawn Rate X FT%",
      desc: "Non-shooting fouls drawn per team offensive possession, times FT%",
    },
    off_foul_drawn_rate: {
      label: "Offensive Foul Drawn Rate",
      desc: "Offensive fouls drawn per team defensive possession",
    },
    offensive_rebounding: { label: "Offensive Rebounding", desc: "" },
    other_AOI_rebounding_FT_lane_line_defense: {
      label: "FT Rebounding (Lane Line Defense) Get Ball AOI",
      desc: "",
    },
    other_AOI_rebounding_FT_lane_line_offense: {
      label: "FT Rebounding Lane Line (Offense) Get Ball AOI",
      desc: "",
    },
    other_AOI_rebounding_FT_shooter: {
      label: "FT Shooter Rebounding Get Ball AOI",
      desc: "",
    },
    other_AOI_rebounding_defensive_rebounding: {
      label: "Defensive Rebounding Get Ball AOI",
      desc: "",
    },
    other_AOI_transition_behind_play_defense: {
      label: "Transition Defense (Behind Play) Reset AOI",
      desc: "",
    },
    other_AOI_transition_in_play_defense: {
      label: "Transition Defense (In Play) Reset AOI",
      desc: "",
    },
    other_xAOI_rebounding_FT_lane_line_defense: {
      label: "FT Lane Line (Defense) Get Ball xAOI",
      desc: "Shot clock-adjusted expected PPP, according to the shot model, when on the lane line on opponent missed free throws, when no additional shots are allowed",
    },
    other_xAOI_rebounding_defensive_rebounding: {
      label: "Defensive Rebounding Get Ball xAOI",
      desc: "",
    },
    other_xAOI_transition_behind_play_defense: {
      label: "Transition Behind Play (Defense) Reset xAOI",
      desc: "",
    },
    other_xAOI_transition_in_play_offense: {
      label: "Transition In Play (Offense) Reset xAOI",
      desc: "",
    },
    overallAbility_catchAndShoot: {
      label: "Spot-Up Jumper Ability",
      desc: "Shot model player effect on spot-ups",
    },
    overallAbility_cutLayup: { label: "Cut Layup Ability", desc: "" },
    overallAbility_drivingLayup: {
      label: "Driving Layup Ability",
      desc: "Shot model player effect on driving layups",
    },
    overallAbility_floater: {
      label: "Overall Floater Ability",
      desc: "Shot model player effect on floaters",
    },
    pass_lyp_TOV: { label: "Passes to Layups per Passing Turnover", desc: "" },
    passer_rate_cut_ballhandler: {
      label: "Cut Ballhandler Passer Rate",
      desc: "Rate of passing to shots as cut ballhandler",
    },
    passer_xAOI_cut_cutter: { label: "Cutter Extra Pass xAOI", desc: "" },
    passer_xAOI_transition_in_play_offense: {
      label: "Transition Extra Pass xAOI",
      desc: "",
    },
    perimeter_crash_ORp2: {
      label: "Perimeter Crash OR%, Squared",
      desc: "OR% when perimeter crashing, squared",
    },
    perimeter_spacer: { label: "Perimeter Spacer", desc: "" },
    perimeter_spacer_defender: { label: "Perimeter Spacer Defender", desc: "" },
    pick_up_30_rate: {
      label: "30-Foot Ballhandler Pickup Rate",
      desc: "Rate of picking up the ballhandler at 30+ feet, per team defensive possession",
    },
    pnr3_rate: {
      label: "PNR 3 Rate",
      desc: "Threes taken off PNR per team offensive possession",
    },
    pnr_drive_rate: {
      label: "PNR Drive Rate",
      desc: "PNR drives per team offensive possession",
    },
    post_def_score_rate_adj: {
      label: "Adjusted Post Score Prevention Rate",
      desc: "",
    },
    post_perimeter_spacer: { label: "Post Perimeter Spacer", desc: "" },
    post_perimeter_spacer_defender: {
      label: "Post Perimeter Spacer Defender",
      desc: "",
    },
    post_post: { label: "Post Ballhandler", desc: "" },
    post_post_defender: { label: "Post Defender", desc: "" },
    post_post_xscoring: {
      label: "Post Scoring",
      desc: "Expected points scored, according to the shot model, per post-up",
    },
    post_post_xscoring_total: {
      label: "Post Scoring X Frequency",
      desc: "Expected points scored, according to the shot model, as post player, per overall team possession",
    },
    post_rim_spacer: { label: "Post Rim Spacer", desc: "" },
    post_rim_spacer_defender: { label: "Post Rim Spacer Defender", desc: "" },
    rate_non_action_spacer: { label: "Non-Action Spacer Rate", desc: "" },
    rate_rebounding_crash: { label: "Crash Rate", desc: "" },
    rate_rebounding_get_back: {
      label: "Rebounding Get Back Rate",
      desc: "Rate of getting back rather than crashing, per overall team offensive possession",
    },
    rate_rebounding_inside_arc: { label: "OR Inside Arc Rate", desc: "" },
    rate_rebounding_interior_crash: {
      label: "Interior Crash Rate",
      desc: "Rate of interior crashes per overall team offensive possession",
    },
    rate_rebounding_perimeter_crash: {
      label: "Perimeter Crash Rate",
      desc: "Rate of perimeter crashes per overall team offensive possession",
    },
    rate_run_off_screen_run_off_screen: {
      label: "Run Off Screen Rate",
      desc: "Rate of run off screen actions, for the player, per overall team offensive possession",
    },
    rate_run_off_screen_run_off_screen_defender: {
      label: "Run Off Screen Defender Rate",
      desc: "Rate of defending guys running off screens, per team defensive possession",
    },
    rate_transition_ballhandler: {
      label: "Transition Ballhandler Rate",
      desc: "Rate of transition ballhandler actions per team offensive possession",
    },
    rate_transition_behind_play_defense: {
      label: "Transition Defense (Behind Play) Rate",
      desc: "Rate of falling behind the play in transition defense, per overall team defensive possession",
    },
    rate_transition_in_play_offense: {
      label: "Transition In Play (Offense) Rate",
      desc: "Rate of being in the play in transition on offense (non-ballhandler), per overall team possession",
    },
    rebounding_FT_back_defense: {
      label: "FT Rebounding (Back Defense)",
      desc: "",
    },
    rebounding_FT_back_offense: {
      label: "FT Rebounding (Back Offense)",
      desc: "",
    },
    rebounding_FT_lane_line_defense: {
      label: "FT Rebounding (Lane Line Defense)",
      desc: "",
    },
    rebounding_FT_lane_line_offense: {
      label: "FT Rebounding (Lane Line Offense)",
      desc: "",
    },
    rebounding_FT_shooter: { label: "FT Rebounding (Shooter)", desc: "" },
    rebounding_defensive_rebounding: {
      label: "Defensive Rebounding",
      desc: "",
    },
    rebounding_get_back: { label: "OR Get Back", desc: "" },
    rebounding_inside_arc: { label: "OR Inside Arc", desc: "" },
    rebounding_interior_crash: { label: "Interior Crash", desc: "" },
    rebounding_offensive_rebounding: {
      label: "Offensive Rebounding",
      desc: "",
    },
    rebounding_perimeter_crash: { label: "Perimeter Crash", desc: "" },
    rim_spacer: { label: "Rim Spacer", desc: "" },
    rim_spacer_defender: { label: "Rim Spacer Defender", desc: "" },
    role_allocation: {
      label: "Role Rate Allocation",
      desc: "Impact on team PPP coming from simply being in a role, at league average effectiveness, more or less than league average frequency",
    },
    role_allocation_defense: {
      label: "Role Rate Allocation (Defense)",
      desc: "",
    },
    role_allocation_offense: {
      label: "Role Rate Allocation (Offense)",
      desc: "",
    },
    ros_jumper_pps: {
      label: "Run Off Screen Jumper PPS",
      desc: "Expected PPS on jumpers, according to the shot model, on shots coming out of run off screen actions",
    },
    run_off_screen_ballhandler: {
      label: "Run Off Screen Ballhandler",
      desc: "",
    },
    run_off_screen_ballhandler_defender: {
      label: "Run Off Screen Ballhandler Defender",
      desc: "",
    },
    run_off_screen_perimeter_spacer: {
      label: "Run Off Screen Perimeter Spacer",
      desc: "",
    },
    run_off_screen_perimeter_spacer_defender: {
      label: "Run Off Screen Perimeter Spacer Defender",
      desc: "",
    },
    run_off_screen_rim_spacer: { label: "Run Off Screen Rim Spacer", desc: "" },
    run_off_screen_rim_spacer_defender: {
      label: "Run Off Screen Rim Spacer Defender",
      desc: "",
    },
    run_off_screen_run_off_screen: { label: "Run Off Screen", desc: "" },
    run_off_screen_run_off_screen_defender: {
      label: "Run Off Screen Defender",
      desc: "",
    },
    run_off_screen_run_off_screen_scoring: {
      label: "Run Off Screen Scoring",
      desc: "",
    },
    run_off_screen_run_off_screen_xscoring: {
      label: "Run Off Screen Expected Scoring",
      desc: "Expected points scored, according to the shot model, per run off screen action",
    },
    run_off_screen_run_off_screen_xscoring_total: {
      label: "Run Off Screen Expected Scoring",
      desc: "",
    },
    run_off_screen_screener: { label: "Run Off Screen Screener", desc: "" },
    run_off_screen_screener_defender: {
      label: "Run Off Screen Screener Defender",
      desc: "",
    },
    runs_above_2_5_frameacc25_rate: {
      label: "High-Effort Runs Rate",
      desc: "",
    },
    runs_above_3_high_speed_rate: { label: "High-Effort Runs Rate", desc: "" },
    scr_hit_screen_rate: {
      label: "Screen Hit Defender Rate",
      desc: "Rate of making contact with the ballhandler defender when setting PNRs",
    },
    seconds_over_half_saved_per_poss: {
      label: "Seconds Over Halfcourt Saved Per Possession",
      desc: "When bringing the ball up, the average number of seconds above 16 when the ball crosses halfcourt",
    },
    seconds_over_half_taken_per_poss: {
      label: "Seconds Over Halfcourt Taken Per Possession",
      desc: "When bringing the ball up, the average number of seconds taken for the ball to cross halfcourt",
    },
    shooter_AOI_PNR_perimeter_spacer: {
      label: "PNR Perimeter Spacer Shooter AOI",
      desc: "",
    },
    shooter_AOI_cut_cutter: {
      label: "Cutter Shooter AOI",
      desc: "Shot clock-adjusted PPP when getting a shot as a cutter",
    },
    shooter_AOI_rebounding_FT_lane_line_offense: {
      label: "FT Lane Line (Offense) Shooter AOI",
      desc: "",
    },
    shooter_AOI_rebounding_FT_shooter: {
      label: "FT Rebounding (Shooter) Shooter AOI",
      desc: "",
    },
    shooter_AOI_run_off_screen_run_off_screen: {
      label: "Run Off Screen Shooter AOI",
      desc: "",
    },
    shooter_rate_rebounding_FT_lane_line_offense: {
      label: "FT Rebounding Lane Line (Offense) Shooter Rate",
      desc: "",
    },
    shooter_rate_rebounding_crash: { label: "Crash Shooter Rate", desc: "" },
    shooter_rate_rebounding_get_back: {
      label: "Get Back Shooter Rate",
      desc: "Shots taken on offense, per time getting back rather than crashing",
    },
    shooter_rate_rebounding_interior_crash: {
      label: "Interior Crash Shooter Rate",
      desc: "Shots taken per time interior crashing",
    },
    shooter_rate_rebounding_offensive_rebounding: {
      label: "Offensive Rebounding Shooter Rate",
      desc: "",
    },
    shooter_xAOI_PNR_ballhandler: {
      label: "PNR Ballhandler Shooter xAOI",
      desc: "Shot clock-adjusted expected PPP, according to the shot model, when shooting as the PNR ballhandler",
    },
    shooter_xAOI_PNR_perimeter_spacer: {
      label: "PNR Perimeter Spacer Shooter xAOI",
      desc: "Team expected shot-clock adjusted PPP when the player shoots as a PNR perimeter spacer",
    },
    shooter_xAOI_advantage_drive_driver: {
      label: "Advantage Driver Shooter xAOI",
      desc: "",
    },
    shooter_xAOI_post_post: { label: "Post Shooter xAOI", desc: "" },
    shooter_xAOI_rebounding_FT_shooter: {
      label: "FT Rebounding (Shooter) xAOI On Shots",
      desc: "",
    },
    shooter_xAOI_rebounding_interior_crash: {
      label: "Interior Crash Shooter xAOI",
      desc: "Shot clock-adjusted expected PPP, according to the shot model, when shooting as an interior crasher",
    },
    shooter_xAOI_run_off_screen_run_off_screen: {
      label: "Run Off Screen Shooter xAOI",
      desc: "",
    },
    shooter_xAOI_transition_in_play_offense: {
      label: "Transition Offense (In-Play) Shooter xAOI",
      desc: "",
    },
    sqrt_cs3_rel_rate_x_cs3_makeAbility: {
      label: "Spot-Up Make Ability X Root Catch-and-Shoot 3 Relocating Rate",
      desc: "Shot model player effect on spot-ups, times square root of relocation threes taken per team offensive possession",
    },
    sqrt_off_foul_drawn_rate: { label: "Offensive Foul Drawn Rate", desc: "" },
    successful_closeouts_per_poss: {
      label: "Successful Closeouts Per Possession",
      desc: "Rate of defensive closeouts where neither a shot nor drive is allowed, per team defensive possession",
    },
    touch_mult_dribble_rate: {
      label: "Multi-Dribble Touch Rate",
      desc: "Percentage of touches where the player takes multiple dribbles",
    },
    trans_involvement_rateD: {
      label: "Transition Defense Involvement Rate",
      desc: "",
    },
    trans_involvement_rateO: {
      label: "Transition Involvement Rate (Offense)",
      desc: "",
    },
    transition_ballhandler: { label: "Transition Ballhandler", desc: "" },
    transition_ballhandler_creation_failure: {
      label: "Transition Ballhandler Creation Failure",
      desc: "",
    },
    transition_ballhandler_xscoring: {
      label: "Transition Ballhandler Scoring",
      desc: "Expected points scored, according to the shot model, per transition ballhandler possession",
    },
    transition_behind_play_defense: {
      label: "Transition Defense (Behind Play)",
      desc: "",
    },
    transition_behind_play_offense: {
      label: "Transition Offense (Behind Play)",
      desc: "",
    },
    transition_defense: { label: "Transition Defense", desc: "" },
    transition_in_play_defense: {
      label: "Transition Defense (In Play)",
      desc: "",
    },
    transition_in_play_offense: {
      label: "Transition Offense (In Play)",
      desc: "",
    },
    transition_in_play_xscoring: {
      label: "Transition In Play (Offense) Expected Scoring",
      desc: "Expected points scored, according to the shot model, when in transition offense (but not the ballhandler)",
    },
    transition_in_play_xscoring_total: {
      label: "Transition Offense (In Play) Scoring",
      desc: "Expected points scored, according to the shot model, when in transition offense (but not the ballhandler), per overall possession",
    },
    transition_offense: { label: "Transition Offense", desc: "" },
    turnover_AOI_iso_iso: {
      label: "Iso Turnover AOI",
      desc: "Shot clock-adjusted PPP on iso turnovers",
    },
    turnover_AOI_transition_ballhandler: {
      label: "Transition Ballhandler Turnover AOI",
      desc: "",
    },
    turnover_forced_rate_post_post_defender: {
      label: "Post Defender Turnover Force Rate",
      desc: "",
    },
    turnover_forced_rate_transition_in_play_defense: {
      label: "Transition In Play (Defense) Turnover Forced Rate",
      desc: "Turnovers forced per time in the play in transition defense",
    },
    turnover_rate_transition_in_play_offense: {
      label: "Transition In Play (Offense) Turnover Rate",
      desc: "",
    },
  };

export const actionRolesOff = [
  "APM  Adjustment (Offense)",
  "Catch and Go Drive Perimeter Spacer",
  "Catch and Go Drive Rim Spacer",
  "Catch and Go Driver",
  "Cut Ballhandler",
  "Cut Perimeter Spacer",
  "Cut Rim Spacer",
  "Cut Screener",
  "Cutter",
  "Iso Ballhandler",
  "Iso Perimeter Spacer",
  "Iso Rim Spacer",
  "Non-Action Ballhandler",
  "Non-Action Spacer",
  "PNR Ballhandler",
  "PNR Perimeter Spacer",
  "PNR Popper",
  "PNR Rim Spacer",
  "PNR Roller",
  "Post Ballhandler",
  "Post Perimeter Spacer",
  "Post Rim Spacer",
  "Run Off Screen",
  "Run Off Screen Ballhandler",
  "Run Off Screen Perimeter Spacer",
  "Run Off Screen Rim Spacer",
  "Run Off Screen Screener",
  "Transition Ballhandler",
  "Transition Offense (Behind Play)",
  "Transition Offense (In Play)",
];

export const actionRolesDef = [
  "APM  Adjustment (Defense)",
  "Catch and Go Drive Perimeter Spacer Defender",
  "Catch and Go Drive Rim Spacer Defender",
  "Catch and Go Driver Defender",
  "Cut Ballhandler Defender",
  "Cut Perimeter Spacer Defender",
  "Cut Rim Spacer Defender",
  "Cut Screener Defender",
  "Cutter Defender",
  "Iso Defender",
  "Iso Perimeter Spacer Defender",
  "Iso Rim Spacer Defender",
  "Non-Action Ballhandler Defender",
  "Non-Action Spacer Defender",
  "PNR Ballhandler Defender",
  "PNR Perimeter Spacer Defender",
  "PNR Rim Spacer Defender",
  "PNR Screener Defender High",
  "PNR Screener Defender Switch",
  "PNR Screener Defender Traditional",
  "Post Defender",
  "Post Perimeter Spacer Defender",
  "Post Rim Spacer Defender",
  "Run Off Screen Ballhandler Defender",
  "Run Off Screen Defender",
  "Run Off Screen Perimeter Spacer Defender",
  "Run Off Screen Rim Spacer Defender",
  "Run Off Screen Screener Defender",
  "Transition Defense (Behind Play)",
  "Transition Defense (In Play)",
];

export const secondSpectrumContestLevels = {
  uncontested: "Uncontested",
  lightly_contested: "Lightly Contested",
  heavily_contested: "Heavily Contested",
};

export const TWO_WAY_CONTRACTS = ["2WINA", "2WACT", "2WILG"];
