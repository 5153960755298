import React from "react";
import { Col, Row } from "react-bootstrap";

import { Panel } from "../../components/core/Panel";
import { TeamLogo } from "../../components/team/TeamLogo";
import { GamePnrTable } from "../../components/games/GamePnrTable";
import { GamePnrBreakdownTable } from "../../components/games/GamePnrBreakdownTable";
import { GamePnr, GamePnrBreakdown } from "../../../shared/routers/GameRouter";

export function GameSubPagePnr(props: {
  home?: { teamid: number; team: string };
  away?: { teamid: number; team: string };
  pnrs?: GamePnr[];
  pnrBreakdowns?: GamePnrBreakdown[];
}) {
  const { pnrs, pnrBreakdowns, home, away } = props;

  if (!pnrBreakdowns || !pnrs || !home || !away) return null;

  const pnrOffense = pnrs.filter((p) => p.offDef === "off");
  const pnrDefense = pnrs.filter((p) => p.offDef === "def");

  const homeBreakdown = pnrBreakdowns.filter((b) => b.teamId === home.teamid);
  const homeOffense = pnrOffense.filter((o) => o.teamid === home.teamid);
  const homeDefense = pnrDefense.filter((d) => d.teamid === home.teamid);

  const awayBreakdown = pnrBreakdowns.filter((b) => b.teamId === away.teamid);
  const awayOffense = pnrOffense.filter((o) => o.teamid === away.teamid);
  const awayDefense = pnrDefense.filter((d) => d.teamid === away.teamid);

  return (
    <div className="sub-page">
      {renderTeam(
        "home",
        home.team,
        home.teamid,
        homeBreakdown,
        homeOffense,
        homeDefense
      )}
      {renderTeam(
        "away",
        away.team,
        away.teamid,
        awayBreakdown,
        awayOffense,
        awayDefense
      )}
    </div>
  );
}

function renderTeam(
  homeAway: string,
  team: string,
  teamId: number,
  breakdown: GamePnrBreakdown[],
  pnrOffense: GamePnr[],
  pnrDefense: GamePnr[]
) {
  const pnrOffBallhandler = pnrOffense.filter((p) => p.type === "ballhandler");
  const pnrOffScreener = pnrOffense.filter((p) => p.type === "screener");
  const pnrDefBallhandler = pnrDefense.filter(
    (p) => p.type === "ballhandler_defender"
  );
  const pnrDefScreener = pnrDefense.filter(
    (p) => p.type === "screener_defender"
  );
  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <TeamLogo idsId={teamId} size={150} />
      </div>
      <Row>
        <Col md={12}>
          <Panel
            header={`PNR Breakdown · ${team}`}
            panelID={`pnrBreakdown${homeAway}`}
          >
            <GamePnrBreakdownTable data={breakdown} />
          </Panel>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Panel
            header={`PNR Offense · ${team}`}
            panelID={`pnrOffense${homeAway}`}
          >
            <GamePnrTable
              data={pnrOffBallhandler}
              offDef="offense"
              type="ballhandler"
            />
            <GamePnrTable
              data={pnrOffScreener}
              offDef="offense"
              type="screener"
            />
          </Panel>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Panel
            header={`PNR Defense · ${team}`}
            panelID={`pnrDefense${homeAway}`}
          >
            <GamePnrTable
              data={pnrDefBallhandler}
              offDef="defense"
              type="ballhandler"
            />
            <GamePnrTable
              data={pnrDefScreener}
              offDef="defense"
              type="screener"
            />
          </Panel>
        </Col>
      </Row>
    </div>
  );
}
