import React, { useContext } from "react";
import { Badge } from "react-bootstrap";
import { IoMdCloseCircle } from "react-icons/io";
import { SSPlayerContext } from "../../PlayerContext";

import { WowyControlValue } from "./WowyLineupInput";
import { TeamContext } from "../../TeamContext";
import { pctFormat, period, seasonString } from "../../util/Format";

export function FilterChips(props: {
  filters: Record<string, unknown>;
  deletableFilters: string[];
  onRemove: (key: string) => void;
}) {
  const { filters, deletableFilters, onRemove } = props;
  const teams = useContext(TeamContext);
  const players = useContext(SSPlayerContext);

  function formatKey(key: string) {
    switch (key) {
      case "period":
      case "periods":
        return "Period";
      case "season":
      case "seasons":
        return "Season";
      case "teamId":
        return "Team";
      case "oppTeamId":
        return "Opponent";
      case "withPlayers":
        return "With";
      case "withoutPlayers":
        return "Without";
      case "oneOfPlayers":
        return "With One";
      case "lineup":
        return "On/Off";
      case "fromDate":
        return "From";
      case "toDate":
        return "To";
      case "gameString":
        return "Game";
      case "turnover":
        return "Turnover";
      case "transition":
        return "Transition";
      case "wowyLineup":
        return "Lineup";
      case "zone":
        return "Zone";
      default:
        return key;
    }
  }

  function playerFromId(id: number) {
    return players.find((p) => p.playerId === id)?.player || "Unknown";
  }

  function parseWowyLineup(value: WowyControlValue): string {
    const withPlayers = value.withPlayers || [];
    const withoutPlayers = value.withoutPlayers || [];
    const oneOfPlayers = value.oneOfPlayers || [];
    const withPlayersStr = withPlayers.length
      ? `With ${withPlayers.map((p) => playerFromId(parseInt(p))).join(", ")}`
      : "";
    const withoutPlayersStr = withoutPlayers.length
      ? `Without ${withoutPlayers
          .map((p) => playerFromId(parseInt(p)))
          .join(", ")}`
      : "";
    const oneOfPlayersStr = oneOfPlayers.length
      ? `With One Of ${oneOfPlayers
          .map((p) => playerFromId(parseInt(p)))
          .join(", ")}`
      : "";
    return [withPlayersStr, withoutPlayersStr, oneOfPlayersStr]
      .filter((s) => s)
      .join(", ");
  }

  function formatValue(key: string, value: unknown): string {
    switch (key) {
      case "season":
        return seasonString(value as string) || (value as string);
      case "period":
      case "periods":
        return period(value as number);
      case "withPlayers":
      case "withoutPlayers":
      case "oneOfPlayers":
        return playerFromId(value as number);
      case "teamId":
      case "oppTeamId":
        return (
          teams.find((t) => t.teamid === (value as number))?.teamname ||
          "Unknown"
        );
      case "wowyLineup":
        return parseWowyLineup(value as WowyControlValue);
      case "lineup":
        return value ? "Selected" : "Everything Else";
      case "fromLeverage":
      case "toLeverage":
        return pctFormat(value === null ? null : (value as number) / 1_000);
      default:
        return JSON.stringify(value);
    }
  }

  return (
    <div>
      {Object.entries(filters)
        .filter(([, v]) => v !== undefined)
        .map(([key, val], i) => (
          <span key={i}>
            <Badge pill bg="info">
              {formatKey(key)}:
              <b>
                {Array.isArray(val)
                  ? val
                      .sort()
                      .map((v) => formatValue(key, v))
                      .join(", ")
                  : formatValue(key, val)}
              </b>{" "}
              {deletableFilters.includes(key) && (
                <IoMdCloseCircle onClick={() => onRemove(key)} />
              )}
            </Badge>{" "}
          </span>
        ))}
    </div>
  );
}
