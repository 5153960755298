import React, { useCallback } from "react";
import { rgb } from "d3";
import { scaleLinear } from "@visx/scale";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { Group } from "@visx/group";
import { LinePath, Line } from "@visx/shape";
import { ParentSize } from "@visx/responsive";
import { localPoint } from "@visx/event";
import { useTooltip, TooltipWithBounds, defaultStyles } from "@visx/tooltip";
import { Text } from "@visx/text";
import { EventType } from "@visx/event/lib/types";

import { optionB } from "../../constants/ChartConstants";
import { complexShotTypeMap } from "../../constants/AppConstants";
import { Shot } from "../../../shared/routers/ShotRouter";
import { generateDomain } from "../../util/Util";
import { gameClockFormat, decFormat2, decFormat } from "../../util/Format";
import { trpc } from "../../util/tRPC";
import { TEAM_COLORS } from "../../constants/ColorConstants";

const AXIS_COLOR = "#999";
const circleRadius = 5;

export function ShotQuality(props: {
  shots: Shot[];
  filteredOutShots?: Shot[];
  teamId?: number;
}) {
  const { shots, filteredOutShots, teamId } = props;

  const { data: avgPPSByShotClock } = trpc.team.getTeamPPSByShotClock.useQuery({
    teamId: teamId,
  });

  const avgPPSByShotClockTeam = (avgPPSByShotClock || [])
    .filter((p) => p.teamId !== 0)
    .map((p) => ({ x: p.shotClock, y: p.pps }))
    .sort((a, b) => a.x - b.x);

  const avgPPSByShotClockLeague = (avgPPSByShotClock || [])
    .filter((p) => p.teamId === 0)
    .map((p) => ({ x: p.shotClock, y: p.pps }))
    .sort((a, b) => a.x - b.x);

  const teamColors = teamId && TEAM_COLORS[teamId];
  const teamColor = teamColors ? teamColors.primary : "black";

  return (
    <ParentSize parentSizeStyles={{ width: "100%" }}>
      {({ width }) =>
        width > 0 && (
          <ShotQualityInner
            shots={shots}
            filteredOutShots={filteredOutShots}
            avgPPSByShotClockTeam={avgPPSByShotClockTeam}
            avgPPSByShotClockLeague={avgPPSByShotClockLeague}
            teamColor={teamColor}
            width={width}
            height={width}
          />
        )
      }
    </ParentSize>
  );
}

function ShotQualityInner(props: {
  shots: Shot[];
  filteredOutShots?: Shot[];
  width: number;
  height: number;
  teamColor: string;
  avgPPSByShotClockTeam: { x: number; y: number }[];
  avgPPSByShotClockLeague: { x: number; y: number }[];
}) {
  const {
    shots,
    filteredOutShots,
    height,
    width,
    teamColor,
    avgPPSByShotClockTeam,
    avgPPSByShotClockLeague,
  } = props;

  const margin = { top: 10, right: 10, bottom: 40, left: 40 };

  const innerHeight = height - (margin.top + margin.bottom);
  const innerWidth = width - (margin.left + margin.right);

  const xScale = scaleLinear({
    range: [0, innerWidth],
    domain: [24, 0],
    nice: true,
  });

  const sortedShots = shots.sort((a, b) => b.epps - a.epps);
  const maxEppsShot = sortedShots[0];
  const maxEpps =
    shots.length === 0 ? 2 : Math.max(2, maxEppsShot ? maxEppsShot.epps : 0);

  const yScale = scaleLinear({
    range: [innerHeight, 0],
    domain: [0, maxEpps],
    nice: true,
  });

  const colorDomain = [0.61, 1.45];
  const colorRange = optionB;
  const color = scaleLinear<string>()
    .domain(
      generateDomain(
        colorDomain[0] || 0,
        colorDomain[1] || 0,
        colorRange.length
      )
    )
    .range(colorRange)
    .clamp(true);

  const tooltip = (dataPoint: Shot) => {
    const cst = complexShotTypeMap[dataPoint.complexShotType];
    return (
      <div>
        {`${dataPoint.made ? "Made" : "Missed"} ${
          dataPoint.shooter ? " by " + dataPoint.shooter : ""
        } at Q${dataPoint.period} ${gameClockFormat(dataPoint.gameClock)}`}
        <br />
        {`${cst ? cst.label : "Unknown Shot"} from ${Math.round(
          dataPoint.shotDist
        )}ft`}
        <br />
        {`${dataPoint.fouled ? "Fouled" : ""}`}
      </div>
    );
  };

  const handleClick = () => {
    if (tooltipData) {
      window.open(`/shot/${tooltipData.shotId}`, "_blank");
    }
  };

  const {
    tooltipData,
    tooltipLeft = 0,
    tooltipTop = 0,
    showTooltip,
    hideTooltip,
  } = useTooltip<Shot>();

  const tooltipStyles = {
    ...defaultStyles,
    minWidth: 60,
    color: "black",
  };

  const handleTooltip = useCallback(
    (event: EventType) => {
      const { x, y } = localPoint(event) || { x: 0, y: 0 };
      for (const shot of shots) {
        const shotX = xScale(shot.shotClock || 0) + margin.left;
        const shotY = yScale(shot.epps) + margin.top;
        if (
          Math.abs(shotX - x) < circleRadius &&
          Math.abs(shotY - y) < circleRadius
        ) {
          showTooltip({
            tooltipData: shot,
            tooltipLeft: shotX,
            tooltipTop: shotY,
          });
          return;
        }
      }
      hideTooltip();
    },
    [shots, xScale]
  );

  const renderShotHelper = (
    s: Shot,
    index: number,
    highlight: boolean,
    circleRadius: number
  ) => {
    const key = highlight ? `circle-${index}` : "fo-" + index;
    const pointColor = rgb(color(s.epps));
    if (!highlight) {
      pointColor.opacity = 0.05;
    }
    let radius = circleRadius;
    if (highlight) {
      radius =
        tooltipData && tooltipData.shotId === s.shotId
          ? circleRadius * 1.5
          : circleRadius;
    }
    const cx = xScale(s.shotClock || 0);
    const cy = yScale(s.epps);

    if (s.made) {
      return renderMadeShot(s, key, pointColor.toString(), radius, cx, cy);
    } else {
      return renderMissedShot(s, key, pointColor.toString(), radius, cx, cy);
    }
  };

  const renderMadeShot = (
    s: Shot,
    key: string,
    pointColor: string,
    radius: number,
    cx: number,
    cy: number
  ) => {
    return [
      s.fouled ? (
        <circle
          key={key + 0}
          cx={cx}
          cy={cy}
          r={radius + 2.5}
          fill={"rgba(255,255,255,0.5)"}
          stroke={pointColor}
        />
      ) : null,
      <circle
        key={key}
        cx={cx}
        cy={cy}
        r={radius}
        fill={pointColor}
        stroke={rgb(pointColor).darker(0.5).toString()}
        strokeWidth={0.5}
      />,
    ];
  };

  const renderMissedShot = (
    s: Shot,
    key: string,
    pointColor: string,
    radius: number,
    cx: number,
    cy: number
  ) => {
    if (s.fouled) {
      return [
        <circle
          key={key + 1}
          cx={cx}
          cy={cy}
          r={radius + 2.5}
          fill={pointColor}
          stroke={rgb(pointColor).darker(0.5).toString()}
          strokeWidth={0.5}
        />,
        <circle
          key={key + 2}
          cx={cx}
          cy={cy}
          r={radius}
          fill={"rgba(255,255,255,1)"}
          stroke={rgb(pointColor).darker(0.5).toString()}
          strokeWidth={0.5}
        />,
      ];
    }

    const w = radius * 2;
    const h = radius / 2;
    return (
      <g transform={`translate(${cx} ${cy}) rotate(45 0 0)`} key={key}>
        <circle
          cx={cx}
          cy={cy}
          r={radius}
          fill={"rgba(0,0,0,0)"}
          stroke={"none"} // For ease of clicking.
        />
        <rect
          x={-w / 2}
          y={-h / 2}
          width={w}
          height={h}
          fill={pointColor}
          stroke={rgb(pointColor).darker(0.5).toString()}
          strokeWidth={1}
        />
        <rect
          x={-h / 2}
          y={-w / 2}
          width={h}
          height={w}
          fill={pointColor}
          stroke={rgb(pointColor).darker(0.5).toString()}
          strokeWidth={1}
        />
        <rect x={-w / 2} y={-h / 2} width={w} height={h} fill={pointColor} />
        <rect x={-h / 2} y={-w / 2} width={h} height={w} fill={pointColor} />
      </g>
    );
  };

  return (
    <div style={{ position: "relative" }}>
      <svg width={width} height={height}>
        <Group left={margin.left} top={margin.top}>
          <AxisLeft
            scale={yScale}
            label={"xPPS"}
            labelProps={{ fill: AXIS_COLOR, textAnchor: "middle" }}
            labelOffset={20}
            tickLabelProps={() => ({
              fill: AXIS_COLOR,
              textAnchor: "end",
              dy: "0.25em",
            })}
            stroke={AXIS_COLOR}
            tickStroke={AXIS_COLOR}
            tickValues={[0, 0.5, 1.0, 1.5, 2.0]}
            tickFormat={(v) => (tooltipData ? "" : decFormat(v as number))}
          />
          <AxisBottom
            scale={xScale}
            top={innerHeight}
            label={"Shot Clock"}
            labelProps={{ fill: AXIS_COLOR, textAnchor: "middle" }}
            labelOffset={20}
            tickLabelProps={() => ({
              fill: AXIS_COLOR,
              textAnchor: "middle",
              alignmentBaseline: "middle",
            })}
            stroke={AXIS_COLOR}
            tickStroke={AXIS_COLOR}
            tickValues={[24, 20, 16, 12, 8, 4, 0]}
            tickFormat={(v) => (tooltipData ? "" : v.toString())}
          />
          {filteredOutShots &&
            filteredOutShots.map((s, index) => {
              return renderShotHelper(s, index, false, circleRadius);
            })}
          {tooltipData && (
            <>
              <Text
                x={-margin.left + 12}
                y={tooltipTop - margin.top / 2}
                fill={AXIS_COLOR}
              >
                {decFormat2(tooltipData.epps)}
              </Text>
              <Line
                from={{ x: 0, y: tooltipTop - margin.top }}
                to={{ x: innerWidth, y: tooltipTop - margin.top }}
                stroke={AXIS_COLOR}
              />
              <Text
                textAnchor="middle"
                x={tooltipLeft - margin.left}
                y={innerHeight + margin.bottom / 2}
                fill={AXIS_COLOR}
              >
                {decFormat(tooltipData.shotClock || 0)}
              </Text>
              <Line
                from={{ x: tooltipLeft - margin.left, y: 0 }}
                to={{ x: tooltipLeft - margin.left, y: innerHeight }}
                stroke={AXIS_COLOR}
              />
            </>
          )}
          {shots.map((s, i) => {
            return renderShotHelper(s, i, true, circleRadius);
          })}
          {avgPPSByShotClockLeague.length && (
            <LinePath
              data={avgPPSByShotClockLeague}
              x={(d) => xScale(d.x)}
              y={(d) => yScale(d.y)}
              stroke={AXIS_COLOR}
              strokeDasharray={"2,2"}
              opacity={0.7}
            />
          )}
          {avgPPSByShotClockTeam.length && (
            <LinePath
              data={avgPPSByShotClockTeam}
              x={(d) => xScale(d.x)}
              y={(d) => yScale(d.y)}
              stroke={teamColor}
              strokeWidth={2}
              strokeDasharray={"2,2"}
              opacity={0.7}
            />
          )}
          <rect
            width={innerWidth}
            height={innerHeight}
            onTouchStart={handleTooltip}
            fill={"transparent"}
            onTouchMove={handleTooltip}
            onMouseMove={handleTooltip}
            onMouseLeave={() => hideTooltip()}
            onClick={() => handleClick()}
          />
        </Group>
      </svg>
      {tooltipData && (
        <TooltipWithBounds
          top={tooltipTop}
          left={tooltipLeft}
          style={tooltipStyles}
        >
          {tooltip(tooltipData)}
        </TooltipWithBounds>
      )}
    </div>
  );
}
