import React from "react";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import {
  useQueryParams,
  StringParam,
  withDefault,
  ArrayParam,
  QueryParamConfig,
} from "use-query-params";

import AppContext from "../../shared/AppContext";
import { NbaDates } from "../../shared/NbaDates";
import { Page } from "../components/core/Page";
import { Panel } from "../components/core/Panel";
import { TeamRankingsTable } from "../components/team/TeamRankingsTable";
import { NbaSeasonDateRangePicker } from "../components/date/NbaSeasonDateRangePicker";
import { Spinner } from "../components/core/Spinner";
import { trpc } from "../util/tRPC";
import { Restrict } from "../components/core/Restrict";

const SELECTED_GROUPS_DEFAULT = [
  "xPPP",
  "PPP",
  "efg",
  "turnrate",
  "orb",
  "fta",
  "pps",
  "xpps",
];

const curDates = NbaDates[AppContext.currentSeason];

const START_DEFAULT = curDates
  ? AppContext.inPreseason
    ? curDates.preseason.start
    : curDates.season.start
  : "";

const END_DEFAULT = curDates
  ? AppContext.inPreseason
    ? curDates.preseason.end
    : curDates.season.end
  : "";

const PRE_DEFAULT = AppContext.inPreseason ? "1" : "0";

const POST_DEFAULT = "0";

export function LeaguePage() {
  const [queryParams, setQueryParams] = useQueryParams({
    start: withDefault(StringParam, moment(START_DEFAULT).format("YYYY-MM-DD")),
    end: withDefault(StringParam, moment(END_DEFAULT).format("YYYY-MM-DD")),
    pre: withDefault(StringParam, PRE_DEFAULT),
    post: withDefault(StringParam, POST_DEFAULT),
    selectedGroups: withDefault(
      ArrayParam,
      SELECTED_GROUPS_DEFAULT
    ) as QueryParamConfig<string[]>,
  });
  const { start, end, pre, post, selectedGroups } = queryParams;

  const groups: Record<string, string> = {
    xPPP: "Expected Efficiency",
    PPP: "Efficiency",
    efg: "EFG%",
    crashers: "Crashers/Shot",
    turnrate: "TO%",
    xTov: "xTO%",
    orb: "OR%",
    xOrb: "xOR%",
    fta: "FTA Rate",
    pps: "PPS",
    xpps: "xPPS (Shot Quality)",
    xppsLg: "xPPS Lg (Shot Difficulty)",
    pace: "Pace",
    "2pct": "2P%",
    "3pct": "3P%",
    "3papct": "3PA%",
    ftpct: "FT%",
    astfgmpct: "AST/FGM%",
    stl: "STL%",
    blk: "BLK%",
  };

  const { data: rankings } = trpc.league.getLeagueTeamRankingsByDate.useQuery({
    startDate: start,
    endDate: end,
    includePreseason: pre,
    includePostseason: post,
  });

  const dataFilterChange = (
    dates: { start: string; end: string },
    pre: boolean,
    post: boolean
  ) => {
    setQueryParams({
      start: dates.start,
      end: dates.end,
      pre: pre ? "1" : "0",
      post: post ? "1" : "0",
    });
  };

  // Handle a checkbox being checked/un-checked.
  const selectedGroupChange = (group: string) => {
    if (!selectedGroups) return;
    const newSelectedGroups = [...selectedGroups];
    const idx = selectedGroups.findIndex((sg) => sg === group);
    if (idx < 0) {
      newSelectedGroups.push(group);
    } else {
      newSelectedGroups.splice(idx, 1);
    }

    setQueryParams({ selectedGroups: newSelectedGroups });
  };

  return (
    <Page header={{ component: <LeaguePageHeader /> }} title="League Overview">
      <Row>
        <Col lg={12}>
          <Panel header="League Stats Rankings">
            <div>
              <NbaSeasonDateRangePicker
                start={start}
                end={end}
                dataFilterChange={dataFilterChange}
                preseason={pre === "1"}
                postseason={post === "1"}
                selectedGroups={selectedGroups}
                selectedGroupChange={selectedGroupChange}
                groups={groups}
              />
              {!rankings && <Spinner />}
              {rankings && (
                <TeamRankingsTable
                  selectedGroupSet={new Set(selectedGroups)}
                  data={rankings}
                />
              )}
            </div>
          </Panel>
        </Col>
      </Row>
    </Page>
  );
}

function LeaguePageHeader() {
  const { data: freshnessData } = trpc.league.getLeagueDataFreshness.useQuery();

  let unprocessedMsg: JSX.Element | undefined = undefined;
  let lastUpdatedMsg = ``;
  if (freshnessData && freshnessData.length > 0) {
    const firstRow = freshnessData[0];
    if (firstRow) {
      const lastUpdated = moment(firstRow.gamedate).format("MMM D, YYYY");

      const unprocessed = freshnessData.reduce(
        (acc, cur) => acc + cur.unprocessed,
        0
      );

      lastUpdatedMsg += `League data reflects games played through ${lastUpdated}.`;

      if (unprocessed > 0) {
        unprocessedMsg = (
          <div>
            <b>BIA ADMIN ONLY:</b> {unprocessed} recent games had issues
            processing
          </div>
        );
      }
    }
  }

  return (
    <div>
      <h1>League Overview</h1>
      {freshnessData !== undefined && (
        <div style={{ fontWeight: "lighter" }}>
          {lastUpdatedMsg}{" "}
          <Restrict roles={["admin"]}>{unprocessedMsg}</Restrict>
        </div>
      )}
    </div>
  );
}
