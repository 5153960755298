import React, { useMemo, useState } from "react";

import { CrashAttempt } from "../../../shared/routers/GameRouter";
import { Table, createColumnHelper } from "../core/Table";
import { VideoModal } from "../video/VideoModal";
import { groupBy } from "../../../shared/util/Collections";
import { decFormat, makePlusMinus, pctFormat } from "../../util/Format";
import { VideoControl } from "../query/VideoControl";
import { TableNote } from "../core/TableNote";
import { sumFromField } from "../../util/Util";

interface CrashingTableRow {
  location: string;
  crashRate: number | null;
  crashRateVsExpected: number | null;
  numCrashed: number;
  xNumCrashed: number | null;
  numGetBack: number;
  numStuck: number;
  numShooter: number;
  total: number;
}

const columnHelper = createColumnHelper<CrashingTableRow>();

export function CrashingTable(props: { data: CrashAttempt[] }) {
  const { data } = props;

  const [clips, setClips] = useState<{ label: string; url: string }[]>();
  const [title, setTitle] = useState("Crashing Clips");

  const hasVideo = data.some((d) => d.url);

  const dataGroupedByLocation = groupBy(data, (d) => d.location || "");

  const rowData = ["Corner", "Inside the Arc", "Above the Break"].map((loc) => {
    const value = dataGroupedByLocation[loc] || [];
    const numShooter = value.filter((x) => x.isShooter).length;
    const nonShooterOpps = value.filter((x) => !x.isShooter);
    const numCrashed = nonShooterOpps.filter(
      (x) => x.action === "Crashed"
    ).length;
    const numGetBack = nonShooterOpps.filter(
      (x) => x.action === "Got Back"
    ).length;
    const numStuck = nonShooterOpps.filter((x) => x.action === "Stuck").length;
    const total = value.length;

    const predictedNumOfCrashes = sumFromField(
      "crashPrediction",
      nonShooterOpps
    );

    // Don't include opps where the player was the shooter.
    const crashRate =
      numCrashed + numStuck + numGetBack === 0
        ? null
        : numCrashed / (numCrashed + numStuck + numGetBack);

    const crashRateVsExpected =
      crashRate === null || predictedNumOfCrashes === null
        ? null
        : crashRate -
          predictedNumOfCrashes / (numCrashed + numStuck + numGetBack);

    return {
      location: loc,
      crashRate,
      crashRateVsExpected,
      xNumCrashed: predictedNumOfCrashes,
      numCrashed,
      numGetBack,
      numStuck,
      numShooter,
      total,
    };
  });

  const columns = useMemo(() => {
    let g = 0;

    const crashAttemptsToConsider = data.filter((d) => !d.isShooter);

    const denominator = crashAttemptsToConsider.length;
    const predictedNumOfCrashes = sumFromField(
      "crashPrediction",
      crashAttemptsToConsider
    );

    const totalCrashRate =
      denominator === 0
        ? null
        : crashAttemptsToConsider.filter((c) => c.action === "Crashed").length /
          denominator;

    const totalCrashRateVsExpected =
      totalCrashRate === null || predictedNumOfCrashes === null
        ? null
        : totalCrashRate - predictedNumOfCrashes / denominator;

    return [
      columnHelper.accessor("location", {
        header: () => "",
        footer: () => <b>Total</b>,
        meta: { group: g++, textAlign: "left" },
      }),
      columnHelper.accessor("crashRate", {
        header: () => "Crash %",
        cell: (info) => pctFormat(info.getValue()),
        footer: () => pctFormat(totalCrashRate),
        meta: { group: g++ },
      }),
      columnHelper.accessor("crashRateVsExpected", {
        header: () => "vs Expected",
        cell: (info) => makePlusMinus(pctFormat)(info.getValue()),
        footer: () => makePlusMinus(pctFormat)(totalCrashRateVsExpected),
        meta: { group: g++, heatmap: true, colorDomain: [-0.2, 0.2] },
      }),
      columnHelper.accessor("xNumCrashed", {
        header: () => "xCrashed",
        cell: (info) => decFormat(info.getValue()),
        footer: () => decFormat(predictedNumOfCrashes),
        meta: { group: g++ },
      }),
      columnHelper.accessor("numCrashed", {
        header: () => "Crashed",
        cell: (info) => {
          const clips = data.filter(
            (d) =>
              d.action === "Crashed" &&
              !d.isShooter &&
              d.location === info.row.original.location
          );

          if (clips.filter(crashAttemptHasUrl).length === 0)
            return clips.length;

          return (
            <a
              href="#"
              onClick={() => {
                setTitle(`Crashed from ${info.row.original.location}`);
                setClips(
                  clips.filter(crashAttemptHasUrl).map(crashAttemptToClip)
                );
              }}
            >
              {info.getValue()}
            </a>
          );
        },
        footer: () => {
          const clips = data.filter(
            (d) => d.action === "Crashed" && !d.isShooter
          );
          if (clips.filter(crashAttemptHasUrl).length === 0)
            return clips.length;

          return (
            <a
              href="#"
              onClick={() => {
                setTitle(`Crashed`);
                setClips(
                  clips.filter(crashAttemptHasUrl).map(crashAttemptToClip)
                );
              }}
            >
              {clips.length}
            </a>
          );
        },
        meta: { group: g++ },
      }),
      columnHelper.accessor("numGetBack", {
        header: () => "Got Back",
        cell: (info) => {
          const clips = data.filter(
            (d) =>
              d.action === "Got Back" &&
              !d.isShooter &&
              d.location === info.row.original.location
          );
          if (clips.filter(crashAttemptHasUrl).length === 0)
            return clips.length;

          return (
            <a
              href="#"
              onClick={() => {
                setTitle(`Got Back from ${info.row.original.location}`);
                setClips(
                  clips.filter(crashAttemptHasUrl).map(crashAttemptToClip)
                );
              }}
            >
              {info.getValue()}
            </a>
          );
        },
        footer: () => {
          const clips = data.filter(
            (d) => d.action === "Got Back" && !d.isShooter
          );
          if (clips.filter(crashAttemptHasUrl).length === 0)
            return clips.length;

          return (
            <a
              href="#"
              onClick={() => {
                setTitle(`Got Back`);
                setClips(
                  clips.filter(crashAttemptHasUrl).map(crashAttemptToClip)
                );
              }}
            >
              {clips.length}
            </a>
          );
        },
        meta: { group: g++ },
      }),
      columnHelper.accessor("numStuck", {
        header: () => "Stuck",
        cell: (info) => {
          const clips = data.filter(
            (d) =>
              d.action === "Stuck" &&
              !d.isShooter &&
              d.location === info.row.original.location
          );
          if (clips.filter(crashAttemptHasUrl).length === 0)
            return clips.length;

          return (
            <a
              href="#"
              onClick={() => {
                setTitle(`Stuck ${info.row.original.location}`);
                setClips(
                  clips.filter(crashAttemptHasUrl).map(crashAttemptToClip)
                );
              }}
            >
              {info.getValue()}
            </a>
          );
        },
        footer: () => {
          const clips = data.filter(
            (d) => d.action === "Stuck" && !d.isShooter
          );
          if (clips.filter(crashAttemptHasUrl).length === 0)
            return clips.length;

          return (
            <a
              href="#"
              onClick={() => {
                setTitle(`Stuck`);
                setClips(
                  clips.filter(crashAttemptHasUrl).map(crashAttemptToClip)
                );
              }}
            >
              {clips.length}
            </a>
          );
        },
        meta: { group: g++ },
      }),
      columnHelper.accessor("numShooter", {
        header: () => "Shooter",
        cell: (info) => {
          const clips = data.filter(
            (d) => d.isShooter && d.location === info.row.original.location
          );
          if (clips.filter(crashAttemptHasUrl).length === 0)
            return clips.length;

          return (
            <a
              href="#"
              onClick={() => {
                setTitle(`Shooter ${info.row.original.location}`);
                setClips(
                  clips.filter(crashAttemptHasUrl).map(crashAttemptToClip)
                );
              }}
            >
              {info.getValue()}
            </a>
          );
        },
        footer: () => {
          const clips = data.filter((d) => d.isShooter);
          if (clips.filter(crashAttemptHasUrl).length === 0)
            return clips.length;

          return (
            <a
              href="#"
              onClick={() => {
                setTitle(`Shooter`);
                setClips(
                  clips.filter(crashAttemptHasUrl).map(crashAttemptToClip)
                );
              }}
            >
              {clips.length}
            </a>
          );
        },
        meta: { group: g++ },
      }),
      columnHelper.accessor("total", {
        header: () => "Video",
        cell: (info) => {
          const clips = data.filter(
            (d) => d.location === info.row.original.location
          );
          if (clips.filter(crashAttemptHasUrl).length === 0) return null;

          return (
            <VideoControl
              data={null}
              onVideo={() => {
                setTitle(`${info.row.original.location}`);
                setClips(
                  clips.filter(crashAttemptHasUrl).map(crashAttemptToClip)
                );
              }}
            />
          );
        },
        footer: () => {
          if (data.filter(crashAttemptHasUrl).length === 0) return null;

          return (
            <VideoControl
              data={null}
              onVideo={() => {
                setTitle(`All Locations`);
                setClips(
                  data.filter(crashAttemptHasUrl).map(crashAttemptToClip)
                );
              }}
            />
          );
        },
        meta: { group: g++ },
      }),
    ];
  }, [data]);

  return (
    <>
      <Table
        data={rowData}
        columns={columns}
        hiddenColumns={{ total: hasVideo }}
        showRowIndex={false}
        autoWidth={true}
      />
      <TableNote
        note={`vs Expected represents the difference in crash rate compared to the
        league average crash rate from that location.`}
      />
      <VideoModal
        clips={clips || []}
        title={title}
        show={!!clips}
        handleClose={() => setClips(undefined)}
        upDownClipSkip={true}
        showSynergyEditor={true}
      />
    </>
  );
}

function crashAttemptToClip(crashAttempt: CrashAttempt): {
  label: string;
  url: string;
} {
  const action = crashAttempt.isShooter ? "Shooter" : crashAttempt.action;
  return {
    label: `${crashAttempt.player} - ${action} - ${crashAttempt.location}`,
    url: crashAttempt.url || "",
  };
}

function crashAttemptHasUrl(crashAttempt: CrashAttempt): boolean {
  return !!crashAttempt.url;
}
