import React, { useContext, useState } from "react";
import { Row, Col } from "react-bootstrap";

import {
  TeamStats,
  TeamRosterGameBoxesPlayer,
} from "../../../shared/routers/TeamRouter";
import { TeamImpact } from "../../../shared/routers/ImpactRouter";
import { RosterPlayer } from "../../../shared/routers/RosterRouter";
import { Panel } from "../../components/core/Panel";
import { Spinner } from "../../components/core/Spinner";
import { TeamDepthChart } from "../../components/team/TeamDepthChart";
import { TeamRosterImpact } from "../../components/team/TeamRosterImpact";
import { TeamStatRankings } from "../../components/team/TeamStatRankings";
import { TeamRosterSeasonBoxes } from "../../components/team/TeamRosterSeasonBoxes";
import { groupBy } from "../../../shared/util/Collections";
import { TeamContext } from "../../TeamContext";
import { useWindowSize } from "../../util/Hooks";

export function TeamSubPageOverview(props: {
  teamId: number;
  teamStats?: TeamStats[];
  leagueStats?: TeamStats[];
  rosteredPlayers?: RosterPlayer[];
  impact?: TeamImpact[];
  playerBoxes?: TeamRosterGameBoxesPlayer[];
  shouldShowFilterMsg: boolean;
  filtered: boolean;
}) {
  const {
    teamId,
    teamStats,
    leagueStats,
    rosteredPlayers,
    impact,
    playerBoxes,
    shouldShowFilterMsg,
    filtered,
  } = props;

  const teamRoster = (rosteredPlayers || []).filter((p) => p.teamid === teamId);

  return (
    <div>
      <Row>
        <Col md={12}>
          <StatRankingsPanel
            teamId={teamId}
            teamStats={teamStats}
            leagueStats={leagueStats}
            shouldShowFilterMsg={shouldShowFilterMsg}
            filtered={filtered}
          />
        </Col>
        <Col md={12}>
          {teamRoster && <DepthChartPanel teamRoster={teamRoster} />}
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <BoxScoresPanel
            teamStats={teamStats}
            playerBoxes={playerBoxes}
            filtered={filtered}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Panel header="Impact" panelID="impact">
            <TeamRosterImpact data={impact} />
          </Panel>
        </Col>
      </Row>
    </div>
  );
}

function StatRankingsPanel(props: {
  teamId: number;
  teamStats?: TeamStats[];
  leagueStats?: TeamStats[];
  shouldShowFilterMsg: boolean;
  filtered: boolean;
}) {
  const { teamId, teamStats, leagueStats, shouldShowFilterMsg, filtered } =
    props;
  const [showFilterMsg, setShowFilterMsg] = useState<boolean>(false);

  const teams = useContext(TeamContext);
  const { width } = useWindowSize();

  const statRankingOrientation = width < 1200 ? "tall" : "wide";

  if (shouldShowFilterMsg !== showFilterMsg) {
    setShowFilterMsg(shouldShowFilterMsg);
  }

  let content = <Spinner />;

  if (leagueStats !== undefined && teamStats !== undefined) {
    // Filter out the team's row from leagueStats and instead use the row from
    // teamStats.
    const thisTeamStats = teamStats[0];
    const data = leagueStats.filter((t) => t.teamId !== teamId);
    if (thisTeamStats) {
      data.push(thisTeamStats);
    }

    content = (
      <TeamStatRankings
        orientation={statRankingOrientation}
        teamId={teamId}
        leagueData={data}
      />
    );
  }

  const team = teams && teams.find((t) => t.teamid === teamId);
  const filterMsg = `Note: Rankings are comparing ${
    team ? team.teamshortname : "the team"
  }'s performance in selected games against the rest of the leagues performance for the entire season.`;

  return (
    <Panel header="Stat Rankings" panelID="statRankings" filtered={filtered}>
      <>
        {showFilterMsg && (
          <div style={{ color: "#888", marginBottom: 8 }}>{filterMsg}</div>
        )}
        {content}
      </>
    </Panel>
  );
}

function DepthChartPanel(props: { teamRoster: RosterPlayer[] }) {
  const { width } = useWindowSize();
  const depthChartOrientation = width < 992 ? "tall" : "wide";

  const { teamRoster } = props;
  return (
    <Panel header="Depth Chart" panelID="depthChart">
      {teamRoster && (
        <TeamDepthChart
          showBottomBorder={false}
          orientation={depthChartOrientation}
          data={groupBy(teamRoster, (p) => p.Role?.toString())}
        />
      )}
    </Panel>
  );
}

function BoxScoresPanel(props: {
  teamStats?: TeamStats[];
  playerBoxes?: TeamRosterGameBoxesPlayer[];
  filtered: boolean;
}) {
  const { teamStats, playerBoxes, filtered } = props;

  const targetTeam = teamStats && teamStats[0];
  const dataReady = playerBoxes && targetTeam;

  return (
    <Panel header="Box Scores" panelID="boxScores" filtered={filtered}>
      {dataReady && (
        <TeamRosterSeasonBoxes
          data={{ roster: playerBoxes }}
          teamStats={targetTeam}
          showRosterToggle={true}
        />
      )}
    </Panel>
  );
}
