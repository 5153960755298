import React, { useContext, useMemo, useState } from "react";
import {
  useQueryParams,
  withDefault,
  StringParam,
  ArrayParam,
} from "use-query-params";
import { Button, Row, Col, Form } from "react-bootstrap";

import {
  Table,
  createColumnHelper,
  SortingState,
} from "../components/core/Table";
import { Page } from "../components/core/Page";
import { Panel } from "../components/core/Panel";
import { MultiSelect } from "../components/core/MultiSelect";
import { trpc } from "../util/tRPC";
import {
  PlayerMatchup,
  PlayerMatchupPlayer,
} from "../../shared/routers/MatchupRouter";
import { PlayerTableCell } from "../components/core/TableCell";
import {
  dec100Format,
  decFormat2,
  intFormat,
  makePlusMinus,
  seasonString,
} from "../util/Format";
import { Highlights } from "../constants/AppConstants";
import { PlayerPhoto } from "../components/player/PlayerPhoto";
import { reduceArrayToObject } from "../../shared/util/Collections";
import { TeamContext } from "../TeamContext";
import { VideoControl } from "../components/query/VideoControl";
import { VideoModal } from "../components/video/VideoModal";

type PlayerMatchupRow = PlayerMatchupPlayer & {
  defPlayerId: number;
  offPlayerId: number;
  matchup_defensive_impact: number | null;
  matchup_impact_diff: number | null;
  n_poss: number | null;
  ppp: number | null;
  xppp: number | null;
};

interface LineupMatchupImpact {
  offPlayer1: string;
  offPlayer2: string;
  offPlayer3: string;
  offPlayer4: string;
  offPlayer5: string;
  defPlayer1: string;
  defPlayer2: string;
  defPlayer3: string;
  defPlayer4: string;
  defPlayer5: string;
  impact: number;
}

const columnHelper = createColumnHelper<PlayerMatchupRow>();
const lineupMatchupColumnHelper = createColumnHelper<LineupMatchupImpact>();

export function PlayerMatchupPage() {
  const [queryParams, setQueryParams] = useQueryParams({
    teamId: withDefault(StringParam, ""),
    selectedPlayerId: withDefault(StringParam, ""),
    // For panel 2.
    offTeamId: withDefault(StringParam, "1610612747"),
    defTeamId: withDefault(StringParam, "1610612738"),
    offPlayerIds: withDefault(ArrayParam, []),
    defPlayerIds: withDefault(ArrayParam, []),
  });
  const { teamId, selectedPlayerId, offTeamId, defTeamId } = queryParams;
  const [sorting, setSorting] = useState<SortingState>();
  const [lineupSorting, setLineupSorting] = useState<SortingState>();
  const [defPlayer, setDefPlayer] = useState<{
    playerId: string;
    player: string;
  }>();

  const offPlayerIds = queryParams.offPlayerIds as unknown as string[];
  const defPlayerIds = queryParams.defPlayerIds as unknown as string[];

  const teams = useContext(TeamContext);

  const { data: players } = trpc.matchup.getPlayerMatchupPlayers.useQuery();

  const { data: clips } = trpc.matchup.getMatchupClips.useQuery({
    offPlayerId: selectedPlayerId,
    defPlayerId: defPlayer ? defPlayer.playerId : undefined,
  });

  const playerMap = useMemo(() => {
    return reduceArrayToObject(players || [], (p) => p.playerId.toString());
  }, [players]);

  const { data: matchupData } = trpc.matchup.getPlayerMatchups.useQuery({
    offPlayerId: selectedPlayerId || undefined,
    defTeamId: teamId || undefined,
  });

  const { data: allLineupMatchupData } =
    trpc.matchup.getPlayerMatchupsForLineups.useQuery(
      {
        offPlayerIds,
        defPlayerIds,
      },
      {
        enabled: offPlayerIds.length === 5 && defPlayerIds.length === 5,
      }
    );

  const teamPlayers = (players || []).filter(
    (p) => teamId === "" || p.teamId.toString() === teamId
  );

  const sortedOffPlayers = offPlayerIds.sort((a, b) => {
    const playerA = playerMap[a];
    const playerB = playerMap[b];
    if (playerA && playerB) {
      if (playerA.Role !== playerB.Role)
        return playerA.Role > playerB.Role ? 1 : -1;
      return playerA.name > playerB.name ? 1 : -1;
    }
    return 0;
  });

  const lineupMatchupRows = rankMatchups(
    sortedOffPlayers,
    defPlayerIds,
    allLineupMatchupData
  );

  const matchupImpactMap = reduceArrayToObject(
    allLineupMatchupData || [],
    (d) => d.offPlayerId + "-" + d.defPlayerId
  );

  const lineupColumns = useMemo(() => {
    if (
      !playerMap ||
      sortedOffPlayers.length !== 5 ||
      defPlayerIds.length !== 5
    )
      return [];

    function getPlayerName(playerId: string | undefined) {
      if (!playerId) return "Unknown";
      const playerFromMap = playerMap[playerId];
      if (!playerFromMap) return "Unknown";
      return playerFromMap.name;
    }

    function getMatchupImpact(
      offPlayerId: string | undefined,
      defPlayerId: string | undefined
    ) {
      if (!offPlayerId || !defPlayerId) return 0;
      const mu = matchupImpactMap[offPlayerId + "-" + defPlayerId];
      return mu ? mu.matchup_defensive_impact || 0 : 0;
    }

    let g = 0;
    return [
      lineupMatchupColumnHelper.accessor("defPlayer1", {
        header: getPlayerName(sortedOffPlayers[0]),
        cell: (info) => (
          <PlayerTableCell
            id={info.row.original.defPlayer1}
            name={getPlayerName(info.getValue())}
          />
        ),
        meta: { group: g },
      }),
      lineupMatchupColumnHelper.accessor(
        (row) => getMatchupImpact(sortedOffPlayers[0], row.defPlayer1),
        {
          id: "impact1",
          header: "",
          cell: (info) => makePlusMinus(dec100Format)(info.getValue()),
          meta: {
            group: g++,
            highlights: Highlights.Max,
            colorDomain: [-0.01, 0.01],
          },
        }
      ),
      lineupMatchupColumnHelper.accessor("defPlayer2", {
        header: getPlayerName(sortedOffPlayers[1]),
        cell: (info) => (
          <PlayerTableCell
            id={info.row.original.defPlayer2}
            name={getPlayerName(info.getValue())}
          />
        ),
        meta: { group: g },
      }),
      lineupMatchupColumnHelper.accessor(
        (row) => getMatchupImpact(sortedOffPlayers[1], row.defPlayer2),
        {
          id: "impact2",
          header: "",
          cell: (info) => makePlusMinus(dec100Format)(info.getValue()),
          meta: {
            group: g++,
            highlights: Highlights.Max,
            colorDomain: [-0.01, 0.01],
          },
        }
      ),
      lineupMatchupColumnHelper.accessor("defPlayer3", {
        header: getPlayerName(sortedOffPlayers[2]),
        cell: (info) => (
          <PlayerTableCell
            id={info.row.original.defPlayer3}
            name={getPlayerName(info.getValue())}
          />
        ),
        meta: { group: g },
      }),
      lineupMatchupColumnHelper.accessor(
        (row) => getMatchupImpact(sortedOffPlayers[2], row.defPlayer3),
        {
          id: "impact3",
          header: "",
          cell: (info) => makePlusMinus(dec100Format)(info.getValue()),
          meta: {
            group: g++,
            highlights: Highlights.Max,
            colorDomain: [-0.01, 0.01],
          },
        }
      ),
      lineupMatchupColumnHelper.accessor("defPlayer4", {
        header: getPlayerName(sortedOffPlayers[3]),
        cell: (info) => (
          <PlayerTableCell
            id={info.row.original.defPlayer4}
            name={getPlayerName(info.getValue())}
          />
        ),
        meta: { group: g },
      }),
      lineupMatchupColumnHelper.accessor(
        (row) => getMatchupImpact(sortedOffPlayers[3], row.defPlayer4),
        {
          id: "impact4",
          header: "",
          cell: (info) => makePlusMinus(dec100Format)(info.getValue()),
          meta: {
            group: g++,
            highlights: Highlights.Max,
            colorDomain: [-0.01, 0.01],
          },
        }
      ),
      lineupMatchupColumnHelper.accessor("defPlayer5", {
        header: getPlayerName(sortedOffPlayers[4]),
        cell: (info) => (
          <PlayerTableCell
            id={info.row.original.defPlayer5}
            name={getPlayerName(info.getValue())}
          />
        ),
        meta: { group: g },
      }),
      lineupMatchupColumnHelper.accessor(
        (row) => getMatchupImpact(sortedOffPlayers[4], row.defPlayer5),
        {
          id: "impact5",
          header: "",
          cell: (info) => makePlusMinus(dec100Format)(info.getValue()),
          meta: {
            group: g++,
            highlights: Highlights.Max,
            colorDomain: [-0.01, 0.01],
          },
        }
      ),
      lineupMatchupColumnHelper.accessor("impact", {
        header: "Total Def Impact",
        cell: (info) => makePlusMinus(dec100Format)(info.getValue()),
        meta: {
          group: g++,
          highlights: Highlights.Max,
          colorDomain: [0, 0.025],
        },
      }),
    ];
  }, [sortedOffPlayers, defPlayerIds, playerMap, matchupImpactMap]);

  const data: PlayerMatchupRow[] = (teamPlayers || []).map((p) => {
    const matchupRow = (matchupData || []).find(
      (md) => md.defPlayerId === p.playerId
    ) || {
      matchup_defensive_impact: null,
      def_impact_no_apm: null,
      n_poss: null,
      xppp: null,
      ppp: null,
      defPlayerId: 0,
      offPlayerId: 0,
    };
    return {
      ...matchupRow,
      ...p,
      matchup_impact_diff:
        matchupRow.matchup_defensive_impact === null ||
        p.def_impact_no_apm === null
          ? null
          : matchupRow.matchup_defensive_impact - p.def_impact_no_apm,
    };
  });

  const columns = useMemo(() => {
    let g = 0;

    return [
      columnHelper.accessor("name", {
        header: () => "Player",
        cell: (info) => (
          <PlayerTableCell
            id={info.row.original.playerId}
            name={info.getValue()}
          />
        ),
        meta: { group: g++ },
      }),
      columnHelper.accessor("def_impact_no_apm", {
        header: "Def Impact",
        cell: (info) => makePlusMinus(dec100Format)(info.getValue()),
        meta: {
          group: g++,
          highlights: Highlights.Max,
          colorDomain: [-0.02, 0.02],
        },
      }),
      columnHelper.group({
        id: "matchup",
        header: "Predicted vs Player",
        meta: { group: g },
        columns: [
          columnHelper.accessor("matchup_defensive_impact", {
            header: "Impact",
            cell: (info) => makePlusMinus(dec100Format)(info.getValue()),
            meta: {
              group: g,
              colorDomain: [-0.015, 0.015],
              highlights: Highlights.Max,
            },
          }),
          columnHelper.accessor("matchup_impact_diff", {
            header: "Diff",
            cell: (info) => makePlusMinus(dec100Format)(info.getValue()),
            meta: {
              group: g++,
              highlights: Highlights.Max,
              colorDomain: [-0.005, 0.005],
            },
          }),
        ],
      }),
      columnHelper.group({
        id: "matchup",
        header: "Actual vs Player",
        meta: { group: g },
        columns: [
          columnHelper.accessor("n_poss", {
            header: "Num Poss",
            cell: (info) => intFormat(info.getValue()),
            meta: {
              group: g,
              highlights: Highlights.Max,
              neutralColorScheme: true,
              colorDomain: [0, 150],
            },
          }),
          columnHelper.accessor("xppp", {
            header: "xPPP",
            cell: (info) => decFormat2(info.getValue()),
            meta: {
              group: g,
              colorDomain: [1.2, 1.1],
              highlights: Highlights.Min,
            },
          }),
          columnHelper.accessor("ppp", {
            header: "PPP",
            cell: (info) => decFormat2(info.getValue()),
            meta: {
              group: g,
              colorDomain: [1.3, 1],
              highlights: Highlights.Min,
            },
          }),
          columnHelper.display({
            id: "video",
            header: "Video",
            cell: (info) =>
              info.row.original.n_poss === null ? (
                ""
              ) : (
                <VideoControl
                  onVideo={(defPlayer) => setDefPlayer(defPlayer)}
                  data={{
                    playerId: info.row.original.defPlayerId.toString(),
                    player: info.row.original.name,
                  }}
                />
              ),
          }),
        ],
      }),
    ];
  }, []);

  if (!players) return null;

  const selectedPlayer = playerMap[selectedPlayerId];

  return (
    <Page
      header={{ text: "Player Matchups [BIA ONLY - WIP]" }}
      title="Player Matchups"
    >
      <>
        <Row>
          <Col>
            <Panel header="Player Matchups">
              <div style={{ display: "flex", gap: 16, marginTop: 8 }}>
                <PlayerPhoto
                  playerId={parseInt(selectedPlayerId)}
                  ids={selectedPlayer && selectedPlayer.playerIdsId}
                />
                <div
                  style={{ display: "flex", flexDirection: "column", gap: 4 }}
                >
                  <div>
                    <Form.Label>Team</Form.Label>
                    <Form.Select
                      value={teamId}
                      onChange={(e) =>
                        setQueryParams({ teamId: e.target.value })
                      }
                    >
                      <option value="">All Teams</option>
                      <option disabled>──────────</option>
                      {teams.map((t) => (
                        <option key={t.teamid} value={t.teamid.toString()}>
                          {t.teamcity} {t.teamname}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                  <div>
                    <Form.Label>Best Matchups For</Form.Label>
                    <MultiSelect
                      values={players
                        .map((p) => {
                          return {
                            label: p.name,
                            value: p.playerId.toString(),
                          };
                        })
                        .sort((a, b) => (a.label > b.label ? 1 : -1))}
                      selected={selectedPlayerId ? [selectedPlayerId] : []}
                      onChange={(vals) => {
                        setQueryParams({
                          selectedPlayerId: vals.length ? vals[0] : undefined,
                        });
                      }}
                      limit={1}
                    />
                  </div>
                </div>
              </div>
              {data && data.length > 0 && (
                <Table
                  data={data.sort((a, b) => {
                    if (
                      a.matchup_defensive_impact === null &&
                      b.matchup_defensive_impact === null
                    ) {
                      return a.name > b.name ? 1 : -1;
                    } else if (a.matchup_defensive_impact === null) return 1;
                    else if (b.matchup_defensive_impact === null) return -1;
                    return a.matchup_defensive_impact >
                      b.matchup_defensive_impact
                      ? -1
                      : 1;
                  })}
                  columns={columns}
                  autoWidth={true}
                  showColorOnHover={true}
                  showRowIndex={false}
                  sorting={sorting}
                  setSorting={setSorting}
                />
              )}
              <VideoModal
                title={`${defPlayer && defPlayer.player} vs ${
                  selectedPlayer && selectedPlayer.name
                }`}
                show={clips !== undefined && clips.length > 0}
                clips={(clips || []).map((c) => {
                  return {
                    label: `xPTS: ${decFormat2(c.xPts)} (${seasonString(
                      c.season.toString()
                    )})`,
                    url: c.url,
                  };
                })}
                handleClose={() => {
                  setDefPlayer(undefined);
                }}
                upDownClipSkip={true}
                showSynergyEditor={false}
              />
            </Panel>
          </Col>
        </Row>
        <Row>
          <Col>
            <Panel header="Full Lineup Matchups">
              <div
                style={{
                  display: "flex",
                  gap: 8,
                  marginBottom: 24,
                  alignItems: "flex-end",
                }}
              >
                <div>
                  <Form.Label>Def Team</Form.Label>
                  <Form.Select
                    value={defTeamId}
                    onChange={(e) =>
                      setQueryParams({
                        defTeamId: e.target.value,
                        defPlayerIds: [],
                      })
                    }
                  >
                    {teams.map((t) => (
                      <option key={t.teamid} value={t.teamid.toString()}>
                        {t.teamcity} {t.teamname}
                      </option>
                    ))}
                  </Form.Select>
                </div>
                <Button
                  disabled={defTeamId === ""}
                  onClick={() =>
                    setQueryParams({
                      defPlayerIds: players
                        .filter(
                          (p) =>
                            p.teamId.toString() === defTeamId &&
                            p.Status === "STARTER"
                        )
                        .map((p) => p.playerId.toString()),
                    })
                  }
                >
                  Fill Starters
                </Button>
                <div>
                  <Form.Label>Def Players</Form.Label>
                  <MultiSelect
                    values={players
                      .filter((p) => p.teamId.toString() === defTeamId)
                      .map((p) => {
                        return {
                          label: p.name,
                          value: p.playerId.toString(),
                        };
                      })}
                    selected={defPlayerIds || []}
                    onChange={(vals) =>
                      setQueryParams({ defPlayerIds: vals || [] })
                    }
                    limit={5}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: 8,
                  marginBottom: 24,
                  alignItems: "flex-end",
                }}
              >
                <div>
                  <Form.Label>Off Team</Form.Label>
                  <Form.Select
                    value={offTeamId}
                    onChange={(e) =>
                      setQueryParams({
                        offTeamId: e.target.value,
                        offPlayerIds: [],
                      })
                    }
                  >
                    {teams.map((t) => (
                      <option key={t.teamid} value={t.teamid.toString()}>
                        {t.teamcity} {t.teamname}
                      </option>
                    ))}
                  </Form.Select>
                </div>
                <Button
                  disabled={offTeamId === ""}
                  onClick={() =>
                    setQueryParams({
                      offPlayerIds: players
                        .filter(
                          (p) =>
                            p.teamId.toString() === offTeamId &&
                            p.Status === "STARTER"
                        )
                        .map((p) => p.playerId.toString()),
                    })
                  }
                >
                  Fill Starters
                </Button>
                <div>
                  <Form.Label>Off Players</Form.Label>
                  <MultiSelect
                    values={players
                      .filter((p) => p.teamId.toString() === offTeamId)
                      .map((p) => {
                        return {
                          label: p.name,
                          value: p.playerId.toString(),
                        };
                      })}
                    selected={offPlayerIds || []}
                    onChange={(vals) =>
                      setQueryParams({ offPlayerIds: vals || [] })
                    }
                    limit={5}
                  />
                </div>
              </div>
              {offPlayerIds.length === 5 && defPlayerIds.length === 5 && (
                <Table
                  data={lineupMatchupRows}
                  columns={lineupColumns}
                  autoWidth={true}
                  showRowIndex={false}
                  sorting={lineupSorting}
                  setSorting={setLineupSorting}
                  showColorOnHover={true}
                />
              )}
            </Panel>
          </Col>
        </Row>
      </>
    </Page>
  );
}

function rankMatchups(
  offPlayerIds: string[],
  defPlayerIds: string[],
  data: PlayerMatchup[] | undefined
) {
  if (data === undefined || data.length === 0) return [];

  const matchupImpactMap = reduceArrayToObject(
    data,
    (d) => d.offPlayerId + "-" + d.defPlayerId
  );

  const permutations = permutator(defPlayerIds);
  return permutations
    .map((perm) => {
      const impact = perm
        .map((p, i) => {
          const mu = matchupImpactMap[offPlayerIds[i] + "-" + p];
          return mu ? mu.matchup_defensive_impact || 0 : 0;
        })
        .reduce((a, b) => a + b, 0);
      return {
        offPlayer1: offPlayerIds[0] || "",
        offPlayer2: offPlayerIds[1] || "",
        offPlayer3: offPlayerIds[2] || "",
        offPlayer4: offPlayerIds[3] || "",
        offPlayer5: offPlayerIds[4] || "",
        defPlayer1: perm[0] || "",
        defPlayer2: perm[1] || "",
        defPlayer3: perm[2] || "",
        defPlayer4: perm[3] || "",
        defPlayer5: perm[4] || "",
        impact,
      };
    })
    .sort((a, b) => b.impact - a.impact);
}
function permutator(inputArr: string[]) {
  const result: string[][] = [];

  const permute = (arr: string[], m: string[] = []) => {
    if (arr.length === 0) {
      result.push(m);
    } else {
      for (let i = 0; i < arr.length; i++) {
        const curr = arr.slice();
        const next = curr.splice(i, 1);
        permute(curr.slice(), m.concat(next));
      }
    }
  };

  permute(inputArr);

  return result;
}
