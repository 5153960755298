import React, { useContext, useCallback, useState, useMemo } from "react";
import moment from "moment";
import { ParentSize } from "@visx/responsive";
import { Row, Col } from "react-bootstrap";

import { Panel } from "../core/Panel";
import { Restrict } from "../core/Restrict";
import { LineChart } from "../chart/LineChart";
import { TeamStandings } from "../team/TeamStandings";
import { trpc } from "../../util/tRPC";
import { TeamContext } from "../../TeamContext";
import { useWindowSize } from "../../util/Hooks";
import { CELTICS_TEAM_ID } from "../../constants/AppConstants";
import { TEAM_COLORS } from "../../constants/ColorConstants";
import { dec100Format, plusMinusFormat2, pctFormat } from "../../util/Format";
import { TeamLogo } from "../team/TeamLogo";

type TeamRecordsByDate = Record<
  string,
  {
    date: string;
    wins: number;
    losses: number;
    ptsFor: number;
    ptsAgainst: number;
    numPoss: number;
  }[]
>;

type TeamChampionshipOddsByDate = Record<
  string,
  {
    date: string;
    pWinFinals: number;
  }[]
>;

export function NBAStandings() {
  const teams = useContext(TeamContext);

  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 768;

  const [hoveredEastId, setHoveredEastId] = useState<number | null>(null);
  const [hoveredWestId, setHoveredWestId] = useState<number | null>(null);

  const { data } = trpc.league.getNbaResultsByDate.useQuery();
  const { data: championshipData } =
    trpc.league.getNbaChampionshipProbByDate.useQuery();

  const teamChampionshipOddsByDate = useMemo(() => {
    if (!championshipData || teams.length === 0) return {};

    const retObj = teams.reduce((acc, team) => {
      acc[team.teamid] = [];
      return acc;
    }, {} as TeamChampionshipOddsByDate);

    for (const d of championshipData) {
      const teamEntry = retObj[d.teamId];
      if (teamEntry) {
        teamEntry.push({
          date: d.date,
          pWinFinals: d.p_win_finals,
        });
      }
    }

    return retObj;
  }, [championshipData, teams]);

  const teamRecordsByDate: TeamRecordsByDate = useMemo(() => {
    if (!data || teams.length === 0) return {};

    const retObj = teams.reduce((acc, team) => {
      acc[team.teamid] = [];
      return acc;
    }, {} as TeamRecordsByDate);

    for (const d of data) {
      const winner = retObj[d.winningTeamId];
      const loser = retObj[d.losingTeamId];

      if (winner === undefined || loser === undefined) continue;

      const winnerRecord = winner[winner.length - 1] || {
        wins: 0,
        losses: 0,
        numPoss: 0,
        ptsFor: 0,
        ptsAgainst: 0,
      };

      const loserRecord = loser[loser.length - 1] || {
        wins: 0,
        losses: 0,
        numPoss: 0,
        ptsFor: 0,
        ptsAgainst: 0,
      };

      winner.push({
        date: d.gameDate,
        wins: winnerRecord.wins + 1,
        losses: winnerRecord.losses,
        numPoss: winnerRecord.numPoss + d.numPoss,
        ptsFor: winnerRecord.ptsFor + d.winningTeamPts,
        ptsAgainst: winnerRecord.ptsAgainst + d.losingTeamPts,
      });

      loser.push({
        date: d.gameDate,
        wins: loserRecord.wins,
        losses: loserRecord.losses + 1,
        numPoss: loserRecord.numPoss + d.numPoss,
        ptsFor: loserRecord.ptsFor + d.losingTeamPts,
        ptsAgainst: loserRecord.ptsAgainst + d.winningTeamPts,
      });
    }

    return retObj;
  }, [data, teams]);

  const eastTeams = teams
    .filter((d) => d.Conference === "Eastern Conference")
    .sort((a, b) => (a.playoffRank > b.playoffRank ? 1 : -1));

  const westTeams = teams
    .filter((d) => d.Conference === "Western Conference")
    .sort((a, b) => (a.playoffRank > b.playoffRank ? 1 : -1));

  const eastRecordsByDate = Object.fromEntries(
    Object.entries(teamRecordsByDate).filter(([key]) => {
      return eastTeams.find((team) => team.teamid.toString() === key);
    })
  );

  const westRecordsByDate = Object.fromEntries(
    Object.entries(teamRecordsByDate).filter(([key]) => {
      return westTeams.find((team) => team.teamid.toString() === key);
    })
  );

  const eastChampionshipOddsByDate = Object.fromEntries(
    Object.entries(teamChampionshipOddsByDate).filter(([key]) => {
      return eastTeams.find((team) => team.teamid.toString() === key);
    })
  );

  const westChampionshipOddsByDate = Object.fromEntries(
    Object.entries(teamChampionshipOddsByDate).filter(([key]) => {
      return westTeams.find((team) => team.teamid.toString() === key);
    })
  );

  if (westTeams.length === 0 || eastTeams.length === 0) return null;

  const defaultWestTeam = westTeams[0];

  return (
    <Row>
      <Col lg={10} md={12}>
        <Panel header="NBA Eastern Conference">
          <Row>
            <Col>
              <TeamStandings
                data={eastTeams}
                setHoveredTeamId={setHoveredEastId}
                hoveredTeamId={hoveredEastId}
              />
            </Col>
            {!isMobile && (
              <Col>
                <Restrict roles={["bia"]}>
                  <StandingsOverTimeChart
                    data={eastRecordsByDate}
                    championshipData={eastChampionshipOddsByDate}
                    selectedTeam={(hoveredEastId || CELTICS_TEAM_ID).toString()}
                    setSelectedTeam={(team) => setHoveredEastId(parseInt(team))}
                  />
                </Restrict>
              </Col>
            )}
          </Row>
        </Panel>
        <Panel header="NBA Western Conference">
          <Row>
            <Col>
              <TeamStandings
                data={westTeams}
                setHoveredTeamId={setHoveredWestId}
                hoveredTeamId={hoveredWestId}
              />
            </Col>
            {!isMobile && (
              <Col>
                <Restrict roles={["bia"]}>
                  <StandingsOverTimeChart
                    data={westRecordsByDate}
                    championshipData={westChampionshipOddsByDate}
                    selectedTeam={(
                      hoveredWestId ||
                      (defaultWestTeam ? defaultWestTeam.teamid : 0)
                    ).toString()}
                    setSelectedTeam={(team) => setHoveredWestId(parseInt(team))}
                  />
                </Restrict>
              </Col>
            )}
          </Row>
        </Panel>
      </Col>
    </Row>
  );
}

function StandingsOverTimeChart(props: {
  data: TeamRecordsByDate;
  championshipData: TeamChampionshipOddsByDate;
  selectedTeam: string;
  setSelectedTeam: (teamId: string) => void;
}) {
  const { data, championshipData, selectedTeam, setSelectedTeam } = props;
  const teams = useContext(TeamContext);
  const [yValue, setYValue] = useState("ppp");

  const teamAndYValueToData = useCallback(
    (teamId: string, yValue: string) => {
      const champDataForTeam = championshipData[teamId];
      const otherDataForTeam = data[teamId];

      if (yValue === "titleProb") {
        return (champDataForTeam || []).map((d) => ({
          x: moment(d.date).valueOf(),
          y: d.pWinFinals,
        }));
      } else if (yValue === "pct") {
        return (otherDataForTeam || []).map((d) => ({
          x: moment(d.date).valueOf(),
          y: d.wins / (d.losses + d.wins),
        }));
      } else {
        return (otherDataForTeam || []).map((d) => ({
          x: moment(d.date).valueOf(),
          y: (d.ptsFor - d.ptsAgainst) / d.numPoss,
        }));
      }
    },
    [data, championshipData]
  );

  const lines = useMemo(() => {
    return Object.keys(data).map((teamId) => {
      const teamColors = TEAM_COLORS[teamId];
      return {
        color: teamColors ? teamColors.primary : "black",
        segments: [teamAndYValueToData(teamId, yValue)],
        label: teamId,
        opacity: teamId === selectedTeam ? 1 : 0.2,
        strokeWidth: teamId === selectedTeam ? 2 : 1,
      };
    });
  }, [data, teamAndYValueToData, yValue, selectedTeam]);

  const tableHeight = 432; // Roughly the height of the standings table.
  const logoSize = 64;

  return (
    <div style={{ position: "relative" }}>
      <div>
        <b>[BIA ONLY]</b>
      </div>
      <select
        value={yValue}
        onChange={(evt) => {
          const newYValue = evt.target.value;
          setYValue(newYValue);
        }}
      >
        <option value="ppp">Net PPP</option>
        <option value="pct">Win %</option>
        <option value="titleProb">Championship Probability</option>
      </select>

      <ParentSize>
        {({ width }) => (
          <>
            <div
              style={{
                position: "absolute",
                left: Math.min(width, tableHeight) - logoSize,
                opacity: 0.5,
              }}
            >
              <TeamLogo idsId={parseInt(selectedTeam)} size={logoSize} />
            </div>
            <LineChart
              lines={lines}
              height={Math.min(width, tableHeight)}
              width={Math.min(width, tableHeight)}
              showLegend={false}
              xTickFormat={(d) => moment(d as number).format("MM/DD")}
              yTickFormat={(d) => {
                const val = d as number;
                return yValue === "pct" || yValue === "titleProb"
                  ? dec100Format(val)
                  : plusMinusFormat2(val);
              }}
              numXTicks={5}
              tooltip={(d) => {
                const data = d.data;
                const yVal = d.yVal;

                const sortedData = data.sort(
                  (a, b) => Math.abs(a.y - yVal) - Math.abs(b.y - yVal)
                );
                const newSelectedTeam = sortedData[0];
                if (!newSelectedTeam) return null;
                const newSelectedTeamId = newSelectedTeam.label;

                if (selectedTeam !== newSelectedTeamId) {
                  // TODO(chrisbu): Wrap in timeout to avoid react error.
                  setTimeout(() => {
                    setSelectedTeam(newSelectedTeamId);
                  }, 0);
                }

                const team = teams.find(
                  (t) => t.teamid.toString() === newSelectedTeam.label
                );

                return (
                  <div>
                    <b>{moment(newSelectedTeam.x).format("MM/DD/YY")}</b>
                    <div>
                      {team && team.teamabbreviation}
                      {": "}
                      {yValue === "pct" || yValue === "titleProb"
                        ? pctFormat(newSelectedTeam.y)
                        : plusMinusFormat2(newSelectedTeam.y)}
                    </div>
                  </div>
                );
              }}
            />
          </>
        )}
      </ParentSize>
    </div>
  );
}
