import React, { useMemo, useState } from "react";

import { Team } from "../../../shared/routers/TeamRouter";
import { CELTICS_TEAM_ID, Highlights } from "../../constants/AppConstants";
import { decFormat, decFormat2, makePlusMinus } from "../../util/Format";
import { Table, createColumnHelper, SortingState } from "../core/Table";
import { TeamTableCell } from "../core/TableCell";
import { StatRankRenderer } from "../../components/table/renderers/StatRankRenderer";
import { extent } from "d3";

const columnHelper = createColumnHelper<Team>();

function winLossRenderer(d: { wins: number; losses: number }) {
  return `${d.wins}-${d.losses}`;
}

function parseStreakValue(streak: string) {
  const tokens = streak.split(" ");

  if (tokens.length !== 2) return 0;

  const token0 = tokens[0];
  const token1 = tokens[1];
  if (token0 === "Won" && token1 !== undefined) {
    return parseInt(token1);
  }

  return token1 === undefined ? 0 : -parseInt(token1);
}

export function TeamStandings(props: {
  data: Team[];
  hoveredTeamId: number | null;
  setHoveredTeamId: (id: number | null) => void;
}) {
  const { data, hoveredTeamId, setHoveredTeamId } = props;
  const [sorting, setSorting] = useState<SortingState>();

  const columns = useMemo(() => {
    let g = 0;
    return [
      columnHelper.accessor("teamcity", {
        header: () => "Team",
        cell: (info) => (
          <TeamTableCell
            ids={info.row.original.teamid}
            name={`${info.getValue()} ${info.row.original.teamname}`}
          />
        ),
        meta: { group: g++ },
      }),
      columnHelper.accessor("Division", {
        meta: { group: g++, textAlign: "left" },
      }),
      columnHelper.accessor("netppp", {
        header: "Net PPP",
        cell: (info) => (
          <StatRankRenderer
            data={{
              rank: info.row.original.confranknetppp,
              stat: info.getValue(),
            }}
            config={{
              rendererOptions: {
                fmt: makePlusMinus(decFormat2),
                showStat: true,
              },
            }}
          />
        ),
        meta: {
          highlights: Highlights.Max,
          colorDomain: [-0.15, 0.15],
          group: g++,
        },
      }),
      columnHelper.accessor("Wins", {
        header: "W",
        meta: {
          highlights: Highlights.Max,
          group: g,
          colorDomain: extent(data.map((d) => d.Wins)) as [number, number],
        },
      }),
      columnHelper.accessor("Losses", {
        header: "L",
        meta: {
          highlights: Highlights.Min,
          group: g,
          colorDomain: extent(data.map((d) => d.Losses)).reverse() as [
            number,
            number
          ],
        },
      }),
      columnHelper.accessor("winPct", {
        header: "%",
        cell: (info) => decFormat(100 * info.getValue()),
        meta: {
          highlights: Highlights.Max,
          group: g,
          colorDomain: extent(data.map((d) => d.winPct)) as [number, number],
        },
      }),
      columnHelper.accessor("gb", {
        header: "GB",
        cell: (info) => decFormat(info.getValue()),
        meta: { colorDomain: [25, 0], highlights: Highlights.Min, group: g++ },
      }),
      columnHelper.accessor("ConfWins", {
        header: "Conf.",
        cell: (info) =>
          winLossRenderer({
            wins: info.getValue(),
            losses: info.row.original.ConfLoss,
          }),
        meta: {
          highlights: Highlights.Max,
          group: g,
          colorDomain: extent(data.map((d) => d.ConfWins)) as [number, number],
        },
      }),
      columnHelper.accessor("DivWins", {
        header: "Div.",
        cell: (info) =>
          winLossRenderer({
            wins: info.getValue(),
            losses: info.row.original.DivLoss,
          }),
        meta: {
          highlights: Highlights.Max,
          group: g,
          colorDomain: extent(data.map((d) => d.DivWins)) as [number, number],
        },
      }),
      columnHelper.accessor("HomeWins", {
        header: "Home",
        cell: (info) =>
          winLossRenderer({
            wins: info.getValue(),
            losses: info.row.original.homeLoss,
          }),
        meta: {
          highlights: Highlights.Max,
          group: g,
          colorDomain: extent(data.map((d) => d.HomeWins)) as [number, number],
        },
      }),
      columnHelper.accessor("Awaywins", {
        header: "Away",
        cell: (info) =>
          winLossRenderer({
            wins: info.getValue() || 0,
            losses: info.row.original.awayLoss,
          }),
        meta: {
          highlights: Highlights.Max,
          group: g++,
          colorDomain: extent(data.map((d) => d.Awaywins || 0)) as [
            number,
            number
          ],
        },
      }),
      columnHelper.accessor((row) => parseInt(row.Last10.split("-")[0] || ""), {
        header: "L 10",
        cell: (info) => {
          const wl = info.row.original.Last10.split("-").map((s) =>
            parseInt(s)
          );

          // Something went wrong... show empty value.
          if (wl.length !== 2) return "";

          return winLossRenderer({
            wins: wl[0] || 0,
            losses: wl[1] || 0,
          });
        },
        meta: { highlights: Highlights.Max, group: g, colorDomain: [0, 10] },
      }),
      columnHelper.accessor((row) => parseStreakValue(row.Streak), {
        header: "Streak",
        cell: (info) =>
          info.getValue() < 0
            ? `L ${-info.getValue()}`
            : `W ${info.getValue()}`,
        meta: { highlights: Highlights.Max, group: g, colorDomain: [-5, 5] },
      }),
    ];
  }, [data]);

  if (data.length === 0) return null;

  const celticsIdx = data.findIndex((d) => d.teamid === CELTICS_TEAM_ID);

  return (
    <Table
      data={data}
      columns={columns}
      rowColorMap={
        celticsIdx !== undefined
          ? {
              [celticsIdx]: {
                backgroundColor: "#F8FFF8",
                borderColor: "1px solid rgb(156, 203, 156)",
              },
            }
          : undefined
      }
      sorting={sorting}
      setSorting={setSorting}
      autoWidth={true}
      showColorOnHover={true}
      showRowIndex={false}
      onHoveredCellChange={(row, col, cellValue, rowValue) => {
        // TODO(chrisbu): Figure out how to remove this settimeout without
        // triggering the react console error.
        setTimeout(() => {
          if (rowValue === undefined) {
            if (hoveredTeamId !== null) {
              setHoveredTeamId(null);
            }
          } else {
            if (hoveredTeamId !== rowValue.teamid) {
              setHoveredTeamId(rowValue.teamid);
            }
          }
        }, 0);
      }}
    />
  );
}
