import React from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Col, Form, Row } from "react-bootstrap";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import moment from "moment-timezone";

import { Restrict } from "../components/core/Restrict";
import { Game } from "../../shared/routers/GameRouter";
import { Page } from "../components/core/Page";
import { Panel } from "../components/core/Panel";
import { TeamLogo } from "../components/team/TeamLogo";
import { trpc } from "../util/tRPC";

const useStyles = makeStyles(() =>
  createStyles({
    actionIcon: {
      cursor: "pointer",
      opacity: 0.5,
      marginLeft: 5,
      marginRight: 5,
      "&:hover": {
        opacity: 1,
      },
    },
    teamContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
    gameInfo: {
      textAlign: "right",
      padding: 10,
    },
    datePicker: {
      width: "auto",
      display: "inline-block",
    },
  })
);

export function GamesPage() {
  const classes = useStyles();
  const { date } = useParams();
  const navigate = useNavigate();

  const { data: games } = trpc.game.getGamesOnDate.useQuery({ date });

  const handleDateChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    navigate(`/games/${evt.target.value}`);
  };

  const prevDay = () => {
    const d = moment(date).subtract(1, "days");
    navigate(`/games/${d.format("YYYY-MM-DD")}`);
  };

  const nextDay = () => {
    const d = moment(date).add(1, "days");
    navigate(`/games/${d.format("YYYY-MM-DD")}`);
  };

  const header = (
    <span>
      <h1>{`Games on ${moment(date).format("MMMM Do, YYYY")}`}</h1>
      <FaChevronLeft onClick={prevDay} className={classes.actionIcon} />
      <Form.Control
        className={classes.datePicker}
        type="date"
        value={date}
        onChange={handleDateChange}
      ></Form.Control>
      <FaChevronRight onClick={nextDay} className={classes.actionIcon} />
    </span>
  );

  return (
    <Page header={{ component: header }} title="Games">
      <Row>
        {games &&
          games
            .filter((game) => game.leagueName)
            .map((game, i) => {
              return (
                <Col xs={12} md={6} key={i}>
                  <GameBox game={game} />
                </Col>
              );
            })}
      </Row>
    </Page>
  );
}

function GameBox(props: { game: Game }) {
  const classes = useStyles();
  const game = props.game;
  const isNba = game.leagueName.includes("NBA");
  const isCombine = game.leagueName.includes("Combine");
  const homeUrl =
    isNba && !isCombine
      ? `/team/${game.homeIDS}`
      : `/team/ml/${game.homeTeamID}?league=${leagueToLeagueKey(
          game.leagueName
        )}&season=${game.season}`;
  const awayUrl =
    isNba && !isCombine
      ? `/team/${game.awayIDS}`
      : `/team/ml/${game.awayTeamID}?league=${leagueToLeagueKey(
          game.leagueName
        )}&season=${game.season}`;
  let gameUrl;
  if (moment().isAfter(moment(game.gameDate))) {
    gameUrl =
      game.leagueName.includes("NBA") && !game.leagueName.includes("Combine")
        ? `/game/${game.alag}`
        : `/game/ml/${leagueToLeagueKey(game.leagueName)}/${game.alag}`;
  } else {
    gameUrl = `/preview/${game.alag}`;
  }

  // Read the game date/time into a moment object knowing its in eastern time.
  const gameMoment = moment.tz(
    `${game.gameDate.split(" ")[0]} ${game.gameTime}`,
    "YYYY-MM-DD h:mm A",
    "America/New_York"
  );
  const curMoment = moment();

  // If it is an NBA game and it is the day of the game and the current time is
  // after the game start time, show live game page link.
  const sameDay = moment(game.gameDate).isSame(curMoment, "day");
  const started = curMoment.isAfter(gameMoment);
  const isGameLive = isNba && !isCombine && sameDay && started;

  const isNCAA = game.leagueKey === "ncaa";

  const isGLeague = game.leagueKey === "g-league";

  return (
    <Panel>
      <Link to={gameUrl} style={{ cursor: "pointer" }}>
        <div className={classes.teamContainer}>
          <h3>
            <Link to={awayUrl}>
              {isNba && game.awayIDS && (
                <TeamLogo idsId={game.awayIDS} size={40} inline={true} />
              )}
              {isNCAA && game.awayESPN && game.awayESPN < 10000 && (
                <TeamLogo
                  espnId={game.awayESPN}
                  isNcaa={isNCAA}
                  size={36}
                  inline={true}
                />
              )}
              {isGLeague && game.awayIDS && (
                <TeamLogo
                  idsId={game.awayIDS}
                  isGLeague={isGLeague}
                  size={36}
                  inline={true}
                />
              )}
              {game.awayTeam}
            </Link>
          </h3>
          {game.awayPts !== 0 && (
            <Link to={gameUrl}>
              <h3>{game.awayPts}</h3>
            </Link>
          )}
        </div>
        <div className={classes.gameInfo}>
          <Link to={gameUrl}>{gameInfoText(game)}</Link>
          <Restrict roles={["bia"]}>
            <div>
              {isGameLive && (
                <b>
                  <Link to={`/live/${game.alag}`}>(FOLLOW LIVE)</Link>
                </b>
              )}
            </div>
          </Restrict>
        </div>
        <div className={classes.teamContainer}>
          <h3>
            <Link to={homeUrl}>
              {isNba && game.homeIDS && (
                <TeamLogo idsId={game.homeIDS} size={40} inline={true} />
              )}
              {isNCAA && game.homeESPN && game.homeESPN < 10000 && (
                <TeamLogo
                  espnId={game.homeESPN}
                  isNcaa={isNCAA}
                  size={36}
                  inline={true}
                />
              )}
              {isGLeague && game.homeIDS && (
                <TeamLogo
                  idsId={game.homeIDS}
                  isGLeague={isGLeague}
                  size={36}
                  inline={true}
                />
              )}
              {game.homeTeam}
            </Link>
          </h3>
          {game.homePts !== 0 && (
            <Link to={gameUrl}>
              <h3>{game.homePts}</h3>
            </Link>
          )}
        </div>
      </Link>
    </Panel>
  );
}

function gameInfoText(game: Game) {
  if (game.processed) {
    // If processed, arenaName only
    return game.arenaName;
  } else {
    // If not processed, show all info available joined with a |.
    return [game.arenaName, game.gameTime].filter((g) => !!g).join(" | ");
  }
}

function leagueToLeagueKey(league: string) {
  return league.toLowerCase().replaceAll(" ", "_");
}
