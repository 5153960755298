// Good for bars (not too intense).
export const Green1 = "#97D573";
export const Red1 = "#DA96A1";

// Good for highlighting rows in tables and table-like things.
export const YellowHighlight = "#FEFFB5";

// Map of team ids to their primary and secondary colors.
export const TEAM_COLORS: Record<
  string,
  { primary: string; secondary: string }
> = {
  "1610612737": { primary: "#E03A3E", secondary: "#C1D32F" }, // ATL
  "1610612738": { primary: "#007A33", secondary: "#BA9653" }, // BOS
  "1610612751": { primary: "#000000", secondary: "#FFFFFF" }, // BRK
  "1610612766": { primary: "#1D1160", secondary: "#00788C" }, // CHA
  "1610612741": { primary: "#CE1141", secondary: "#000000" }, // CHI
  "1610612739": { primary: "#860038", secondary: "#041E42" }, // CLE
  "1610612742": { primary: "#00538C", secondary: "#002B5E" }, // DAL
  "1610612743": { primary: "#0E2240", secondary: "#FEC524" }, // DEN
  "1610612765": { primary: "#C8102E", secondary: "#1D42BA" }, // DET
  "1610612744": { primary: "#1D428A", secondary: "#FFC72C" }, // GSW
  "1610612745": { primary: "#CE1141", secondary: "#000000" }, // HOU
  "1610612754": { primary: "#002D62", secondary: "#FDBB30" }, // IND
  "1610612746": { primary: "#C8102E", secondary: "#1D428A" }, // LAC
  "1610612747": { primary: "#552583", secondary: "#FDB927" }, // LAL
  "1610612763": { primary: "#5D76A9", secondary: "#12173F" }, // MEM
  "1610612748": { primary: "#98002E", secondary: "#F9A01B" }, // MIA
  "1610612749": { primary: "#00471B", secondary: "#EEE1C6" }, // MIL
  "1610612750": { primary: "#0C2340", secondary: "#236192" }, // MIN
  "1610612740": { primary: "#0C2340", secondary: "#C8102E" }, // NOP
  "1610612752": { primary: "#006BB6", secondary: "#F58426" }, // NYK
  "1610612760": { primary: "#007AC1", secondary: "#EF3B24" }, // OKC
  "1610612753": { primary: "#0077C0", secondary: "#C4CED4" }, // ORL
  "1610612755": { primary: "#006BB6", secondary: "#ED174C" }, // PHI
  "1610612756": { primary: "#1D1160", secondary: "#E56020" }, // PHX
  "1610612757": { primary: "#E03A3E", secondary: "#000000" }, // POR
  "1610612758": { primary: "#5A2D81", secondary: "#63727A" }, // SAC
  "1610612759": { primary: "#C4CED4", secondary: "#000000" }, // SAS
  "1610612761": { primary: "#CE1141", secondary: "#000000" }, // TOR
  "1610612762": { primary: "#002B5C", secondary: "#00471B" }, // UTA
  "1610612764": { primary: "#002B5C", secondary: "#E31837" }, // WAS
};

export const blueLow = "#518fd3";

export const grayMid = "#f6f6f6";

export const darkGrayMid = "#838383";

export const redHigh = "#F05C39";

// Good contrasting colors for comparisons.
export const green = "rgb(174, 232, 146)";
export const purple = "rgb(210, 170, 237)";

export const lineChartColors = {
  yellow: "rgb(250, 188, 4)",
  green: "rgb(52, 168, 83)",
  orange: "rgb(250, 109, 2)",
  turqoiuse: "rgb(70, 189, 198)",
  blue: "rgb(66, 133, 244)",
  red: "rgb(234, 67, 53)",
};

export const lineChartColorArray = [
  lineChartColors.yellow,
  lineChartColors.green,
  lineChartColors.orange,
  lineChartColors.turqoiuse,
  lineChartColors.blue,
  lineChartColors.red,
];

export const LEAGUE_COLORS: Record<string, string> = {
  NBA: "#fff",
  NCAA: "#e8f3ff", // Blue.
  "NBA Preseason": "#ededed", // Light gray.
  "G-League": "#ebfddf", // Light green.
  "Utah Summer": "#dff8f2", // Light turqoise.
  "Vegas Summer": "#dff8f2", // Light turqoise.
  "Orlando Summer": "#dff8f2", // Light turqoise.
  "California Classic": "#dff8f2", // Light turqoise.
  other: "#edecff", // Light purple.
  youth: "#FFD8B2", // Light orange.
};
