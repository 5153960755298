import React from "react";
import { format, timeFormat as d3TimeFormat } from "d3";

export const TABLE_EMPTY_VALUE_STR = "-";

export function intFormat(x: number | null) {
  return x == null ? TABLE_EMPTY_VALUE_STR : format("d")(x);
}

export function decFormat(x: number | null) {
  return x == null ? TABLE_EMPTY_VALUE_STR : format("0.1f")(x);
}

export function decFormat2(x: number | null) {
  return x == null ? TABLE_EMPTY_VALUE_STR : format("0.2f")(x);
}

export function decFormat3(x: number) {
  return x == null ? TABLE_EMPTY_VALUE_STR : format("0.3f")(x);
}

export function dec100Format(x: number | null) {
  return x == null ? TABLE_EMPTY_VALUE_STR : format("0.1f")(x * 100);
}

export function seFormat(x: number) {
  return x == null ? TABLE_EMPTY_VALUE_STR : "±" + format("0.1f")(x);
}

export function seFormat2(x: number | null) {
  return x == null ? TABLE_EMPTY_VALUE_STR : "±" + format("0.2f")(x);
}

export function leadingZeroFormat(x: number, length: number) {
  return ("00000000" + x).slice(-length);
}

// At most one decimal point.
export function atMostDecFormat(x: number | null): string {
  return x == null || x.toString() === ""
    ? TABLE_EMPTY_VALUE_STR
    : (Math.round((x as number) * 10) / 10).toString();
}

export function pctFormat(x: number | null) {
  return x == null ? TABLE_EMPTY_VALUE_STR : format("0.1%")(x);
}

export function fracDecFormat(
  threshold: number,
  data: null | { denominator: number | null; numerator: number | null }
) {
  if (data == null || !data.denominator || data.numerator === null) {
    return TABLE_EMPTY_VALUE_STR;
  }

  if (Math.abs(data.denominator) < threshold) {
    let str =
      atMostDecFormat(Math.abs(data.numerator)) +
      " / " +
      atMostDecFormat(Math.abs(data.denominator));

    if (data.numerator / data.denominator < 0) {
      str = "-" + str;
    }

    return str;
  }
  const val = data.denominator === 0 ? 0 : data.numerator / data.denominator;
  if (isNaN(val)) {
    return decFormat(null);
  }
  return decFormat(100 * val);
}

export function makePercent(fmt: (x: number | null) => string | null) {
  return (x: number | null) => (x != null ? `${fmt(100 * x)}%` : fmt(x));
}

export function percentileFormat(
  x: number | null
): JSX.Element | string | null {
  if (x == null) {
    return TABLE_EMPTY_VALUE_STR;
  }

  let value = x.toString();

  if (x === 0) {
    value = "<1";
    x = 0.01;
  } else if (x >= 0.99) {
    value = ">99";
    x = 0.99;
  } else {
    value = Math.round(100 * x).toString();
  }

  return (
    <span>
      {value}
      <sup>{ordinalTimeSuffix(Math.round(100 * x))}</sup>
    </span>
  );
}

export function pctFormatRound(x: number) {
  return x == null ? TABLE_EMPTY_VALUE_STR : format("0.0%")(x);
}

export function plusMinusFormat(x: number | null) {
  return x == null ? TABLE_EMPTY_VALUE_STR : format("+0.1f")(x);
}

export function plusMinusFormat2(x: number | null) {
  return x == null ? TABLE_EMPTY_VALUE_STR : format("+0.2f")(x);
}

export function pythDeltaFormat(x: number) {
  return x == null ? TABLE_EMPTY_VALUE_STR : `${plusMinusFormat(x)} wins`;
}

export function pythFormat(x: number) {
  return x == null ? TABLE_EMPTY_VALUE_STR : `${decFormat(x)} wins`;
}

export const plusMinusIntFormat = makePlusMinus((x: number | null) =>
  (x == null ? TABLE_EMPTY_VALUE_STR : Math.round(x)).toString()
);

/**
 * Wraps any formatting function and puts a + in front of formatted value if x
 * is positive.
 */
export function makePlusMinus(fmt: (x: number | null) => string) {
  return (x: number | null) => (x != null && x > 0 ? `+${fmt(x)}` : fmt(x));
}

/** Takes a season like "2015" and outputs "2014-15". */
export function seasonString(season: string | null) {
  if (season == null) {
    return season;
  }

  return (
    parseInt(season, 10) -
    1 +
    "-" +
    String(season).substring(String(season).length - 2)
  );
}

export function fractionFormat(data: {
  denominator: number;
  numerator: number;
}) {
  if (!data || !data.denominator) {
    return TABLE_EMPTY_VALUE_STR;
  }
  return `${atMostDecFormat(data.numerator)} / ${atMostDecFormat(
    data.denominator
  )}`;
}

export function dateFormat(x: Date) {
  return x == null ? TABLE_EMPTY_VALUE_STR : d3TimeFormat("%m/%d/%Y")(x);
}

export function dateFormatShort(x: Date) {
  return x == null ? TABLE_EMPTY_VALUE_STR : d3TimeFormat("%-m/%-d")(x);
}

export function dateFormatLong(x: Date) {
  return x == null ? TABLE_EMPTY_VALUE_STR : d3TimeFormat("%B %-d, %Y")(x);
}

export function timeFormat(x: Date) {
  return x == null ? TABLE_EMPTY_VALUE_STR : d3TimeFormat("%-I:%M %p")(x);
}

export function gameClockFormat(x: number) {
  if (x == null) {
    return TABLE_EMPTY_VALUE_STR;
  }

  let quarterMin = (x % 720) / 60;
  if (x % 720 === 0 && x !== 0) {
    quarterMin = 12;
  }

  return (
    Math.floor(quarterMin) + ":" + leadingZeroFormat(Math.round(x % 60), 2)
  );
}

export function dateToString(date: Date | null, long: boolean) {
  // converts Date object to string. leaves string as string
  if (!date) {
    return TABLE_EMPTY_VALUE_STR;
  }

  date = new Date(date);
  return long ? dateFormatLong(date) : dateFormat(date);
}

export function footFormat(inches: number | null) {
  if (inches === null) {
    return TABLE_EMPTY_VALUE_STR;
  }

  let feet = Math.floor(inches / 12);
  // round remainder to eighth inches

  const remainder = Math.round((inches % 12) * 8) / 8;
  const inchFraction = (remainder * 1000) % 1000;
  let inchWhole = (remainder * 1000 - inchFraction) / 1000;
  if (inchWhole === 12) {
    inchWhole = 0;
    feet += 1;
  }

  let inchesStr = inchWhole.toString();
  switch (inchFraction) {
    case 875:
      inchesStr += "⅞";
      break;
    case 750:
      inchesStr += "¾";
      break;
    case 625:
      inchesStr += "⅝";
      break;
    case 500:
      inchesStr += "½";
      break;
    case 375:
      inchesStr += "⅜";
      break;
    case 250:
      inchesStr += "¼";
      break;
    case 125:
      inchesStr += "⅛";
      break;
  }

  return feet + "'" + inchesStr + '"';
}

export function footFormatDec(x: number) {
  if (x == null) {
    return TABLE_EMPTY_VALUE_STR;
  }

  let feet = Math.floor(x / 12);
  // Round remainder to eighth inches.

  let remainder = atMostDecFormat(x % 12);

  if (remainder === "12") {
    feet += 1;
    remainder = "0";
  }
  return feet + "'" + remainder + '"';
}

export function poundsFormat(x: number | null) {
  return x == null ? TABLE_EMPTY_VALUE_STR : Math.round(x) + "lbs";
}

export function moneyFormat(x: number | null) {
  return x == null ? TABLE_EMPTY_VALUE_STR : format("$,.0f")(x);
}

// Instead of $3,124,451 just show $3.1M or instead of $567,123 just show $567K.
export function abbreviatedMoneyFormat(x: number | null) {
  if (x == null) {
    return TABLE_EMPTY_VALUE_STR;
  }

  if (x >= 1e6) {
    return format("$,.1f")(x / 1e6) + "M";
  }
  if (x >= 1e3) {
    return format("$,.0f")(x / 1e3) + "K";
  }
  return format("$,.0f")(x);
}

export function minutesFormat(x: number | null) {
  if (x === null) {
    return TABLE_EMPTY_VALUE_STR;
  }
  const seconds = Math.round(x * 60);
  const remainderSeconds = seconds % 60;
  const mins = (seconds - remainderSeconds) / 60;
  return mins + ":" + leadingZeroFormat(remainderSeconds, 2);
}

export function ordinalTimeSuffix(x: number) {
  const j = x % 10;
  const k = x % 100;
  if (j == 1 && k != 11) {
    return "st";
  }
  if (j == 2 && k != 12) {
    return "nd";
  }
  if (j == 3 && k != 13) {
    return "rd";
  }
  return "th";
}

export function ordinalFormat(x: number | null) {
  return x === null ? TABLE_EMPTY_VALUE_STR : x + ordinalTimeSuffix(x);
}

export function intToSortableCharacterString(n: number, max: number): string {
  return "0".repeat(max.toString().length - n.toString().length) + n;
}

export function collegeGameClockFormat(x: number | null) {
  if (x === null) {
    return TABLE_EMPTY_VALUE_STR;
  }

  let quarterMin = (x % 1200) / 60;
  if (x % 1200 === 0 && x !== 0) {
    quarterMin = 20;
  }

  return (
    Math.floor(quarterMin) + ":" + leadingZeroFormat(Math.round(x % 60), 2)
  );
}

export function intlGameClockFormat(x: number | null) {
  if (x === null) {
    return TABLE_EMPTY_VALUE_STR;
  }

  let quarterMin = (x % 600) / 60;
  if (x % 600 === 0 && x !== 0) {
    quarterMin = 10;
  }

  return (
    Math.floor(quarterMin) + ":" + leadingZeroFormat(Math.round(x % 60), 2)
  );
}

export function period(period: number | null) {
  if (period === null) return TABLE_EMPTY_VALUE_STR;
  if (period <= 4) {
    return `Q${period}`;
  } else {
    return `OT${period - 4}`;
  }
}

export function itemsAsList(items: string[], useOr?: boolean) {
  if (items.length === 0) return "";

  if (items.length === 1) {
    return items[0];
  }
  return `${items.slice(0, -1).join(", ")} ${useOr ? "or" : "and"} ${
    items[items.length - 1]
  }`;
}

export function makeParen(fmt: (x: number | null) => string) {
  return (x: number | null) => (x != null ? `(${fmt(x)})` : fmt(x));
}
