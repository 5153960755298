import React, { useState, useMemo } from "react";

import { Highlights } from "../../constants/AppConstants";
import {
  dec100Format,
  fractionFormat,
  plusMinusIntFormat,
  decFormat2,
  minutesFormat,
  makeParen,
  intFormat,
} from "../../util/Format";
import { Table, SortingState, createColumnHelper } from "../core/Table";
import { PlayerTableCell, PlayerMinutesTableCell } from "../core/TableCell";
import { GameBoxScore } from "../../../shared/routers/GameRouter";
import { perGame } from "../../constants/ColorDomains";

const columnHelper = createColumnHelper<GameBoxScore>();

export function GameBoxScoresTable(props: { data: GameBoxScore[] }) {
  const { data } = props;
  const [sorting, setSorting] = useState<SortingState>();

  const columns = useMemo(() => {
    const teamData = data.find((d) => d.playerId === 0);

    if (!teamData) return [];

    let g = 0;
    return [
      columnHelper.accessor("name", {
        header: () => "Player",
        cell: (info) => (
          <PlayerTableCell
            id={info.row.original.playerId}
            name={info.getValue()}
            bold={info.row.original.gs === 1}
          />
        ),
        footer: "Team Total",
        meta: { group: g++ },
      }),
      columnHelper.accessor("min", {
        header: () => "Min",
        cell: (info) => (
          <PlayerMinutesTableCell
            min={info.getValue()}
            gameId={info.row.original.gameId}
            playerId={info.row.original.playerId}
            playerMinutesExist={!!info.row.original.playerMinutesExist}
          />
        ),
        footer: () => minutesFormat(teamData.min),
        meta: {
          group: g++,
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor("pts", {
        header: () => "PTS",
        footer: () => teamData.pts,
        meta: {
          group: g++,
          highlights: Highlights.Max,
          colorDomain: perGame.pts,
        },
      }),
      columnHelper.accessor("plusminus", {
        header: () => "+/-",
        cell: (info) => plusMinusIntFormat(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: [-15, 15],
        },
      }),
      columnHelper.accessor("xPlusMinus", {
        header: () => "(exp.)",
        cell: (info) =>
          info.getValue() === null
            ? null
            : `(${plusMinusIntFormat(info.getValue())})`,
        meta: {
          group: g++,
          highlights: Highlights.Max,
          colorDomain: [-15, 15],
        },
      }),
      columnHelper.accessor("roff", {
        header: () => "OREB",
        footer: () => teamData.roff,
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: perGame.orb,
        },
      }),
      columnHelper.accessor("rdef", {
        header: () => "DREB",
        footer: () => teamData.rdef,
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: perGame.drb,
        },
      }),
      columnHelper.accessor("rtot", {
        header: () => "REB",
        footer: () => teamData.rtot,
        meta: {
          group: g++,
          highlights: Highlights.Max,
          colorDomain: perGame.trb,
        },
      }),
      columnHelper.accessor("ast", {
        header: () => "AST",
        footer: () => teamData.ast,
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: perGame.ast,
        },
      }),
      columnHelper.accessor("blk", {
        header: () => "BLK",
        footer: () => teamData.blk,
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: perGame.blk,
        },
      }),
      columnHelper.accessor("stl", {
        header: () => "STL",
        footer: () => teamData.stl,
        meta: {
          group: g++,
          highlights: Highlights.Max,
          colorDomain: perGame.stl,
        },
      }),
      columnHelper.accessor("turn", {
        header: () => "TOV",
        footer: () => teamData.turn,
        meta: {
          group: g,
          highlights: Highlights.Min,
          colorDomain: perGame.to,
        },
      }),
      columnHelper.accessor("liveBallTov", {
        header: () => "(live)",
        cell: (info) => makeParen(intFormat)(info.getValue()),
        footer: () => teamData.liveBallTov,
        meta: {
          group: g,
          highlights: Highlights.Min,
          colorDomain: perGame.liveBallTurn,
        },
      }),
      columnHelper.accessor("pf", {
        header: () => "PF",
        footer: () => teamData.pf,
        meta: {
          group: g++,
          highlights: Highlights.Min,
          colorDomain: perGame.pf,
        },
      }),
      columnHelper.accessor(
        (row) => (row.roffpct === null ? null : row.roffpct / 100),
        {
          id: "roffpct",
          header: () => "OR%",
          cell: (info) => dec100Format(info.getValue()),
          footer: () =>
            dec100Format(
              teamData.roffpct === null ? null : teamData.roffpct / 100
            ),
          meta: {
            group: g,
            highlights: Highlights.Max,
            colorDomain: perGame.orbpct,
          },
        }
      ),
      columnHelper.accessor(
        (row) => (row.rdefpct === null ? null : row.rdefpct / 100),
        {
          id: "rdefpct",
          header: () => "DR%",
          cell: (info) => dec100Format(info.getValue()),
          footer: () =>
            dec100Format(
              teamData.rdefpct === null ? null : teamData.rdefpct / 100
            ),
          meta: {
            group: g,
            highlights: Highlights.Max,
            colorDomain: perGame.drbpct,
          },
        }
      ),
      columnHelper.accessor("turnrate", {
        header: () => "TOV%",
        cell: (info) => dec100Format(info.getValue()),
        footer: () => dec100Format(teamData.turnrate),
        meta: {
          group: g,
          highlights: Highlights.Min,
          colorDomain: perGame.topct,
        },
      }),
      columnHelper.accessor(
        (row) =>
          row.numCrashes !== null && row.numCrashOpps
            ? row.numCrashes / row.numCrashOpps
            : null,
        {
          id: "crashRate",
          header: () => "Crash %",
          cell: (info) => dec100Format(info.getValue()),
          footer: () =>
            dec100Format(
              teamData.numCrashes !== null && teamData.numCrashOpps
                ? teamData.numCrashes / teamData.numCrashOpps
                : null
            ),
          meta: {
            group: g++,
            highlights: Highlights.Max,
            colorDomain: perGame.crashRate,
          },
        }
      ),
      columnHelper.accessor((row) => (row.fg2a ? row.fg2m / row.fg2a : null), {
        id: "2pma",
        header: () => "2PM/A",
        cell: (info) =>
          fractionFormat({
            numerator: info.row.original.fg2m,
            denominator: info.row.original.fg2a,
          }),
        footer: () =>
          fractionFormat({
            numerator: teamData.fg2m,
            denominator: teamData.fg2a,
          }),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: perGame["2pct"],
        },
      }),
      columnHelper.accessor((row) => (row.fg2a ? row.fg2m / row.fg2a : null), {
        id: "2pct",
        header: () => "2P%",
        cell: (info) => dec100Format(info.getValue()),
        footer: () => dec100Format(teamData.fg2m / (teamData.fg2a || 1)),
        meta: {
          group: g++,
          highlights: Highlights.Max,
          colorDomain: perGame["2pct"],
        },
      }),
      columnHelper.accessor((row) => (row.tpa ? row.tpm / row.tpa : null), {
        id: "3pma",
        header: () => "3PM/A",
        cell: (info) =>
          fractionFormat({
            numerator: info.row.original.tpm,
            denominator: info.row.original.tpa,
          }),
        footer: () =>
          fractionFormat({
            numerator: teamData.tpm,
            denominator: teamData.tpa,
          }),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: perGame["3pct"],
        },
      }),
      columnHelper.accessor((row) => (row.tpa ? row.tpm / row.tpa : null), {
        id: "3pct",
        header: () => "3P%",
        cell: (info) => dec100Format(info.getValue()),
        footer: () => dec100Format(teamData.tpm / (teamData.tpa || 1)),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: perGame["3pct"],
        },
      }),
      columnHelper.accessor((row) => (row.fga ? row.tpa / row.fga : null), {
        id: "3apct",
        header: () => "3PA%",
        cell: (info) => dec100Format(info.getValue()),
        footer: () => dec100Format(teamData.tpa / (teamData.fga || 1)),
        meta: {
          group: g++,
          highlights: Highlights.Max,
          colorDomain: perGame["3papct"],
        },
      }),
      columnHelper.accessor((row) => (row.fta ? row.ftm / row.fta : null), {
        id: "ftma",
        header: () => "FTM/A",
        cell: (info) =>
          fractionFormat({
            numerator: info.row.original.ftm,
            denominator: info.row.original.fta,
          }),
        footer: () =>
          fractionFormat({
            numerator: teamData.ftm,
            denominator: teamData.fta,
          }),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: perGame.ftpct,
        },
      }),
      columnHelper.accessor((row) => (row.fta ? row.ftm / row.fta : null), {
        id: "ftpct",
        header: () => "FT%",
        cell: (info) => dec100Format(info.getValue()),
        footer: () => dec100Format(teamData.ftm / (teamData.fta || 1)),
        meta: {
          group: g++,
          highlights: Highlights.Max,
          colorDomain: perGame.ftpct,
        },
      }),
      columnHelper.accessor("ppt", {
        header: () => "PPP",
        cell: (info) => decFormat2(info.getValue()),
        footer: () => decFormat2(teamData.ppt),
        meta: {
          group: g++,
          highlights: Highlights.Max,
          colorDomain: perGame.ppp,
        },
      }),
    ];
  }, [data]);

  if (data.length === 0) {
    return (
      <div>
        The data for this game is still being processed, please check back
        later.
      </div>
    );
  }

  return (
    <Table
      title={data.find((d) => d.playerId === 0)?.name}
      data={data.filter((d) => d.playerId)}
      columns={columns}
      autoWidth={true}
      showColorOnHover={true}
      sorting={sorting}
      setSorting={setSorting}
    />
  );
}
